<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{"News-detail" | translate}}</ion-title>
    </ion-toolbar>
</ion-header>


<ion-content>
  <ion-card>
    <img [src]="newsDetails.thumb" style="width: 100%; border-radius: 10px;;"/>
    <ion-card-header>
      <ion-card-title><h3>{{newsDetails.title}}</h3></ion-card-title>
      <ion-card-subtitle style="float: right;">- {{newsDetails.publishtime | date:'medium'}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content style="margin-top: 20px;">
      <h4>{{newsDetails.description}}</h4>
    </ion-card-content>
  </ion-card>
</ion-content>
