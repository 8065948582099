import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { SignInTabsPage } from './pages/SignInTabs/signintabs.page';
import { PagesModule } from './pages/pages.module';
import { ProductListPage } from './pages/product-list/product-list';
import { PageNotFoundPage } from './pages/page-not-found/page-not-found.page';
import { ProductDetailsPage } from './pages/product-details/product-details';
import { CartPage } from './pages/cart/cart';
import { CheckoutPage } from './pages/checkout/checkout';
import { ChefPage } from './pages/chef/chef';
import { AboutUsPage } from './pages/about-us/about-us';
import { FavouritePage } from './pages/favourite/favourite';
import { NewsPage } from './pages/news/news';
import { NewsDetailPage } from './pages/news-detail/news-detail';
import { RateItemPage } from './pages/rate-item/rate-item';
import { SettingsPage } from './pages/settings/settings';
import { ThankyouPage } from './pages/thankyou/thankyou';
import { OfferPage } from './pages/offer/offer';
import { OrdersPage } from './pages/orders/orders';
import { AuthGuard } from './services/auth-service/authguard';
import { LoginPage } from './pages/SignInTabs/login/login.page';
import { RegisterPage } from './pages/SignInTabs/register/register.page';
import { ForgotPasswordPage } from './pages/forgotpassword/forgotpassword.page';
import { VerifyEmailPage } from './pages/verifyemail/verifyemail.page';
import { SellerInfoPage } from './pages/sellerinfo/sellerinfo';
import { listitemsPopupPage } from './pages/listitems-popup/listitems-popup';
import { SellerPage } from './pages/seller/seller';
import { ProductListByCategoryPage } from './pages/product-by-category/product-by-category';
import { ProductListByChefPage } from './pages/product-by-chef/product-by-chef';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: '/product-list/all', 
    pathMatch: 'full'
  },    
  {
    path: 'signintabs', 
    component: SignInTabsPage, 
    children: [
      {
        path: 'login',
        component: LoginPage
      },
      {
        path: 'register',
        component: RegisterPage
      },
      {
        path: 'product-list',
        redirectTo: '/product-list/all', 
        pathMatch: 'full',
      }
    ] 
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordPage
  },
  {
    path: 'verifyemail',
    component: VerifyEmailPage
  },
  { 
    path: 'home/:id', 
    component: ProductListPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'product-list/:id',
    component: ProductListPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'product-by-category/:id',
    component: ProductListByCategoryPage,
    //canActivate: [AuthGuard]
  }, 
  {
    path: 'product-by-chef/:id',
    component: ProductListByChefPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'listitems-popup',
    component: listitemsPopupPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'product-details/:id',
    component: ProductDetailsPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'seller-info/:id',
    component: SellerInfoPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'seller/:id',
    component: SellerPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    component: CartPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    component: CheckoutPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'offer',
    component: OfferPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'aboutus',
    component: AboutUsPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'chef/:id',
    component: ChefPage,
    // canActivate: [AuthGuard]
  },
  {
    path: 'favourite',
    component: FavouritePage,
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    component: NewsPage,
    // canActivate: [AuthGuard]
  },
  {
    path: 'news-detail/:id',
    component: NewsDetailPage,
    //canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    component: OrdersPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'rating/:orderId/:index',
    component: RateItemPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsPage,
    canActivate: [AuthGuard]
  },
  {
    path: 'thankyou',
    component: ThankyouPage,
    canActivate: [AuthGuard]
  },
  {
     path: '**', 
     component: PageNotFoundPage
  }
];
@NgModule({
  imports: [
    PagesModule,
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
export const RoutingComponents = [  
                                  PageNotFoundPage,
                                  SignInTabsPage,
                                    LoginPage,
                                    RegisterPage,
                                    VerifyEmailPage,
                                  HomePage,
                                  ProductListPage,
                                  ChefPage,
                                  CartPage,
                                  CheckoutPage
                                ]
