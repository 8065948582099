<ion-header>
  <ion-toolbar>      
    <ion-title>
      Select Filter     
    </ion-title>
    <ion-buttons slot="end" color="secondary">
      <ion-button (click)="applyfilters()">Apply</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>Items only in Location</ion-label>
      <ion-select placeholder="Select One" [(ngModel)]="selectlocationfilter">
        <ion-select-option *ngFor="let location of allLocations$" [value]="location.title">{{location.title}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>

  <ion-row>
    <ion-col>
      <ion-button (click)="clearallfilters()" style="float: right; margin-top: 30px;">Clear all filters.</ion-button>
    </ion-col>
  </ion-row>
</ion-content>