<ion-grid no-padding>
  <ion-row *ngIf="!readonly">
    <ion-col *ngFor="let num of [1,2,3,4,5]" (click)="rate(num)" size="2" class="center-star pointer-cursor">
      <ion-icon [name]="isAboveRating(num) ? 'star-outline': 'star'" color="primary" size="large"></ion-icon>
    </ion-col>
    <ion-col size="2">
      <ion-chip>
        <ion-label color="secondary">{{score}}</ion-label>
      </ion-chip>
    </ion-col>
  </ion-row>

  <div  *ngIf="readonly">
    <ion-row>
      <ion-col size="1"></ion-col>
      <ion-col *ngFor="let num of [1,2,3,4,5]" size="2" class="center-star">
        <ion-icon [name]="isAboveRating(num) ? 'star-outline': 'star'"  color="primary" size="auto"></ion-icon>
      </ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
  </div>
</ion-grid>
