import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'page-news-detail',
  templateUrl: 'news-detail.html',
  styleUrls: ['news-detail.scss']
})
export class NewsDetailPage {
  id: any;
  newsDetails: any = {};

  constructor(public af: AngularFireDatabase, public route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.af.object('/news/' + this.id).valueChanges().subscribe(res => {
        this.newsDetails = res;
      });
    })
  }
}
