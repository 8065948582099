<ion-header>
    <ion-toolbar class="apptitle" style="--min-height: 45px;">
    </ion-toolbar>
    <ion-toolbar color="secondary" style="--min-height: 45px;border-bottom: 1px solid darkgray; padding-bottom: 5px;">
        <ion-buttons slot="start">
            <ion-back-button text=""></ion-back-button>
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-searchbar (ionChange)="getItems($event)" color="secondary" placeholder="Search items" showCancelButton="never" animated [(ngModel)]="searchtextfilter" inputmode="search" type="search" debounce="1000">
        </ion-searchbar>
        <ion-spinner class="searchtextspinner" name="dots" *ngIf="showtextspinner"></ion-spinner>
        <ion-buttons slot="end">
            <ion-button class="cart-icon-nav" (click)="navcart()">
                <ion-icon name="cart-outline"></ion-icon> 
                <ion-badge color="tertiary">{{noOfItems}}</ion-badge>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="secondary" style="margin-top: 1px; padding-bottom: 5px; text-align: center;">
        <p style="margin: 0 1px 1px 1px; padding: 5px; font-size: xx-small; word-spacing: 12px; letter-spacing: 8px; font-weight: bold;"><ion-icon name="sparkles"></ion-icon> TOP CATEGORIES <ion-icon name="sparkles"></ion-icon></p>
        <ion-slides [options]="{ slidesPerView: 'auto', zoom: true, grabCursor: true }">
            <ion-slide *ngFor="let card of categories" style="width: auto; cursor: pointer;">
                <ion-card style="padding: 2px; margin: 1px 4px 1px 4px; min-width: 150px; --background: var(--ion-color-secondary-shade)" (click)="showbycategory(card)">
                    <ion-card-header>
                        <ion-card-title style="color: var(--ion-color-dark);font-size:small;text-transform: uppercase;">{{card.title}}</ion-card-title>
                    </ion-card-header>
                    <!--<ion-card-content style="padding: 0; width: 60px; height: 60px;">
                        <ion-img style="pointer-events:none;" [src]="card.thumb"></ion-img>
                    </ion-card-content>-->
                </ion-card> 
            </ion-slide>                
       </ion-slides>
    </ion-toolbar>
    <ion-toolbar *ngIf="settings.showFeaturedChefs" color="secondary" style="margin-top: 1px; text-align: center;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;">
        <p style="margin: 0 1px 1px 1px; padding: 5px; font-size: large; word-spacing: 8px; letter-spacing: 4px; font-weight: bold;"><ion-icon name="star-sharp"></ion-icon> FEATURED CHEFS <ion-icon name="star-sharp"></ion-icon></p>
        <div style="margin-bottom: 5px;">
            <ion-slides [options]="{ slidesPerView: 'auto', zoom: true, grabCursor: true }">
                <ion-slide *ngFor="let chef of featuredchefs" style="width: auto; cursor: pointer;">
                    <ion-card style="padding: 2px; margin:1px 4px 1px 4px; min-width: 150px;" color="tertiary" (click)="showbychef(chef)">
                        <ion-card-header>                            
                            <ion-img class="chefthumbnail fadeIn" [src]="chef.thumb" [style.height.px]="heightbyscroll" ></ion-img>
                            <ion-card-title style="color: var(--ion-color-light);font-size:small;text-transform: uppercase; margin-top: 2px;">{{chef.title}}</ion-card-title>
                        </ion-card-header>
                        <!--<ion-card-content style="padding: 0; width: 60px; height: 60px;">
                            <ion-img style="pointer-events:none;" [src]="card.thumb"></ion-img>
                        </ion-card-content>-->
                    </ion-card> 
                </ion-slide>                
            </ion-slides>
        </div>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" *ngIf="showtextspinner || showlocationspinner"></ion-progress-bar>
</ion-header>

<ion-content style="--ion-background-color: var(--ion-color-secondary);" [scrollEvents]="settings.showFeaturedChefs" (ionScroll)="onContentScroll($event)">    
    <ion-grid style="background-color: var(--ion-color-secondary-tint);" *ngIf="initialloaddone">
        <div *ngIf="items.length > 0">
            <ion-row>
                <ion-col *ngFor="let item of items | deliverablePipe ; index as i" sizeXl="3" sizeLg="3" sizeMd="4" sizeSm="6" sizeXs="6" class="box1">
                    <ion-card  (click)="navigate(item)" color="secondary" class="cursor-pointer itemcard box"> <!-- *ngIf="item.isdeliverable" [class.undeliverable]="!item.isdeliverable" -->
                        <ion-card-header>
                            <ion-card-title color="primary" class="truncate">{{item.title}}</ion-card-title>
                            <ion-card-subtitle color="dark" class="cursor-pointer hyperlink" (click)="showSellerInfo($event, item)">{{item.seller.storename}}</ion-card-subtitle>
                        </ion-card-header>
                        <ion-img class="menuthumbnail fadeIn" [src]="item.thumb" ></ion-img>
                        <ion-item  lines="none" color="secondary">
                            <rating style="width: 100%;" [score]="item.rating.currentrating  | number:'1.0-0'" [totalratings]="item.rating.totalratings" readonly="true" class="rating"></rating>
                        </ion-item>
                        <ion-item lines="full" color="secondary">
                            <ion-chip slot="start" (click)="popupTags($event, item)" style="margin-right: 0; margin-left: 0;background-color: var(--ion-color-primary-tint)" *ngIf="(item.tagsarr.length > 0)">
                                <ion-icon name="pricetags" color="secondary"></ion-icon>
                                <ion-label color="secondary">{{item.tagsarr.length}}</ion-label>
                            </ion-chip>
                            <ion-chip slot="end" (click)="popupLocations($event, item)" style="margin-right: 0; margin-left: 0; background-color: var(--ion-color-primary-tint);" *ngIf="(item.locationsservedarr.length > 0)">
                                <ion-icon name="location" color="secondary"></ion-icon>
                                <ion-label color="secondary">{{item.locationsservedarr.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-list style="padding-top: 0; padding-bottom: 0;" color="secondary">
                            <!--
                            <ion-item  lines="none" color="secondary">
                                <ion-col size="12">
                                    <ion-note *ngIf="item.isdeliverable" color="success" style="background: var(--ion-color-light-tint);padding: 5px;border-radius: 10px;font-size: xx-small;float: right;">
                                        <ion-icon name="checkmark-circle" color="success" style="top: 2px; position: relative;"></ion-icon> {{this.deliveryarea}}
                                    </ion-note>
                                    <ion-note *ngIf="!item.isdeliverable" color="danger" style="background: var(--ion-color-light-tint);padding: 5px;border-radius: 10px;font-size: xx-small;float: right;">
                                        Not available in {{this.deliveryarea}}
                                    </ion-note>
                                </ion-col>
                            </ion-item>
                            -->
                            <ion-item  lines="none" color="secondary">
                                <ion-col size="12" class="price">
                                    <div class="ion-text-right" *ngIf="!(item.price.specialPrice != null &&  item.price.specialPrice > 0)">
                                        <ion-label color="dark">{{currency}} {{item.price.value}}</ion-label>
                                    </div>
                                    <div class="ion-text-right specialprice"  *ngIf="item.price.specialPrice != null &&  item.price.specialPrice > 0">
                                        <ion-label>{{currency}}</ion-label>
                                        <ion-label style="text-decoration:line-through; margin-right: 4px; margin-left: 4px;">{{item.price.value}}</ion-label>
                                        <ion-label>{{item.price.specialPrice}}</ion-label>
                                    </div>
                                </ion-col>
                            </ion-item>
                        </ion-list>                        
                    </ion-card>
                </ion-col>
                <ion-infinite-scroll #infiniteScroll threshold="99%" (ionInfinite)="loadMoreItems($event)">
                    <ion-infinite-scroll-content
                      loadingSpinner="bubbles"
                      loadingText="Loading more menu items...">
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
            </ion-row>
        </div>

        <div *ngIf="items.length === 0">
            <ion-list>
                <ion-item class="text-wrap">
                    <ion-row size="12">
                        {{noitemsmsg}}
                    </ion-row>
                </ion-item>
            </ion-list>
        </div>
    </ion-grid>
</ion-content>

<ion-footer>
    <ion-item style="--background: var(--ion-color-secondary-shade); --min-height: 0;">
        <div slot="start" style="padding: 6px;">
            Delivery to: <span (click)="changeDeliveryArea()" style="margin-left: 4px; font-weight: bolder; text-decoration: underline;">{{deliveryarea}}</span>
        </div>
        <!--
        <ion-select slot="end" cancelText="Clear" placeholder="Filter by Area" [(ngModel)]="selectlocationfilter" (ionCancel)="removeLocationFilter()" (ionChange)="getItemsByLocation($event)">
            <ion-select-option *ngFor="let location of allLocations$" [value]="location.title">{{location.title}}</ion-select-option>
        </ion-select>
        -->
    </ion-item>
</ion-footer>