import { Injectable, resolveForwardRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '../../../environments/environment';
import { Configs } from '../../config';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UsersService } from '../users/userservice';
import { take, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DbService {
    _settings: any;
    _categories: any;
    _featuredchefs: any;
    
    documentToDomainObject = _ => {
        const tmpobject = _.payload.val();
        const object = {
            text: tmpobject.title,
            id: _.payload.key
        }
        console.log(JSON.stringify(object));
        return object;
    }

    getLocations(term: string = null): Promise<string[]> {
        const getLocationsPromise = new Promise<any>((resolve, reject) => {
            this.db.list('/locations', ref => ref.orderByChild('title'))
                .valueChanges()
                .subscribe((response: any) => {
                       resolve(response);
                }, (error) => {
                    reject(error);
                });
        });

        return getLocationsPromise;
    }

    getSellerByName(sellername: string): Promise<any> {
        const getSellerDetailsPromise = new Promise<any>((resolve, reject) => {
            this.db.list('/business/', ref => ref.orderByChild('storeName').equalTo(sellername).limitToFirst(1))
            .valueChanges()
            .subscribe((response: any) => {
                if (response.length === 1) {
                    resolve(response[0]);
                } else {
                    resolve(null);
                }
            }, (error) => {
                reject(error);
            });
        });
        return getSellerDetailsPromise;
    }

    
    getSellerInfoByShortName(sellersn: string): Promise<any> {
        console.log('dbservice: Getting seller by shortname=' + sellersn);
        const getSellerInfoByShortNamePromise = new Promise<any>((resolve, reject) => {
            this.db.object('/shortnames/seller/' + sellersn)
                .valueChanges()
                .subscribe((response: any) => {
                    console.log('Seller Info Response='+JSON.stringify(response));
                    if ((response != null) && (response.sellerId != null) && (response.sellerId.length > 0)) {
                        this.getSellerDetails(response.sellerId).then((sellerInfo: any) => {
                            resolve(sellerInfo);
                        }, (err) => {
                            reject(err);
                        });
                    } else {
                        reject('chef not found');
                    }
            }, (error) => {
                reject(error);
            });
        });
        return getSellerInfoByShortNamePromise;
    }



    getSellerDetails(sellerid: string): Promise<any> {
        const getSellerDetailsPromise = new Promise<any>((resolve, reject) => {
            this.db.list('/business/', ref => ref.orderByChild('sellerId').equalTo(sellerid).limitToFirst(1))
            .valueChanges()
            .subscribe((response: any) => {
                if (response.length === 1) {
                    resolve(response[0]);
                } else {
                    resolve(null);
                }
            }, (error) => {
                reject(error);
            });
        });
        return getSellerDetailsPromise;
    }
    missingmenuitemurl: string = null;
    missingchefprofileurl: string = null;
    constructor(private functions: AngularFireFunctions, private db: AngularFireDatabase, private storage: AngularFireStorage, public af: AngularFireAuth, public usersService: UsersService) {}

    getBusinessDetails(sellerid: string): Promise<boolean> {
        const getBusinessDetailsPromise = new Promise<boolean>((resolve, reject) => {
            this.db.list('/business/' + sellerid)
                .valueChanges()
                .subscribe((response: any[]) => {
                   if (response.length === 1) {
                       resolve(response[0]);
                   } else {
                       resolve(null);
                   }
                }, (error) => {
                    reject(error);
                });
        });
        return getBusinessDetailsPromise;
    }

    getMissingSellerThumb(): Promise<string> {
        const getMissingSellerThumbbPromise = new Promise<string>((resolve, reject) => {
            if (this.missingchefprofileurl !== null) {
                setTimeout (() => {
                    resolve(this.missingchefprofileurl);
                 });
            } else {
                const missingsellerfilePath = Configs[environment.name].menuitems.chefprofilefolder + '/' + Configs[environment.name].menuitems.missingchefprofile;
                this.storage.ref(missingsellerfilePath).getDownloadURL().toPromise().then((downloadurl) => {
                    this.missingchefprofileurl = downloadurl;
                    resolve(this.missingchefprofileurl);
                }, (err) => {
                    reject("");
                });
            }
        });       
        return getMissingSellerThumbbPromise;
    }    

    getMissingMenuThumb(): Promise<string> {
        const getMissingMenuThumbPromise = new Promise<string>((resolve, reject) => {
            if (this.missingmenuitemurl !== null) {
                setTimeout (() => {
                    resolve(this.missingmenuitemurl);
                 });
            } else {
                const missingmenufilePath = Configs[environment.name].menuitems.menuitemsimagesfolder + '/' + Configs[environment.name].menuitems.missingmenuitem;
                this.storage.ref(missingmenufilePath).getDownloadURL().toPromise().then((downloadurl) => {
                    this.missingmenuitemurl = downloadurl;
                    resolve(this.missingmenuitemurl);
                }, (err) => {
                    reject("");
                });
            }
        });       
        return getMissingMenuThumbPromise;
    }    

    getUnRatedUserOrders(): Promise<any[]> {
        const getUnRatedUserOrdersPromise = new Promise<any[]>((resolve, reject) => {
            this.af.currentUser.then((cuser) => {
                const userID = cuser.uid;
                let orderstorate = 0;
                this.usersService.GetMenuItemsRated(userID)
                    .then((menuitemsallreadyrated) => {
                        console.log('Menuitems allready rated: ' + menuitemsallreadyrated);
                        this.db.list('/suborders', ref => ref.orderByChild('buyerId').equalTo(userID)).valueChanges().subscribe(response => {
                            let unsortedsuborders: any[] = response;
                            unsortedsuborders.forEach(suborder => {
                              suborder.sellerDetails = {
                                storeName: '',
                                profileimage: ''
                              };
                              this.db.list('/business', ref => ref.orderByChild('sellerId').equalTo(suborder.sellerId)).valueChanges().subscribe(sellerresponse => {
                                if (sellerresponse[0] !== null) {
                                  suborder.sellerDetails = sellerresponse[0];
                                }
                              });
                            });
                            let sortedsuborders = unsortedsuborders.sort((n1, n2) => n2.createdAt - n1.createdAt).filter((suborder) => {
                                return suborder.status == 'Delivered';
                            }).slice(0, 1);          
                            
                            if (sortedsuborders.length === 1) {
                                sortedsuborders[0].items = sortedsuborders[0].items.filter((item) => {
                                    return !menuitemsallreadyrated.includes(item.itemId);
                                });
                            }
                            resolve(sortedsuborders);
                        }, (error) => {
                            console.error(error);
                            reject(error);
                        });          
                    })
              });
        });       
        return getUnRatedUserOrdersPromise;
    }

    updateItemsIhaveRated(itemsrated: any) {
        console.log('Updating menuitems i have rated: ');
        this.af.currentUser.then((cuser) => {
            const userID = cuser.uid;
            console.log('Userid: ' + userID);
            for (const key in itemsrated){
                let menuitem  = itemsrated[key];
                if (itemsrated.hasOwnProperty(key)) {
                    // Save it back to DB.
                    this.db.object('/users/' + cuser.uid + '/menuratings/' + menuitem.menuitemid).update({
                        rating: menuitem.rating
                      }).then(res => {
                          console.log('Ratings saved.' + res);
                      });
                }
            }
        });
    }

    getSellerMenuItems(sellerid: string): Promise<any[]> {
        const getSellerMenuItemsPromise = new Promise<any[]>((resolve, reject) => {
            this.db.list('/menuItems/', ref => ref.orderByChild('sellerid').equalTo(sellerid))
                .snapshotChanges()
                .subscribe((_data) => {
                    const data = [];                        
                    _data.forEach((element) => {
                        const _menuitem: any = element.payload.toJSON();
                        if (_menuitem.isActive) {
                            _menuitem.key = element.key;
                            data.push(_menuitem);
                        }
                    });
                    resolve(data);
                });
        });
        return getSellerMenuItemsPromise;
    }

    updateRatingforMenu(menuitemid: string, newrating: number) {
        console.log('Updating menuitemid: ' + menuitemid);
        this.db.object('menuItems/'+menuitemid).valueChanges().pipe(take(1)).subscribe((menuitem: any) => {
            menuitem.rating = menuitem.rating || { currentrating: 0, totalratings: 0};
            let totalrating = (menuitem.rating.currentrating * menuitem.rating.totalratings) + newrating;
            let newcount = menuitem.rating.totalratings + 1;
            menuitem.rating = {
                currentrating: totalrating / newcount,
                totalratings: newcount,
            }

            // Save it back to DB.
            this.db.object('menuItems/'+menuitemid).update({
                rating: menuitem.rating
            });
        });
    }


    // Set Product List
    productlist = [];
    setProductList(_productlist: any[]) {
        this.productlist = _productlist;
    }

    // Get Product List
    getProductList(): any[] {
        return this.productlist;
    }

    // Set Product List for categories
    productlistforcategory = {};
    setProductListForCategory(category: string, _productlist: any[], nextKey: string) {
        this.productlistforcategory[category] = {
            nextKey: nextKey,
            _items: _productlist
        };
    }

    // Get Product List
    getProductListByCategory(category: string): any {
        return this.productlistforcategory[category] || {
            nextKey: null,
            _items: []
        };
    }

    // Get Paginated menu items for a tag.
    getMenuItemsRangeByCategory(startAt: string, nitems: number, defaultmenuitemthumb: string, defaultsellerthumb: string, showonlyactive: boolean, category: string): Promise<any> {
        let _categoryname = category;
        let no_of_non_active_items = 0;
        let result = {
            nextkey: null,
            data: []
        };
        let lastitem: any = null;
        let shouldweslice = true;
        if (startAt === '') {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems', ref => ref.orderByChild('ntags/' + _categoryname).equalTo(1).limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];                        
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;

                            _menuitem.tagsarr = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _menuitem.tagsarr.push(key);
                            };
                            if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                  
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                  
                            let sum = 0;
                            if (_menuitem.reviews) {
                              for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                              }
                              const avg = sum / _menuitem.reviews.length;
                              _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });

                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }                            
                        } else {
                            result.nextkey = null;
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        } else {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems/tags', ref => ref.orderByChild(_categoryname).limitToLast(nitems+1))
                // this.db.list('/menuItems', ref => ref.orderByKey().endAt(startAt).limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                console.log('Inactive: ' + _menuitem.$key);
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;
                  
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                            let _tags = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _tags.push(key);
                            };
                            if (_tags.length == 0){
                                _menuitem.tagsarr = [];
                            } else {
                                _menuitem.tagsarr = _menuitem.tags.split(',');
                            }

                            if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                  
                            let sum = 0;
                            if (_menuitem.reviews) {
                              for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                              }
                              const avg = sum / _menuitem.reviews.length;
                              _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });
                        
                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }
                        } else {
                            result.nextkey = null;                            
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        }
    }


    // Set Chef Product List
    productlistforchef = {};
    setProductListForChef(sellerid: string, _productlist: any[], nextKey: string) {
        this.productlistforchef[sellerid] = {
            nextKey: nextKey,
            _items: _productlist
        };
    }

    // Get Chef Product List
    getProductListForChef(sellerid: string): any {
        console.log('getProductListForChef: SellerId: ' + sellerid);
        return this.productlistforchef[sellerid] || {
            nextKey: null,
            _items: []
        };
    }

    // Get Paginated menu items for a chef.
    getMenuItemsRangeBySellerId(startAt: string, nitems: number, defaultmenuitemthumb: string, defaultsellerthumb: string, showonlyactive: boolean, sellerid: string): Promise<any> {
        let no_of_non_active_items = 0;
        let result = {
            nextkey: null,
            data: []
        };
        let lastitem: any = null;
        let shouldweslice = true;
        if (startAt === '') {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems', ref => ref.orderByChild('sellerid').startAt(sellerid).endAt(sellerid + "\uf8ff").limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];                        
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;

                            let _tags = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _tags.push(key);
                            };
                            if (_tags.length == 0){
                                _menuitem.tagsarr = [];
                            } else {
                                _menuitem.tagsarr = _menuitem.tags.split(',');
                            }
                            if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                    
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                    
                            let sum = 0;
                            if (_menuitem.reviews) {
                                for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                                }
                                const avg = sum / _menuitem.reviews.length;
                                _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });

                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }                            
                        } else {
                            result.nextkey = null;
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        } else {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems', ref => ref.orderByChild('sellerid').startAt(sellerid).endAt(sellerid + "\uf8ff").limitToLast(nitems+1))
                // this.db.list('/menuItems', ref => ref.orderByKey().endAt(startAt).limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                console.log('Inactive: ' + _menuitem.$key);
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;
                    
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                            let _tags = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _tags.push(key);
                            };
                            if (_tags.length == 0){
                                _menuitem.tagsarr = [];
                            } else {
                                _menuitem.tagsarr = _menuitem.tags.split(',');
                            }
                        if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                    
                            let sum = 0;
                            if (_menuitem.reviews) {
                                for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                                }
                                const avg = sum / _menuitem.reviews.length;
                                _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });
                        
                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }
                        } else {
                            result.nextkey = null;                            
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        }
    }




    // Get Paginated Menu Items.
    getMenuItemsRange(startAt: string, nitems: number, defaultmenuitemthumb: string, defaultsellerthumb: string, showonlyactive: boolean): Promise<any> {
        let no_of_non_active_items = 0;
        let result = {
            nextkey: null,
            data: []
        };
        let lastitem: any = null;
        let shouldweslice = true;
        if (startAt === '') {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems', ref => ref.orderByKey().limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];                        
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;

                            // _menuitem.tags = '';
                            _menuitem.tagsarr = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _menuitem.tagsarr.push(key);
                            };                               
                            if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                  
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                  
                            let sum = 0;
                            if (_menuitem.reviews) {
                              for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                              }
                              const avg = sum / _menuitem.reviews.length;
                              _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });

                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }                            
                        } else {
                            result.nextkey = null;
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        } else {
            const getMenuItemsRangePromise = new Promise<any>((resolve, reject) => {
                this.db.list('/menuItems', ref => ref.orderByKey().endAt(startAt).limitToLast(nitems+1))
                    .snapshotChanges()
                    .subscribe((_data) => {
                        const data = [];
                        _data.forEach((element) => {
                            const _menuitem: any = element.payload.toJSON();
                            _menuitem.$key = element.key;
                            if (showonlyactive && !_menuitem.isActive) {
                                console.log('Inactive: ' + _menuitem.$key);
                                no_of_non_active_items++;
                                shouldweslice = false;
                                return;
                            }
                            _menuitem.seller.thumb = _menuitem.seller.thumb || defaultsellerthumb;
                            _menuitem.thumb = _menuitem.thumb || defaultmenuitemthumb;
                  
                            _menuitem.rating = _menuitem.rating || { currentrating: 0, totalratings: 0};
                            
                            _menuitem.tagsarr = [];
                            let _tags = [];
                            for (const [key, value] of Object.entries(_menuitem.ntags || {})) {
                                _menuitem.tagsarr.push(key);
                            };

                            if (_menuitem.locationsserved == null) {
                                _menuitem.locationsserved = '';
                                _menuitem.locationsservedarr = [];
                            } else {
                                if (_menuitem.locationsserved.length == 0){
                                    _menuitem.locationsservedarr = [];
                                } else {
                                    _menuitem.locationsservedarr = _menuitem.locationsserved.split(',');
                                }
                            }
                  
                            let sum = 0;
                            if (_menuitem.reviews) {
                              for (const k of _menuitem.reviews) {
                                sum = sum + _menuitem.reviews[k].rating;
                              }
                              const avg = sum / _menuitem.reviews.length;
                              _menuitem.reviewData = avg;
                            }
                            shouldweslice = true; // If last item was a non active one, then we dont need to slice.
                            data.push(_menuitem);
                        });
                        
                        // did we get less items, then we are at last page.
                        if (nitems === data.length + no_of_non_active_items - 1) {
                            result.nextkey = _data[0].key;
                            if (shouldweslice) {
                                result.data = data.slice(1);
                            } else {
                                result.data = data;
                            }
                        } else {
                            result.nextkey = null;                            
                            result.data = data;
                        }
                        resolve(result);
                    });
            });    
            return getMenuItemsRangePromise;
        }
    }

    getNewOrderId(): Promise<string> {
        const getNewOrderIdPromise = new Promise<string>((resolve, reject) => {
            const generateOrderIdCallable = this.functions.httpsCallable('generateOrderId');
            generateOrderIdCallable({prefix: 'MUM-'}).subscribe({
                error: (e) => reject(e),
                next: (res) => resolve(res) 
            });
        });
        return getNewOrderIdPromise;
    }

    getSettings(): Promise<any> {
        const getSettingsPromise = new Promise<any>((resolve, reject) => {
            if (this._settings == null) {
                this.db.object('/settings')
                    .valueChanges()
                    .subscribe((response: any) => {
                        this._settings = response;
                        resolve(this._settings);
                    }, (error) => {
                        reject(error);
                    });
            } else {
                setTimeout (() => {
                    resolve(this._settings);
                 });
            }
        });

        return getSettingsPromise;
    }

    getCategories(): Promise<any> {
        const getCategoriesPromise = new Promise<any>((resolve, reject) => {
            if (this._categories == null) {
                this.db.list('/categories')
                    .valueChanges()
                    .subscribe((response: any) => {
                        this._categories = response;
                        resolve(this._categories);
                    }, (error) => {
                        reject(error);
                    });
            } else {
                setTimeout (() => {
                    resolve(this._categories);
                 });
            }
        });

        return getCategoriesPromise;
    }

    getFeaturedChefs(): Promise<any> {
        const getFeaturedChefsPromise = new Promise<any>((resolve, reject) => {
            if (this._featuredchefs == null) {
                this.db.list('/featuredchefs')
                    .valueChanges()
                    .subscribe((response: any) => {
                        this._featuredchefs = response;
                        resolve(this._featuredchefs);
                    }, (error) => {
                        reject(error);
                    });
            } else {
                setTimeout (() => {
                    resolve(this._featuredchefs);
                 });
            }
        });

        return getFeaturedChefsPromise;
    }

    validatePromoCode(_promocode: string, cart: any[], grandtotal: number): Promise<string> {
        const promocode = (_promocode || '').toUpperCase();
        const validatePromoCodePromise = new Promise<string>((resolve, reject) => {
            const validatePromoCodeCallable = this.functions.httpsCallable('validatePromoCode');
            console.log('DB Svc: Validating promo code: ' + promocode);
            console.log('DB Svc: For cart: ' + JSON.stringify(cart));
            validatePromoCodeCallable({promocode: promocode, cart: cart, grandtotal: grandtotal}).subscribe({
                error: (e) => {
                    console.log('Promocode validation result: ' + JSON.stringify(e));
                    reject(e)
                },
                next: (res) => {
                    console.log('Promocode validation result: ' + JSON.stringify(res));
                    resolve(res);
                }
            });
        });

        return validatePromoCodePromise;
    }

    redeempromoandgenerateorderid(cart: any, _promocode: any, grandtotal: number): Promise<any> {
        const promocode = (_promocode || '').toUpperCase();
        const redeempromoPromise = new Promise<string>((resolve, reject) => {
            const redeemPromoCodeCallable = this.functions.httpsCallable('redeemPromoCodeAndGenerateOrderId');
            console.log('DB Svc: Validating promo code: ' + promocode);
            console.log('DB Svc: For cart: ' + JSON.stringify(cart));
            redeemPromoCodeCallable({promocode: promocode, cart: cart, grandtotal: grandtotal, prefix: 'MUM-'}).subscribe({
                error: (e) => {
                    console.log('Promocode Redeem result: ' + JSON.stringify(e));
                    reject(e)
                },
                next: (res) => {
                    console.log('Promocode Redeem result: ' + JSON.stringify(res));
                    resolve(res);
                }
            });
        });

        return redeempromoPromise; 
    }

    calculateDelivery(cart: any[], grandtotal: number): Promise<number> {
        const calculateDeliveryPromise = new Promise<number>((resolve, reject) => {
            const calculateDeliveryCallable = this.functions.httpsCallable('calculateDelivery');
            console.log('DB Svc: Calculating delivery charge: ');
            console.log('DB Svc: For cart: ' + JSON.stringify(cart));
            calculateDeliveryCallable({cart: cart, grandtotal: grandtotal}).subscribe({
                error: (e) => {
                    console.log('calculateDelivery result: ' + JSON.stringify(e));
                    reject(e)
                },
                next: (res) => {
                    console.log('calculateDelivery result: ' + JSON.stringify(res));
                    resolve(res);
                }
            });
        });

        return calculateDeliveryPromise;
    }

    _deliveryArea = '';
    setDeliveryArea(deliveryarea: string) {
        localStorage.setItem('DeliveryArea', deliveryarea);
        this._deliveryArea = deliveryarea;
    }

    // Get Product List
    getDeliveryArea(): string {
        this._deliveryArea = localStorage.getItem('DeliveryArea');
        console.log(this._deliveryArea);
        return this._deliveryArea;
    }   
    
    _themes = null;
    // Get themes
    getThemes(): Promise<any> {
        const getThemesPromise = new Promise<any>((resolve, reject) => {
            if (this._themes == null) {
                this.db.list('/themes')
                    .snapshotChanges()
                    .subscribe((_data: any) => {
                        this._themes = {};                        
                        _data.forEach((element) => {
                            const _theme: any = element.payload.toJSON();
                            const _darktheme = { ..._theme };
                            _darktheme.dark = _theme.light;
                            _darktheme.light = _theme.dark;
                            _darktheme.primary = _theme.secondary;
                            _darktheme.secondary = _theme.primary;
                            _darktheme.tertiary = _theme.medium;
                            _darktheme.medium = _theme.tertiary;
                           
                            this._themes[element.key] = _theme;
                            this._themes[element.key + 'dark'] = _darktheme;
                        });
                        resolve(this._themes);
                    }, (error) => {
                        reject(error);
                    });
            } else {
                setTimeout (() => {
                    resolve(this._themes);
                 });
            }
        });

        return getThemesPromise;
    }

     // Get themes
    setThemes(_themes): Promise<any> {
        const getThemesPromise = new Promise<any>((resolve, reject) => {
            this.db.database.ref('/themes')
                .set(_themes);
        });

        return getThemesPromise;
    }

}
