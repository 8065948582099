import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Options, ImageResult } from "ngx-image2dataurl";
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { UsersService } from '../../services/users/userservice';


@Component({
  selector: 'page-settings',
  templateUrl: 'settings.html',
  styleUrls: ['settings.scss']
})
export class SettingsPage {
  user: any = {};
  url: any = '';
  value: any;
  defaultprofilepicture = 'assets/img/profile.jpg';
  originalprofilepicture = '';
  imageoptions: Options = {
    resize: {
      maxHeight: 128,
      maxWidth: 128
    },
    allowedExtensions: ['JPG', 'PnG']
  };

  options = [{
      'language': 'ENGLISH',
      'value': 'en'
    },
    {
      'language': 'FRENCH',
      'value': 'fr'
    }
  ];

  constructor(public af: AngularFireAuth,
              public db: AngularFireDatabase,
              public router: Router,
              private camera: Camera,
              private userService: UsersService,
              public translate: TranslateService) {
    this.value = 'en';
    this.translate.setDefaultLang('en');
    this.af.authState.subscribe((_user) => {
      if (_user != null){
        console.log(_user);
        if (_user.uid) {
            this.db.object('/users/' + _user.uid).valueChanges().subscribe((res: any) => {
              res.profilepictureurl = res.profilepictureurl || this.defaultprofilepicture;
              this.originalprofilepicture = res.profilepictureurl;
              this.user = res;
            });
          } else {
            // this.router.navigate(['/signintabs', { outlets: { 'login': 'login' } }]);
          }  
      }
    });

  }
  
  takePicture() {
    console.log('Taking picture....');
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      allowEdit: true,
      targetWidth: 200,
      targetHeight: 200,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA
    }

    this.camera.getPicture(options).then((base64datauri) => {
      if ((base64datauri != null) && (base64datauri.length > 0) && (!base64datauri.startsWith('data'))) {
        base64datauri = 'data:image/png;base64,' + base64datauri;
      }
      this.user.profilepictureurl = base64datauri;
      console.log(base64datauri);

    }, (err) => {
      // Handle error
      console.log("Camera issue:" + err);
    });
  }

  onupload() {
    console.log('upload clicked');
  }

  readUrl(imageResult: ImageResult) {
    if (imageResult.error) console.log(imageResult.error);
    this.user.profilepictureurl = imageResult.resized && imageResult.resized.dataURL || imageResult.dataURL;
  }

  readUrl1(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.user.profilepictureurl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  changeLanguage() {
    console.log('language is -' + this.value);
    this.translate.use(this.value);
  }


  SaveUserProfile(deleteoldpicture: string) {
    if (this.af.currentUser) {
      this.af.currentUser.then((cuser) => {
        this.db.object('/users/' + cuser.uid).update({
          name: this.user.name,
          email: this.user.email,
          mobileNo: this.user.mobileNo,
          notification: this.user.notification || false,
          profilepictureurl: this.user.profilepictureurl
        }).then(() => {
          console.log('done');
          if ((deleteoldpicture != null) && (deleteoldpicture.length > 0) && (deleteoldpicture.indexOf('firebasestorage.googleapis.com') > 0)) {
            this.userService.DeleteFirebaseOldImage(deleteoldpicture);
          }  
        })
        .catch((err) => {
          console.log('Error' + err);
        }).finally(() => {
          this.router.navigate(['./product-list/all']);
        });
      });
    }
  }

  onSubmit(user: NgForm) {
    // lets check if profile picture has been updated. if updated, then we need to first upload the image to firebase storage, get the download url, and use that url to save. if not, then dont bother uploading.
    if (this.user.profilepictureurl !== this.originalprofilepicture) {
      this.userService.UploadNewProfile(this.user.profilepictureurl, this.originalprofilepicture, this.user.name).then((profileurl) => {
        this.user.profilepictureurl = profileurl;
        this.SaveUserProfile(this.originalprofilepicture);
      }, (err) => {
        console.log('Error uploading profile picture: ' + err);
      });
      // picture changed.
    } else {
      this.SaveUserProfile(null);
    }
  }
}
