import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "../../services/db-service/dbservice";
import { Configs } from "../../../app/config";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'page-delivery-area',
    templateUrl: 'deliveryarea.html',
    styleUrls: ['deliveryarea.scss']
})
export class DeliveryAreaPage implements OnInit {
    @Input() public deliverydata: any;
    currency: string = Configs[environment.name].Currency.symbol;
    
    constructor(public dbService: DbService, public router: Router, public modalCtrl: ModalController) {
        console.log('DeliveryAreaPage');        
    }
    ngOnInit(): void {
        console.log('DeliveryDataInfo: ' + JSON.stringify(this.deliverydata));
    }

    //Get value on ionChange on IonRadioGroup
    selectedRadioGroup:any;
    //Get value on ionSelect on IonRadio item
    selectedRadioItem:any;

    radioGroupChange(event) {
        console.log("radioGroupChange",event.detail);
        this.selectedRadioGroup = event.detail;
        this.dismissModal();
    }
    
    radioFocus() {
      console.log("radioFocus");
    }
    radioSelect(event) {
      console.log("radioSelect",event.detail);
      this.selectedRadioItem = event.detail;
    }
    radioBlur() {
      console.log("radioBlur");
    }

  dismissModal() {
      if (this.selectedRadioGroup != null) {
          this.modalCtrl.dismiss({
              'dismissed': true,
              'selectedlocation': this.selectedRadioGroup.value
            });    
      }
  }
}