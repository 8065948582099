<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{"About Us" | translate}}</ion-title>
    </ion-toolbar>
</ion-header>


<ion-content>
    <ion-card>
        <ion-card-header>
            <p style="color: var(--ion-color-dark);">
                We believe the best chefs are the ones at home and the best food is the one you get at home prepared by your loved ones! The next best, is the food prepared by these home chefs with the same care they take for their family! Introducing Vegecious! We are
                on a mission to bring you the choice of heathy, home cooked, hygienic pure vegetarian food prepared by the best home chefs in your neighborhood! You don't have to eat commercially prepared food as your meal! To our Home chefs, we want to be
                the platform you can leverage to reach our Vegecious community of users, provide them with the best, healthy, hygienic, vegetarian food you cook, and earn their love, fan following and extra income you can count on! So come on in and join
                our Chef network.
            </p>
        </ion-card-header>
        <ion-card-content>
            <ion-list>
                <ion-item style="cursor: pointer;" (click)="goToVegecious('https://www.vegecious.com/')">Vegecious.com</ion-item>
                <ion-item style="cursor: pointer;" (click)="goToVegecious('https://www.vegecious.com/work-with-us/')">Become a chef</ion-item>
                <ion-item style="cursor: pointer;" (click)="goToVegecious('https://www.vegecious.com/terms-of-service/')">Terms of service.</ion-item>
                <ion-item style="cursor: pointer;" (click)="goToVegecious('https://www.vegecious.com/privacy-policy/')">Privacy Policy.</ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>
</ion-content>