import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IonSlides } from '@ionic/angular';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'page-offer',
  templateUrl: 'offer.html',
  styleUrls: ['offer.scss']
})
export class OfferPage implements OnInit {
  @ViewChild('offerSlider', { static: true }) slider: IonSlides;
  offerData: Array < any > ;
  slideOptions: { [key: string]: any } = {};
  slideindx = 1;

  isBeginning$: Observable<boolean>;
  isEnd$: Observable<boolean>;

  constructor(public af: AngularFireDatabase, 
              public router: Router) {
    const queryObservable = af.list('/menuItems', ref => ref.orderByChild('offer/hasOffer').equalTo(true));
    queryObservable.snapshotChanges().subscribe(_data => {
      const data = [];
      _data.forEach((element) => {
        const _offeritem: any = element.payload.toJSON();
        _offeritem.$key = element.key;
        data.push(_offeritem);
      });
      // console.log('Offer Data: ' + JSON.stringify(data));
      this.offerData = data;
      console.log(data);
    });
  }

  ngOnInit() {
    console.log('offer');
    this.slideOptions = {
      initialSlide: this.slideindx,
      slidesPerView: 1,
      speed: 400,
      pager: false
    };
  }

  addToCart(key) {
    this.router.navigate(['product-details', key]);
  }

  noOfOffers(): number {
    return (this.offerData == null ? 0 : this.offerData.length);
  }

  trackItemsById(index, item) {
    return item.id;
  }

  nextSlide() {
    if (this.slider) {
      console.log('next');
      this.slider.slideNext();
      this.slideindx++;
    }
  }

  prevSlide() {
    if (this.slider) {
      console.log('Prev');
      this.slider.slidePrev();
      this.slideindx--;
    }
  }

  hasPrev(): boolean {
    return (this.slideindx > 0)
  }

  hasNext(): boolean {
    return (this.slideindx < this.noOfOffers())
  }
}
