<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar>      
      <ion-title>
        Rate Menu Items
        <ion-chip>
          <ion-label>{{indexer.length}}</ion-label>
        </ion-chip>
      </ion-title>
      <ion-buttons slot="end" color="secondary">
        <ion-button (click)="dismissModal()">Submit</ion-button>
    </ion-buttons>
 </ion-toolbar>
</ion-header>


<ion-content class="ion-no-padding">
  <ion-grid>
    <ion-card class="ion-no-margin">
      <ion-card-header>
        <ion-card-title>
          <h3>
            <span>{{currentitemindex+1}}. </span>
            <span>{{itemstorate[sellerindex].items[indexer[currentitemindex]].title}}</span>
          </h3>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-row>
          <ion-col size="4">    
              <ion-img [src]="itemstorate[sellerindex].items[indexer[currentitemindex]].thumb"></ion-img>
          </ion-col>
          <ion-col size="8" class="center-vertical">
            <rating [(score)]="itemstorate[sellerindex].items[indexer[currentitemindex]].currentrating"></rating>
          </ion-col>
        </ion-row>
    </ion-card-content>
    </ion-card>
  </ion-grid>
</ion-content>
<ion-list style="margin-top: 10px;">
  <ion-item button color="secondary">
    <span>
      <rating [(score)]="ratingforall"></rating>
    </span>
    <ion-button slot="end" (click)="ApplyAllSameRating()">
      Apply All
    </ion-button>
  </ion-item>
</ion-list>
<ion-footer *ngIf="currentitemindex<maxitems-1">
  <ion-toolbar>
    <ion-title>Rate Next Item</ion-title>
    <ion-buttons slot="primary">
      <ion-button color="primary" (click)="nextitem()">
        <ion-icon slot="icon-only" name="arrow-forward-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
