<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar>
      <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title class="title text-center">{{"Thank you" | translate}}</ion-title>     
  </ion-toolbar>
</ion-header>

<ion-content class="page-content">
  <ion-row>
    <ion-col size="12">
      <div class="thanks">
        <h2>{{"Thank you !" | translate}}</h2>
        <p>{{"We love our customers and we value your business. we want to thank you for giving us the oppurtunity to serve
          you. Our customer service always open to hear feedback from you.thanks you!" | translate}}
        </p>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
