import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, PathLocationStrategy, AsyncPipe } from '@angular/common';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule, SERVICE_WORKER  } from '@angular/fire/compat/messaging';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, COLLECTION_ENABLED, DEBUG_MODE as ANALYTICS_DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { Configs } from './config';
import { environment } from '../environments/environment';
import { ServicesModule } from './services/services.module';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
/*
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
*/


import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    IonicModule.forRoot(), 
    /*provideFirebaseApp(() => {
      console.log('Test');
      return initializeApp(Configs[environment.name].firebaseConfig);
    }),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideStorage(() => getStorage()),
    provideMessaging(() => getMessaging()),*/
    AngularFireModule.initializeApp(Configs[environment.name].firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    // provide modular style for AppCheck, see app.browser/server
    provideFirebaseApp(() => initializeApp(Configs[environment.name].firebaseConfig)),
    ServicesModule,
    AppRoutingModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('/firebase-messaging-sw.js', { enabled: Configs[environment.name].enableServiceWorker }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwa, registrationStrategy: 'registerImmediately' }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AsyncPipe,
    GooglePlus,
    SignInWithApple,
    InAppBrowser,
    Camera,
    Facebook,
    FirebaseX,
    UserTrackingService,
    ScreenTrackingService,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    { provide: ANALYTICS_DEBUG_MODE, useValue: true },
    { provide: COLLECTION_ENABLED, useValue: true },
    { provide: SERVICE_WORKER, useFactory: () => typeof navigator !== 'undefined' && navigator.serviceWorker?.register('firebase-messaging-sw.js', { scope: '__' }) || undefined },
    { provide: APP_VERSION, useValue: Configs[environment.name].currentversion },
    { provide: APP_NAME, useValue: Configs[environment.name].firebaseConfig.projectId },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
