<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
    <ion-toolbar color="primary" style="text-align: center;">
        <ion-buttons slot="start">
            <ion-back-button text=""></ion-back-button>
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-label>{{categoryname}}</ion-label>
        <ion-buttons slot="end">
            <ion-button class="cart-icon-nav" (click)="navcart()">
                <ion-icon name="cart-outline"></ion-icon> 
                <ion-badge class="carts1">{{noOfItems}}</ion-badge>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate" *ngIf="showtextspinner || showlocationspinner"></ion-progress-bar>
</ion-header>

<ion-content>    
    <ion-grid style="background-color: var(--ion-color-secondary-tint);">
        <div *ngIf="items.length > 0">
            <ion-row>
                <ion-col *ngFor="let item of items ; index as i" sizeXl="3" sizeLg="3" sizeMd="4" sizeSm="6" sizeXs="12" class="itemtile">
                    <ion-card  (click)="navigate(item)" class="cursor-pointer itemcard">
                        <ion-card-header>
                            <ion-card-title color="primary" class="truncate">{{item.title}}</ion-card-title>
                            <ion-card-subtitle color="dark" class="cursor-pointer hyperlink" (click)="showSellerInfo($event, item)">{{item.seller.storename}}</ion-card-subtitle>
                        </ion-card-header>
                        <ion-img class="menuthumbnail" [src]="item.thumb" ></ion-img>
                        <ion-item lines="full">
                            <ion-chip slot="start" (click)="popupTags($event, item)" *ngIf="(item.tagsarr.length > 0)">
                                <ion-icon name="pricetags" color="dark"></ion-icon>
                                <ion-label color="dark">{{item.tagsarr.length}}</ion-label>
                            </ion-chip>
                            <ion-chip slot="end" (click)="popupLocations($event, item)" *ngIf="(item.locationsservedarr.length > 0)">
                                <ion-icon name="location" color="dark"></ion-icon>
                                <ion-label color="dark">{{item.locationsservedarr.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-list style="padding-top: 0;">
                            <ion-item lines="none">
                                <ion-col size="7">
                                    <rating [score]="item.rating.currentrating  | number:'1.0-0'" [totalratings]="item.rating.totalratings" readonly="true" class="rating"></rating>
                                </ion-col>
                                <ion-col size="5" class="price">
                                    <div class="ion-text-right" *ngIf="!(item.price.specialPrice != null &&  item.price.specialPrice > 0)">
                                        <ion-label color="dark">{{currency}} {{item.price.value}}</ion-label>
                                    </div>
                                    <div class="ion-text-right specialprice"  *ngIf="item.price.specialPrice != null &&  item.price.specialPrice > 0">
                                        <ion-label>{{currency}}</ion-label>
                                        <ion-label style="text-decoration:line-through; margin-right: 4px; margin-left: 4px;">{{item.price.value}}</ion-label>
                                        <ion-label>{{item.price.specialPrice}}</ion-label>
                                    </div>
                                </ion-col>
                            </ion-item>
                        </ion-list>                        
                    </ion-card>
                </ion-col>
                <ion-infinite-scroll #infiniteScroll threshold="99%" (ionInfinite)="loadMoreItems($event)">
                    <ion-infinite-scroll-content
                      loadingSpinner="bubbles"
                      loadingText="Loading more menu items...">
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
            </ion-row>
        </div>

        <div *ngIf="items.length === 0">
            <ion-list>
                <ion-item class="text-wrap">
                    <ion-row size="12">
                        {{noitemsmsg}}
                    </ion-row>
                </ion-item>
            </ion-list>
        </div>
    </ion-grid>
</ion-content>
