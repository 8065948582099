import { NgModule } from '@angular/core';
import { HomePage } from './home/home.page';
import { SignInTabsPage } from './SignInTabs/signintabs.page';
import { ProductListPage } from './product-list/product-list';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../components/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { ImageToDataUrlModule } from "ngx-image2dataurl";
import { Configs } from '../config';
import { environment } from '../../environments/environment';
import { PageNotFoundPage } from './page-not-found/page-not-found.page';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../components/directives/directives.module';
import { ProductDetailsPage } from './product-details/product-details';
import { CartPage } from './cart/cart';
import { CheckoutPage } from './checkout/checkout';
import { AboutUsPage } from './about-us/about-us';
import { FavouritePage } from './favourite/favourite';
import { NewsPage } from './news/news';
import { NewsDetailPage } from './news-detail/news-detail';
import { RateItemPage } from './rate-item/rate-item';
import { SettingsPage } from './settings/settings';
import { ThankyouPage } from './thankyou/thankyou';
import { OfferPage } from './offer/offer';
import { OrdersPage } from './orders/orders';
import { LoginPage } from './SignInTabs/login/login.page';
import { RegisterPage } from './SignInTabs/register/register.page';
import { ForgotPasswordPage } from './forgotpassword/forgotpassword.page';
import { VerifyEmailPage } from './verifyemail/verifyemail.page';
import { SellerInfoPage } from './sellerinfo/sellerinfo';
import { DeliveryAreaPage } from './deliveryarea/deliveryarea';
import { FilterPage } from './filter/filter';
import { listitemsPopupPage } from './listitems-popup/listitems-popup';
import { ChefPage } from './chef/chef';
import { SellerPage } from './seller/seller';
import { ProductListByCategoryPage } from './product-by-category/product-by-category';
import { ProductListByChefPage } from './product-by-chef/product-by-chef';


@NgModule({
  declarations: [
    PageNotFoundPage,
    HomePage,
    SignInTabsPage,
      LoginPage,
      RegisterPage,
      ForgotPasswordPage,
      VerifyEmailPage,
    ProductListPage,
      listitemsPopupPage,
    ProductListByCategoryPage,
    ProductListByChefPage,
    ProductDetailsPage,    
    CartPage,
    CheckoutPage,
    AboutUsPage,
    FavouritePage,
    OfferPage,
    OrdersPage,
    SellerInfoPage,
    DeliveryAreaPage,
    ChefPage,
    SellerPage,
    FilterPage,
    NewsPage,
    NewsDetailPage,
    RateItemPage,
    SettingsPage,
    ThankyouPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    DirectivesModule,
    IonicModule,
    ImageToDataUrlModule
  ],
  providers: [
  ],
  exports: [
  ]
})
export class PagesModule {  }
export const PageComponents = [
  PageNotFoundPage,
  HomePage,
  SignInTabsPage,
    LoginPage,
    RegisterPage,
    ForgotPasswordPage,
    VerifyEmailPage,
  ProductListPage,
    listitemsPopupPage,
    ProductListByCategoryPage,
    ProductListByChefPage,
  ProductDetailsPage,
  CartPage,
  CheckoutPage,
  AboutUsPage,
  ChefPage,
  FavouritePage,
  OfferPage,
  SellerInfoPage,
  SellerPage,
  FilterPage,
  OrdersPage,
  NewsPage,
  NewsDetailPage,
  RateItemPage,
  SettingsPage,
  ThankyouPage
]
