<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title class="title">{{"Payment Options" |translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
      <ion-button class="cart-icon-nav" (click)="navcart()">
        <ion-icon name="cart-outline"></ion-icon> 
          <ion-badge class="carts">{{noOfItems}}</ion-badge>
      </ion-button>
  </ion-buttons>  
  </ion-toolbar>
  <ion-progress-bar type="indeterminate" *ngIf="showtextspinner"></ion-progress-bar>
</ion-header>

<ion-content class="ion-padding checkOut">
  <form (ngSubmit)="onCheckOut(f)" #f="ngForm">
    <ion-card color="secondary">
      <ion-card-header>
        <ion-card-title color="primary">Delivery Details</ion-card-title>
      </ion-card-header>
    
      <ion-card-content>
        <ion-list style="background-color: var(--ion-color-secondary);">
          <ion-item-group>
            <ion-item-divider>
              <ion-label>{{"DELIVERY ADDRESS" | translate}}</ion-label>
            </ion-item-divider>
            <ion-item>
              <ion-input type="text" placeholder="Name" name="name" id="name" [(ngModel)]="address.name" required></ion-input>
            </ion-item>
            <ion-item>
              <ion-textarea rows="3" placeholder="address" name="address" id="address" [(ngModel)]="address.address" required></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-input type="text" placeholder="City" name="city" id="city" [(ngModel)]="address.city" required></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="number" placeholder="Pincode" name="pincode" id="pincode" [(ngModel)]="address.zipcode" required></ion-input>
            </ion-item>
          </ion-item-group>
          <ion-item-group style="margin-top: 15px;">
            <ion-item-divider>
              <ion-label>Phone No.</ion-label>
          </ion-item-divider>
          <ion-item>
              <ion-input type="tel" placeholder="Phone No" name="phoneno" id="phoneno" [(ngModel)]="address.phoneno" required></ion-input>
            </ion-item>
          </ion-item-group>
          <ion-item-group style="margin-top: 15px;">
            <ion-item-divider>
              <ion-label>Delivery/Special Instructions.</ion-label>
            </ion-item-divider>
            <ion-item>
              <ion-textarea rows="3" placeholder="Instructions" name="instructions" id="instructions" [(ngModel)]="address.instructions"></ion-textarea>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar color="secondary">
    <ion-row *ngIf="GrandTotal <= 0">
      <ion-col size="12">
        <ion-button *ngIf="settings && settings.paymentproviders && settings.paymentproviders.useCOD" expand="block" color="primary" (click)="onCODCheckout()" [disabled]="!f.valid">
          <ion-icon name="cash-outline"></ion-icon>&nbsp;{{"Cash on Delivery" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="GrandTotal > 0">
      <ion-col size="6">
        <ion-button *ngIf="settings && settings.paymentproviders && settings.paymentproviders.useRazorPay" expand="block" color="primary" (click)="onRazorPayCheckout()" [disabled]="!f.valid">
          <ion-icon name="card-outline"></ion-icon>&nbsp;{{"Razor Pay Checkout" | translate}}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button *ngIf="settings && settings.paymentproviders && settings.paymentproviders.useCOD" expand="block" color="primary" (click)="onCODCheckout()" [disabled]="!f.valid">
          <ion-icon name="cash-outline"></ion-icon>&nbsp;{{"Cash on Delivery" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <br>
  </ion-toolbar>
</ion-footer>
