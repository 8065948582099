<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar>
      <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>{{"Favourite" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{bg:!isFavourite()}">
   <div *ngIf="!isFavourite()">
     <ion-row>
       <ion-icon class="empty-cart" name="heart"></ion-icon>
     </ion-row>
        <h4>Wish List is empty!</h4>
    </div>

  <div *ngIf="isFavourite()">
    <ion-card *ngFor="let favouriteItem of favouriteItems">
      <ion-list>
        <ion-item>
          <ion-thumbnail item-left>
            <img src={{favouriteItem.thumb}}>
          </ion-thumbnail>
          <p class="heading">{{favouriteItem.title}}</p>
          <p>{{favouriteItem.description}}</p>
          <ion-row>
            <ion-col size="6">
              <ion-button class="add" (click)="buyNow(favouriteItem.$key)">
                <ion-icon name="cart-outline"></ion-icon>
                &nbsp; {{"Buy Now" | translate}}
            </ion-button>
          </ion-col>
            <ion-col size="6">
            <ion-button color="danger" class="delete" fill="outline" (click)="removeFromFavourites(favouriteItem.$key)">
              <ion-icon  name="trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-item>
      </ion-list>
    </ion-card>
  </div>
</ion-content>
