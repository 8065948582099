<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
</ion-header>

<ion-content class="login">
  <img class="logo" src="assets/img/logo-icon.png">

  <!--inputs-->
  <!--email-->
  <form [formGroup]="valForm" (submit)="OnLogin($event, valForm.value)">
    <ion-row>
      <ion-col size="12">
        <ion-item style="--background: var(--ion-color-secondary-tint)">
          <!--<ion-label>{{"Email:" | translate}}</ion-label>-->
          <ion-input class="form-control" id="inputEmail" type="email" name="email" inputmode="email" placeholder="Enter email" autocomplete="off" formControlName="email" required></ion-input>
          
        </ion-item>
      </ion-col>
    </ion-row>
    <!--password-->
    <ion-row>
      <ion-col size="12">
        <ion-item  style="--background: var(--ion-color-secondary-tint)">
          <!--<ion-label>{{"Password:" | translate}}</ion-label>-->
          <ion-input id="loginPassword" [type]="showPasswordText ? 'text' : 'password'" name="password" placeholder="Password" formControlName="password" required=""></ion-input>
          <button ion-button icon-only clear [style.color]="showPasswordText ? 'black' : 'gray'" style="background: transparent;" type="button" item-right (click)="showPasswordText = !showPasswordText">
            <ion-icon size="large" name="eye"> </ion-icon>
          </button>
        </ion-item>
      </ion-col>
    </ion-row>

    <!--forget-->
    <ion-row style="margin-top: 10px; font-size: small;">
      <ion-col size="12" class="ion-text-center">
        <a routerLink="/forgotpassword">{{"FORGOT PASSWORD ?" | translate}}</a>
      </ion-col>
    </ion-row>
    <!--button-->
    <ion-row>
      <ion-col size="10" offset="1">
        <ion-button expand="block" type="submit" [disabled]="!valForm.valid">{{"Login" | translate}}</ion-button>
      </ion-col>
    </ion-row>
    <!--login with-->
    <ion-row class="hideforsmall">
      <ion-col size="12">
        <p class="ion-text-center" style="font-size:12px;margin:0;">{{"LOGIN WITH" | translate}}</p>
      </ion-col>
    </ion-row>
  </form>
  <!--Social login-->
  <ion-row style="font-size: 44px;">
    <ion-col class="ion-text-center" size-xs="12" size-md>
      <ion-button expand="block" (click)="googleLogin()">
          <ion-icon slot="start" name="logo-google"></ion-icon>
          Sign in with Google
      </ion-button>     
    </ion-col>   
    <ion-col class="ion-text-center" size-xs="12" size-md>
      <ion-button expand="block" (click)="doFbLogin()">
        <ion-icon slot="start" name="logo-facebook"></ion-icon>
        Sign in with Facebook
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-center" size-xs="12" size-md *ngIf="isBrowser || isIOS">
      <ion-button class="apple-color" expand="block" (click)="appleLogin()" >
          <ion-icon slot="start" name="logo-apple"></ion-icon>
          Sign in with Apple
      </ion-button>     
    </ion-col>   
  </ion-row>
  <ion-row style="margin-top: 5px;">
    <ion-col size="12" size-xs>
      <ion-button expand="block" (click)="checkProducts()" color="secondary">
        <ion-icon slot="start" name="restaurant-outline"></ion-icon>
        Or browse our products.
    </ion-button> 
    </ion-col>
  </ion-row>
</ion-content>