import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { GeoService } from '../../services/geofire/geo.service';
import { LoadingController, ModalController, IonInfiniteScroll, PopoverController } from '@ionic/angular';
import { Configs } from '../../config';
import { DbService } from '../../services/db-service/dbservice';
import { SellerInfoPage } from '../sellerinfo/sellerinfo';
import { FilterPage } from '../filter/filter';
import { listitemsPopupPage } from '../listitems-popup/listitems-popup';
import { CartService } from '../../../app/services/cart-service/cart.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'page-product-by-chef',
  templateUrl: './product-by-chef.html',
  styleUrls: ['./product-by-chef.scss']
})
export class ProductListByChefPage implements OnInit/*, OnDestroy*/ {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  
  chefid: any;
  public menuItems: Array<any> = [];
  public selectedItems: Array<any> = [];
  filter$: any = {
    location: ''
  };
  

  Cart: any = [];
  noOfItems: any;
  items: any[] = [];
  currency: string = Configs[environment.name].Currency.symbol;
  lat: number;
  lng: number;
  markers: any;
  isloaderdismissed = false;
  noitemsmsg = '';
  missingmenuitem: string = '';
  defaultsellericon: string = '';
  chefname: string = '';
  sellerid: string = '';

  allLocations$ = [];
  selectlocationfilter = '';
  searchtextfilter = '';
  constructor(public af: AngularFireDatabase,
              public loadingCtrl: LoadingController,
              public router: Router,
              public route: ActivatedRoute,
              public dbsvc: DbService,
              public cartSvc: CartService,
              public modalController: ModalController,
              public analytics: AngularFireAnalytics,
              public popoverController: PopoverController) {
    this.getcartcounts();

    this.dbsvc.getLocations().then((data) => {
      this.allLocations$ = data;
     });
  }

  loadData(event) {
    setTimeout(() => {
      console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      /*if (data.length == 1000) {
        event.target.disabled = true;
      }*/
    }, 500);
  }

  currentPopover = null;
  async popupLocations($event: Event, item: any) {
    $event.stopPropagation();
    let popover = await this.popoverController.create({
      component: listitemsPopupPage,
      event: $event,
      translucent: true,
      componentProps: {
        'data': {
          'listitems': item.locationsservedarr,
          'title': 'Locations Served',
        }
      }
    });
    this.currentPopover = popover;
    popover.present();
    return true;
  }

  async popupTags($event: Event, item: any) {
    $event.stopPropagation();
    let popover = await this.popoverController.create({
      component: listitemsPopupPage,
      event: $event,
      translucent: true,
      componentProps: {
        'data': {
          'listitems': item.tagsarr,
          'title': 'Tags',
        }
      }
    });
    this.currentPopover = popover;
    popover.present();
    return true;
  }

  getcartcounts() {
    this.Cart = JSON.parse(localStorage.getItem('Cart'));
    if (localStorage.getItem('Cart') != null) {
      this.noOfItems = this.Cart.length;
    }
  }
  ionViewWillEnter() {
    this.currentActiveSeller = this.cartSvc.getSellerFromCart();
    console.log('Active Seller: ' + this.currentActiveSeller);
    this.getcartcounts();
  }

  currentActiveSeller = '';
  async ngOnInit() {
    if (this.selectedItems.length > 0)
      return;
    this.route.paramMap.subscribe(params => {
      let id = params.get('id');
      this.chefname = id;
      this.items = [];
      this.selectedItems = [];
      if (this.missingmenuitem.length === 0) {
        this.dbsvc.getMissingMenuThumb().then((url) => {
          this.missingmenuitem = url;
        }, (err) => {
          this.missingmenuitem = '';
        });  
      }
      if (this.defaultsellericon.length === 0) {
        this.dbsvc.getMissingSellerThumb().then((url) => {
          this.defaultsellericon = url;
        }, (err) => {
          this.defaultsellericon = '';
        });  
      }
      
      this.dbsvc.getSellerByName(this.chefname)
        .then((sellerdetails: any) => {
          if (sellerdetails != null) {
            console.log('Seller ' + this.chefname + ' details');
            console.log(JSON.stringify(sellerdetails));  
            this.sellerid = sellerdetails.sellerId;
            console.log('Sellerid: ' + this.sellerid);
            // get seller menuitems
            let _cachedproductlists = this.dbsvc.getProductListForChef(this.sellerid);
            if (_cachedproductlists._items.length > 0) {
              this.selectedItems = _cachedproductlists._items;
              this.startAt = _cachedproductlists.nextKey;
              this.stillmoreitems = _cachedproductlists.nextKey != null;
              this.initializeItems();
              // this.getFilteredItems();
            } else {
              this.firstdataload();
            }      
      
          } else {
            console.log('No details found for seller ' + this.chefname);
          }
        })
      
      
      
      /*let _cacheditems = this.dbsvc.getProductListForCategory();
      if (_cacheditems.length > 0) {
        this.selectedItems = _cacheditems;
        this.initializeItems();
        this.getFilteredItems();
      } else {
        this.firstdataload();
      }*/
    });    
  }


  logAnalyticsForListItems(){
    console.log('Logging ItemView');  
    console.log(this.selectedItems);
    let _items = this.selectedItems.map(x => ({item_id: x.$key, item_name: x.title, price: x.price.value, item_category: 'food'}));
    this.analytics.logEvent('view_item_list', {
      item_list_id: 1,
      item_list_name: 'product-list',
      items: _items
    });
  }
  
  async firstdataload() {
    const loader = await this.loadingCtrl.create({
      message: 'Please wait...',
      cssClass: 'loadingmsg'
    });
    loader.present().then(() => {
      this.getData().then((hasmoredata) => {
        if (!hasmoredata) {
          console.log('All data retrieved.... disabling infinite scroll');
          this.stillmoreitems = false;
        }
        loader.dismiss();
      });
    });
  }

  startAt: string = '';
  nitemsperbatch: number = 100;
  stillmoreitems = true;
  getData(): Promise<boolean> {
    const getDataPromise = new Promise<boolean>((resolve, reject) => {
      this.dbsvc.getMenuItemsRangeBySellerId(this.startAt, this.nitemsperbatch, this.missingmenuitem, this.defaultsellericon, true, this.sellerid).then((result) => {        
        if (result.data.length > 0) {
          this.selectedItems = this.selectedItems.concat(result.data);
          this.items = this.selectedItems;
          if (this.startAt == '') {
            this.logAnalyticsForListItems();
          }
          this.startAt = result.nextkey;
          this.dbsvc.setProductListForChef(this.sellerid, this.selectedItems, this.startAt);
          // this.getFilteredItems();
          console.log('next key: ' + this.startAt);
        } 
        
        resolve(result.nextkey !== null);
      });
    });
    return getDataPromise;
  }

  loadMoreItems(event) {
    if (!this.stillmoreitems) {
      event.target.complete(); // Inform the current event load is completed.
      event.target.disabled = true;
    } else {
      this.getData().then((hasmoredata) => {
        event.target.complete(); // Inform the current event load is completed.
        if (!hasmoredata) {
          console.log('All data retrieved.... disabling infinite scroll');
          this.stillmoreitems = false;
          event.target.disabled = true;
        }
      });
    }
  }
  /*
  ngOnDestroy() {
  }*/

  showbycategory(card) {
    console.log('Card: ' + JSON.stringify(card));
  }

  initializeItems() {
    this.items = this.selectedItems;
  }

  getFilteredItems() {
    let _srcitem = this.chefname.toUpperCase();
    this.initializeItems();

      this.items = this.items.filter((data) => {
        return (
              // data.seller.name.toUpperCase().indexOf(_srcitem) > -1)
              data.seller.storename.toUpperCase().indexOf(_srcitem) > -1
        );
      });  
  }    
  

  removeLocationFilter() {
    if (this.selectlocationfilter.length > 0) {
      this.selectlocationfilter = '';
      this.getFilteredItems();
    }
  }

  loadRemainingItems(): Promise<boolean> {
    const loadRemainingItemsPromise = new Promise<boolean>((resolve, reject) => {
      this.nitemsperbatch = 50000;
      this.getData().then((hasmoredata) => {
        if (!hasmoredata) {
          console.log('All data retrieved.... disabling infinite scroll');
          this.stillmoreitems = false;
        }
        resolve(true);
      });
    });
    return loadRemainingItemsPromise;
  }

  showlocationspinner = false;
  getItemsByLocation(ev: any) {
    // make sure all items are loaded, and then filter.
    this.showlocationspinner = true;
    if (!this.stillmoreitems){
      this.getFilteredItems();
      this.showlocationspinner = false;
    } else {
      this.loadRemainingItems().then(() => {
        this.getFilteredItems();
        this.showlocationspinner = false;
      });
    }
  }

  showtextspinner = false;
  async getItems(ev: any) {
      this.showtextspinner = true;
      if (!this.stillmoreitems){
        this.initializeItems();
        const val = ev.target.value;
        if (val && val.trim() !== '') {
          this.searchtextfilter = val.trim();
          this.getFilteredItems();
        }
        this.showtextspinner = false;
        ev.target.setFocus();
      } else {
        this.loadRemainingItems().then(() => {
          this.initializeItems();
          const val = ev.target.value;
          if (val && val.trim() !== '') {
            this.searchtextfilter = val.trim();
            this.getFilteredItems();
          }
          this.showtextspinner = false;
          ev.target.setFocus();
        }, (err) => {
          ev.target.setFocus();
          this.showtextspinner = false;      
        });
      }
  }


  async presentSellerInfoModal(item: any) {
    const modal = await this.modalController.create({
      component: SellerInfoPage,
      cssClass: 'seller-info.scss',
      animated: true,
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      swipeToClose: true,
      componentProps: {
        'seller': {
          'sellerid': item.sellerid,
          'name': item.seller.name,
          'storename': item.seller.storename,
          'thumb': item.seller.thumb,
          'description': item.seller.description
        }
      }
    });
    modal.onDidDismiss().then((modalResult) => {
      if (modalResult.data != null) {
        this.navigate(modalResult.data.itemid);
      }
    });
    return await modal.present();
  }


  showSellerInfo(event: Event, item: any) {
    event.stopPropagation();
    console.log('item: ' + JSON.stringify(item));
    this.presentSellerInfoModal(item);
    return true;
  }

  navigate(item) {
    console.log("id---"+item)
    this.analytics.logEvent('select_item', {
      item_list_id: 1,
      item_list_name: 'product-list',
      items: [{
        item_id: item.$key,
        item_name: item.title
      }]
    });
    this.router.navigate(['/product-details', item.$key]);
  }

  navcart() {
    this.router.navigate(['/cart']);
  }



  async presentFilterModal() {
    const modal = await this.modalController.create({
      component: FilterPage,
      cssClass: 'filter.scss',
      animated: true,
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      swipeToClose: true,
      componentProps: {
          filter: this.filter$
      }
    });
    modal.onDidDismiss().then((modalResult) => {
      console.log('filter dismissed');
      if (modalResult.data != null) {
        //this.navigate(modalResult.data.itemid);
        console.log('Filter is: ' + modalResult.data);
        this.filter$ = modalResult.data;
      }
      this.getData();
    });
    return await modal.present();
  }


  hasActiveFilter(): boolean {
    return ((this.filter$ !== null) && (this.filter$.location !== null) && (this.filter$.location.length > 0));
  }

  showfilters(event: Event) {
    event.stopPropagation();
    this.presentFilterModal();
    return true;
  }
}
