import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.page.html',
  styleUrls: ['./page-not-found.scss']
})
export class PageNotFoundPage {
  constructor( ) { }                
}
