<ion-tabs>
  <ion-tab-bar slot="bottom" color="primary">
    <ion-tab-button tab="login">
      <ion-label>Login</ion-label>
      <ion-icon name="person-circle"></ion-icon>
    </ion-tab-button>

    <ion-tab-button tab="register">
      <ion-label>Register</ion-label>
      <ion-icon name="person-add-outline"></ion-icon>
    </ion-tab-button>

    <ion-tab-button tab="product-list">
      <ion-label>Browse</ion-label>
      <ion-icon name="restaurant-outline"></ion-icon>
    </ion-tab-button>

  </ion-tab-bar>
</ion-tabs>
