import { Component, OnInit } from '@angular/core';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Configs } from '../../../../app/config';
import { environment } from '../../../../environments/environment';
import { UsersService } from '../../../services/users/userservice';
import { AppleSignInErrorResponse, ASAuthorizationAppleIDRequest, SignInWithApple, AppleSignInResponse } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';



@Component({
  selector: 'page-register',
  templateUrl: 'register.page.html',
  styleUrls: ['./register.page.scss']
})
export class RegisterPage {
  registration: FormGroup;
  toastduration = Configs[environment.name].toastduration;
  showPasswordText = false;
  isIOS = false;

  constructor(public navCtrl: Router,
              public fb: FormBuilder,
              public af: AngularFireAuth,
              private facebook: Facebook,
              private googlePlus: GooglePlus,             
              public db: AngularFireDatabase,
              private signInWithApple: SignInWithApple,
              public loadingCtrl: LoadingController,
              public platform: Platform,
              public userssvc: UsersService,
              public toastCtrl: ToastController) {
    this.isIOS = this.platform.is('ios');
    console.log('IOS: ' + this.isIOS);

  }

  sendEmailVerification() {
    // [START sendemailverification]
  
  }
  onSubmit() {
    console.log('this.registration.value.email: ' + JSON.stringify(this.registration.value.email));

    this.af.createUserWithEmailAndPassword(this.registration.value.email, this.registration.value.password)
      .then((_user) => {
         console.log('Firebase success: ' + JSON.stringify(_user));
        this.db.object('/users/' + _user.user.uid).update({
          name: this.registration.value.name,
          email: this.registration.value.email,
          mobileNo: this.registration.value.mobileNo,
          role: 'User',
          emailverified: _user.user.emailVerified
        }).then(() => {
          this.af.currentUser.then((cuser) => {
            cuser.sendEmailVerification().then(() => {
              localStorage.setItem('uid', _user.user.uid);
              this.navCtrl.navigate(['./verifyemail']);
            });  
          });
        })
        .catch((err) => {
          console.log(err);
        })
      }).catch((err) => {
        console.log(err);
        this.createToaster(err.message, 5000);
      });
  }

  async createToaster(message, duration) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration
    });
    toast.present();
  }

  navLogin() {
    // this.navCtrl.push('LoginPage');
  }

  ngOnInit(): any {
      this.buildForm();
  }

// Validation
  buildForm(): void {
    this.registration = new FormGroup({
      'name': new FormControl('', Validators.required),
      'mobileNo': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$')]),
      'password': new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(24)])
    });
  }

  doFbLogin() {
    let permissions = new Array();
    permissions = ['public_profile', 'email'];

    this.facebook.login(permissions)
      .then((success) => {
        console.log('facebook Success response->' + JSON.stringify(success));
        // tslint:disable-next-line:ban-comma-operator
        this.facebook.api('/me?fields=id,name,email,gender,first_name,last_name', permissions).then((user) => {
          const provider = firebase.auth.FacebookAuthProvider.credential(success.authResponse.accessToken);

          this.af.signInWithCredential(provider)
            .then((response) => {
              console.log('Firebase -fb success: ' + JSON.stringify(response));
              this.db.object('/users/' + response.user.uid).update({
                name: response.user.displayName,
                email: response.user.email,
                role: 'User'
              });
              localStorage.setItem('uid', response.user.uid);
              this.navCtrl.navigate(['./product-list/all']);
            })
            .catch((error) => {
              let errmsg = JSON.stringify(error);
              console.log('Firebase failure:--- ' + errmsg);
              this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
            });

        }),
          (error) => {
            let errmsg = JSON.stringify(error);
            console.log('Firebase failure:--- ' + errmsg);
            this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
          };
      }, error => {
        let errmsg = JSON.stringify(error);
        console.log('Firebase failure:--- ' + errmsg);
        this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
      });
  }

  async appleIosLogin() {
    console.log('Performing ios native apple login.');
    try {
      const loading = await this.loadingCtrl.create({
        message: 'Login Please Wait...',
        cssClass: 'loadingmsg'
      });
      loading.present();

      this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      })
      .then((appleCredential: AppleSignInResponse) => {
        const credential =  new firebase.auth.OAuthProvider('apple.com').credential(appleCredential.identityToken);
        this.af.signInWithCredential(credential)
          .then((result) => {
            console.log('Login successful'); 
            if (result.additionalUserInfo.isNewUser) {
              console.log('Calling hasrole.');
              this.userssvc.HasRole('User', result.user.email).then((hasrolesuccess) => {
                if (hasrolesuccess == null) {
                  console.log('Registering...');
                  // console.log("Firebase -G success: " + JSON.stringify(response));
                  this.db.object('/users/' + result.user.uid).update({
                      profilepictureurl: result.user.photoURL,
                      name: result.user.displayName,
                      email: result.user.email,
                      role: 'User'
                    });
                  localStorage.setItem('uid', result.user.uid);
                  loading.dismiss();
                  this.navCtrl.navigate(['./product-list/all']);
                } else {
                  loading.dismiss();
                  this.createToaster('User allready registered. Please login', this.toastduration);
                  this.navCtrl.navigate(['signintabs', 'login']);
                }
              });              
            } else {
              console.log('This is not a new user...');
              loading.dismiss();
              this.createToaster('User allready registered. Please login', this.toastduration);
              this.navCtrl.navigate(['signintabs', 'login']);
            }
          })
          .catch((error) => {
            console.log(error);
            loading.dismiss();
            this.createToaster('Login Failure: Error.' + error, this.toastduration);
          });
          

      })
      .catch((error: AppleSignInErrorResponse) => {
        console.log(error.code + ' ' + error.localizedDescription);
        console.error(error);
        loading.dismiss();
        this.createToaster('Login Failure: Error.' + error, this.toastduration);
      });  
    }
    catch{error => {
        console.log(error.code + ' ' + error.localizedDescription);
        console.error(error);
      }
    }
  }

  async appleNonIosLogin() {
    console.log('Performing non ios apple login.');
    try {
      const loading = await this.loadingCtrl.create({
        message: 'Login Please Wait...',
        cssClass: 'loadingmsg'
      });
      loading.present();

      var provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('name');
      provider.addScope('email');

      this.af.signInWithPopup(provider).then((result) => {
        if (result != null) {
          console.log(result);
          // The signed-in user info.
          // alert('Apple token: ' + result);
          var user = result.user;
          // You can also get the Apple OAuth Access and ID Tokens.
          // var accessToken = result.credential.accessToken;
          // var idToken = result.credential.idToken;
  
          // check if the user has role.
          if (result.additionalUserInfo.isNewUser) {
            console.log('Calling hasrole.');
            this.userssvc.HasRole('User', result.user.email).then((hasrolesuccess) => {
              if (hasrolesuccess == null) {
                console.log('Registering...');
                // console.log("Firebase -G success: " + JSON.stringify(response));
                this.db.object('/users/' + result.user.uid).update({
                    profilepictureurl: result.user.photoURL,
                    name: result.user.displayName,
                    email: result.user.email,
                    role: 'User'
                  });
                localStorage.setItem('uid', result.user.uid);
                loading.dismiss();
                this.navCtrl.navigate(['./product-list/all']);
              } else  {
                loading.dismiss();
                this.createToaster('User allready registered. Please login', this.toastduration);
                this.navCtrl.navigate(['signintabs', 'login']);
              }
            });  
          } else  {
            // not a new user.
            console.log('This is not a new user...');
            loading.dismiss();
            this.createToaster('User allready registered. Please login', this.toastduration);
            this.navCtrl.navigate(['signintabs', 'login']);
          }
        } else {
          loading.dismiss();
        }  
      }, (err) => {
        console.log('Err:');
        loading.dismiss();
      });
    }
    catch{error => {
        console.log(error.code + ' ' + error.localizedDescription);
        console.error(error);
      }
    };
  }

  async appleLogin() {
    if (this.isIOS) {
      this.appleIosLogin();
    } else {
      this.appleNonIosLogin();
    }
  }

  
  googleLogin() {
    this.googlePlus.login({
      'scopes': 'profile email',
      'webClientId': Configs[environment.name].googleConfig.clientid,
      'offline': false
    })
      .then(async (success) => {
          console.log('you have been successfully logged in by googlePlus!' + JSON.stringify(success));
          const profileimage = success.imageUrl;
          const loading = await this.loadingCtrl.create({
            message: 'Login Please Wait...',
            cssClass: 'loadingmsg'
          });
          loading.present();
          const provider = firebase.auth.GoogleAuthProvider.credential(success.idToken);

          this.af.signInWithCredential(provider)
            .then((response) => {
              // console.log("Firebase -G success: " + JSON.stringify(response));
              this.db.object('/users/' + response.user.uid).update({
                name: response.user.displayName,
                email: response.user.email,
                profilepictureurl: profileimage || 'assets/img/profile.jpg',
                role: 'User'
              });
              localStorage.setItem('uid', response.user.uid);
              loading.dismiss();
              this.navCtrl.navigate(['./product-list/all']);
            })
            .catch((error) => {
              let errmsg = JSON.stringify(error);
              console.log('Firebase failure:--- ' + errmsg);
              this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
            });
        },
        (error) => {
          let errmsg = JSON.stringify(error);
          console.log('Firebase failure:--- ' + errmsg);
          this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
        });
  }

  /*
  twitterLogin() {
    this.twitter.login().then(
      (result) => {
      // console.log("twitter res--"+JSON.stringify(result));

      this.twitter.showUser().then(
        (error) => {
          let errmsg = JSON.stringify(error);
          console.log('Firebase failure showuser:--- ' + errmsg);
          this.createToaster('Firebase failure showuser: ' + errmsg, this.toastduration);
        },
        async (user) => {
        // console.log("user--"+JSON.stringify(user));
         const loading = await this.loadingCtrl.create({
            message: 'Login Please Wait...'
          });
          loading.present();

        const provider = auth.TwitterAuthProvider.credential(result.token, result.secret);

         this.af.auth.signInWithCredential(provider)
            .then((response) => {
              console.log('Firebase -G success: ' + JSON.stringify(response));
              this.db.object('/users/' + response.user.uid).update({
                name: response.user.displayName,
                email: response.user.email,
                role: 'User'
              });
              localStorage.setItem('uid', response.user.uid);
              loading.dismiss();
              this.navCtrl.navigate(['./product-list/all']);
            })
            .catch((error) => {
              let errmsg = JSON.stringify(error);
              console.log('Firebase failure:--- ' + errmsg);
              loading.dismiss();
              this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
            });
      });
    },
    (error) => {
      let errmsg = JSON.stringify(error);
      console.log('Firebase failure:--- ' + errmsg);
      this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
    });
  }*/
}
