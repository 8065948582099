import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
// import { GeoFire } from 'geofire';
import { BehaviorSubject } from 'rxjs';
import { Configs } from '../../../app/config';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GeoService {
  dbRef: any;
  geoFire: any;
  hits: any;
  constructor(private db: AngularFireDatabase) {
    /// Reference database location for GeoFire
    this.dbRef = this.db.list('/locations');
    // this.geoFire = new GeoFire(this.dbRef.query.ref);
   }

   getnewhits() {
    this.hits = new BehaviorSubject([]);
    return this.hits;
   }
   /// Adds GeoFire data to database
   setLocation(key: string, coords: Array<number>) {
     this.geoFire.set(key, coords)
         .then(_ => console.log('location updated'))
         .catch(err => console.log(err));
   }

   getBusinessLocation(key: string) {
    return this.geoFire.get(key);
   }


  getAllLocations(radius: number, coords: Array<number>) {
    const ngdb = this.db;
    const locationhits = this.hits;
    const businessinfoquery = ngdb.list('/business/').valueChanges().subscribe((sellerinfos: any[]) => {
      const currentHits = [];
      sellerinfos.forEach((sellerinfo: any) => {
        const hit = {
          location: location,
          distance: 0,
          key: sellerinfo.sellerId,
          sellerinfo: {
            distance: 0.0
          }
        };
        hit.sellerinfo = sellerinfo;
        currentHits.push(hit);
        hit.sellerinfo.distance = 0;

      });
      locationhits.next(currentHits);
      businessinfoquery.unsubscribe();
    });
  }

  getLocations(radius: number, coords: Array<number>) {
    if (Configs[environment.name].locationbased) {
      return this.getLocationbased(radius, coords);
    } else {
      return this.getAllLocations(radius, coords);
    }
  }
   /// Queries database for nearby locations
   /// Maps results to the hits BehaviorSubject
   getLocationbased(radius: number, coords: Array<number>) {
     const geoQuery = this.geoFire.query({
      center: coords,
      radius: radius
    });
    const ngdb = this.db;
    const locationhits = this.hits;
    const onKeyEnteredRegistration = geoQuery.on('key_entered', function(key, location, distance) {
      // console.log(key + ' entered query at ' + location + ' (' + distance + ' km from center)');
      const hit = {
        location: location,
        distance: distance,
        key: key,
        sellerinfo: {
          distance: 0.0
        }
      };
      const businessinfoquery = ngdb.list('/business/', ref => ref.orderByChild('sellerId').equalTo(key).limitToFirst(1)).valueChanges().subscribe((sellerinfo: any) => {
        if (sellerinfo.length > 0) {
          hit.sellerinfo = sellerinfo[0];
          hit.sellerinfo.distance = distance;
        }
        const currentHits = [];
        currentHits.push(hit);
        locationhits.next(currentHits);
        businessinfoquery.unsubscribe();
      });
    });
    geoQuery.on('ready', function() {
      // This will fire once the initial data is loaded, so now we can cancel the "key_entered" event listener
      onKeyEnteredRegistration.cancel();
    });
   }
}
