import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController, PopoverController, AlertController, LoadingController, PickerController } from '@ionic/angular';
import { PickerOptions } from '@ionic/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { Configs } from '../../config';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DbService } from '../../services/db-service/dbservice';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'page-cart',
  templateUrl: 'cart.html',
  styleUrls: ['cart.scss']
})
export class CartPage implements OnInit {
  Cart: any[] = [];
  settings: any = {};
  subTotal = 0;
  GrandTotal = 0;
  taxamt = 0;
  noOfItems = 0;
  total = 0;
  currency: string = Configs[environment.name].Currency.symbol;
  autoclose = true;

  promocode = '';
  promocodevalid = false;
  promocodeneverchecked = true;
  validating = false;
  discpct = 0.0;
  discamt = 0.0;


  constructor(public db: AngularFireDatabase,
              public router: Router,
              public analytics: AngularFireAnalytics,
              public modalCtrl: ModalController,
              public popoverCtrl: PopoverController,
              public alertCtrl: AlertController,
              private dbsvc: DbService,
              private pickerCtrl: PickerController,
              public loadingCtrl: LoadingController,
              public toastCtrl: ToastController) {   
      // Called after the constructor and called  after the first ngOnChanges() 
      console.log('In cart constructor');
      this.resetPromoCodeDetails();
      this.calculateDelivery();
  }

  pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
  }

  getSum(items: any[]) {
    let s = 0;
    items.forEach((o, i) => {
      s += (o.specialPrice != null && o.specialPrice > 0 ? o.specialPrice : o.totalcost);
    });
    return s;
  }

  onQtyChange(cartitem, itemindex){
    let data = this.Cart[itemindex];
    let baseprice = (data.item.price.specialPrice > 0 ? data.item.price.specialPrice : data.item.price.value);
    let additionalprice = data.item.additionalprice != null ? 
                          (
                            (data.item.additionalprice.specialPrice != null && data.item.additionalprice.specialPrice > 0) ? data.item.additionalprice.specialPrice : (data.item.additionalprice.value)
                          ) : 0;
    let extraoptionprice = (data.item.extraOptions.length > 0 ? this.getSum(data.item.extraOptions) : 0);
    let total = (data.item.itemQuantity * (additionalprice != null && additionalprice > 0 ? additionalprice : baseprice)) + extraoptionprice;
    data.item.totalprice = total;
    data.itemTotalPrice = total;
    this.computeGrandTotal();
    localStorage.setItem('Cart', JSON.stringify(this.Cart));
  }

  getSellerCurrentTime(zone: string): Date {
    var here = new Date();
    var invdate = new Date(here.toLocaleString('en-US', {
      timeZone: zone
    }));

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    var diff = here.getTime() - invdate.getTime();

    // so 12:00 in Toronto is 17:00 UTC
    return new Date(here.getTime() - diff); // needs to substract
  }

  ionViewWillEnter(){
    this.ngOnInit();
  }

  expandItem(itemIndex: number, cartitem: any) {
    console.log('Expand items');
    cartitem.open = !cartitem.open;

    if (this.autoclose) {
      this.Cart
        .filter((item, i) => i !== itemIndex)
        .map((item) => item.open = false);
    }
  }

  _inited = false;
  ngOnInit() {
    if (this._inited)
      return;
    this._inited = true;
    console.log('On Init called');
    this.Cart = JSON.parse(localStorage.getItem('Cart'));    
    console.log(this.Cart);
    let businessinfopromise = this.dbsvc.getSellerDetails(this.Cart[0].item.sellerId);
    let settingspromise = this.dbsvc.getSettings();
    Promise.all([businessinfopromise, settingspromise]).then((_results) => {
      let _businessinfo = _results[0];
      let _settings = _results[1];
      let timeinsellerzone = this.getSellerCurrentTime(Configs[environment.name].sellertimezone);
        
      let minsinday = 60*24;
      let currenttimemins = timeinsellerzone.getHours() * 60 + timeinsellerzone.getMinutes();          
      let _starthour = (_businessinfo.workinghours.starttime.hour || _settings.workinghours.starttime.hour);
      let _startmin = (_businessinfo.workinghours.starttime.minute || _settings.workinghours.starttime.minute);
      let _endhour = (_businessinfo.workinghours.endtime.hour || _settings.workinghours.endtime.hour);
      let _endmin = (_businessinfo.workinghours.endtime.minute || _settings.workinghours.endtime.minute);

      let startmin = _starthour * 60 + _startmin;
      let endmin = _endhour * 60 + _endmin;
      if (endmin < startmin) {
        // this will be due to we are crossing over midnight.
        if (currenttimemins < endmin) {
          currenttimemins = minsinday + currenttimemins;  
        }
        endmin = minsinday + endmin;
      }

      console.log('currenttimemins: ' + currenttimemins);
      console.log('Startmin: ' + startmin);
      console.log('Endmin: ' + endmin);

      if ((currenttimemins > startmin) && (currenttimemins < endmin)) {
        // All good... we are in business time.
      } else {
        // we are outside business hours. Let user know.
        /*let remainingtime = 0;
        let nextday = false;
        if (currenttimemins > endmin) {
          remainingtime = (minsinday - currenttimemins) + startmin;
          nextday = true;
        } else {
          nextday = false;
          remainingtime = startmin - currenttimemins;
        }
        let remainingtimehours = Math.floor(remainingtime / 60);
        let remainingtimemins = remainingtime - (remainingtimehours * 60);
        let timetoprocess = (remainingtime / 60);
        let msg = '';
        if (remainingtimehours > 0) {
          msg = 'Order will be processed in next ' + remainingtimehours + ' hours, ' + remainingtimemins + ' mins.';
        } else {
          msg = 'Order will be processed in next ' + remainingtimemins + ' mins.';
        }
        this.createToaster(msg, 10000);*/
        this.createToaster('Vegecious Alert', 'Your order is being placed outside the seller ' + _businessinfo.storeName + ' business hours. It will be processed during business hours. (' + this.pad(_starthour, 2) + ':' + this.pad(_startmin, 2) + ' to ' + + this.pad(_endhour, 2) + ':' + this.pad(_endmin, 2) +')', 10000)
      }

        console.log('Seller Time: ' + timeinsellerzone.toISOString());

    });

    this.resetPromoCodeDetails();
    if (this.Cart != null) {
      this.noOfItems = this.Cart.length;
      this.Cart.map((cartitem) => {
        cartitem.open = false;
        cartitem.item.price.specialPrice = cartitem.item.price.specialPrice || 0;
      });
      this.computeGrandTotal();      
    }
    
  }

  calculatingdelivery = false;
  deliverycharge = 0;
  calculateDelivery() {
    console.log('calculateDelivery: ');
    this.deliverycharge = 0;
    //this.resetPromoCodeDetails(); // reset any previous promocode details 
    this.computeGrandTotal().then((res) => {
      console.log('For cart: ' + JSON.stringify(this.Cart));
      this.calculatingdelivery = true;
      this.dbsvc.calculateDelivery(this.Cart, this.GrandTotal).then(async (deliverychargedetails: any) => {
        console.log('Delivery Charge: ' + JSON.stringify(deliverychargedetails));
        this.deliverycharge = deliverychargedetails.deliverycharge;
        this.calculatingdelivery = false;
        this.computeGrandTotal();
      }, (err) => {
        this.calculatingdelivery = false;
        console.log('Error calculating delivery: ' + JSON.stringify(err));
        this.computeGrandTotal();
      });  
    }, (err) => {
      console.log('error calculating delivery charge');
      this.computeGrandTotal();
    });
  }

  validatePromoCode() {
    console.log('Validating promocode: ' + this.promocode);
    this.resetPromoCodeDetails(); // reset any previous promocode details 
    this.computeGrandTotal().then((res) => {
      console.log('For cart: ' + JSON.stringify(this.Cart));
      this.promocodeneverchecked = false;
      this.validating = true;
      this.dbsvc.validatePromoCode(this.promocode, this.Cart, this.GrandTotal).then(async (promocodedetails: any) => {
        console.log('Promocode Details: ' + JSON.stringify(promocodedetails));
        this.validating = false;
        if (promocodedetails.isValid) {
          this.promocodevalid = true;
          this.discpct = promocodedetails.discpct;
          this.discamt = promocodedetails.discamt;
        } else {
          this.resetPromoCodeDetails();
          this.createToaster('Error Validating Promocode', promocodedetails.reason, 1000);
        }
        this.applyPromoCode();
        this.calculateDelivery();
      }, (err) => {
        this.validating = false;
        this.resetPromoCodeDetails();
        this.applyPromoCode();
        this.calculateDelivery();
        this.createToaster('Validate Promocode', 'Error Validating PromoCode', 1000);
        console.log('Error Validating promocode: ' + JSON.stringify(err));
      });  
    }, (err) => {
      console.log('error calculating grand total');
    });
  }

  resetPromoCodeDetails() {
    localStorage.removeItem('promocode');
    this.promocodevalid = false;
    this.discpct = 0;
    this.discamt = 0;
  }

  revalidatePromoCode(){    
    if ((this.promocode != null) && (this.promocode.length > 0)){
      console.log('Cart Changed: Revalidating promocode: ' + this.promocode);
      this.resetPromoCodeDetails();
      this.validatePromoCode();
    } else {
      console.log('Cart Changed: computing total');
      this.resetPromoCodeDetails();
      this.computeGrandTotal();
    }

  }
  
  applyPromoCode() {
    if (this.promocodevalid) {
      localStorage.setItem('promocode', JSON.stringify({
        promocode: this.promocode,
        isvalid: this.promocodevalid,
        discpct: this.discpct,
        discamt: this.discamt
      }));
  
      if (this.discpct > 0) {
        this.discamt = (this.discpct / 100 * this.subTotal);
      } else {
        // we use the discount amount. nothing to do then.
      }  
    }
    this.GrandTotal = this.subTotal - this.discamt;
  }

  async showQuantityPicker(event: any, item: any) {
    event.stopPropagation();
    const min = item.quantityrange.min;
    const max = item.quantityrange.max;
    const qty = item.itemQuantity;

    let pickercolopts = [];

    for (let qtycounter = min; qtycounter <= max; qtycounter++) 
    {
      if (qtycounter == qty) {
        const coloption = {
          text: qtycounter.toString(),
          value: qtycounter,
          selected: true
        }
        pickercolopts.push(coloption);
      } else {
        const coloption = {
          text: qtycounter.toString(),
          value: qtycounter,
          selected: false
        }
        pickercolopts.push(coloption);
      }
      
    }

    let opts: PickerOptions = {
      keyboardClose: true,
      backdropDismiss: true,
      showBackdrop: true,
      buttons: [        
        {text: 'Cancel', role: 'cancel'},
        {text: 'Confirm', role: 'ok'},
      ],
      columns: [
        {
          name: 'Quantity',
          options: pickercolopts
        }
      ]
    };

    this.pickerCtrl.create(opts).then((picker) => {
      picker.present();
      picker.onDidDismiss().then(async (data:any) => {
        picker.getColumn('Quantity').then((col) => {
          let newQty = col.options[col.selectedIndex].value;
          console.log('New Qty: ' + newQty);
          if (item.itemQuantity != newQty) {
            item.itemQuantity = newQty;
            for (let i = 0; i <= this.Cart.length - 1; i++) {
              let ExtotalPrice = 0;
              let totalPrice = 0;
              if (this.Cart[i].item.itemId === item.itemId) {
                for (let j = 0; j <= this.Cart[i].item.extraOptions.length - 1; j++) {
                  ExtotalPrice = ExtotalPrice + this.Cart[i].item.extraOptions[j].value;
                }
                totalPrice = this.Cart[i].item.price.specialPrice ? ExtotalPrice + this.Cart[i].item.price.specialPrice : this.Cart[i].item.price.value;
                this.Cart[i].itemTotalPrice = totalPrice * item.itemQuantity;
              }
            }
            localStorage.setItem('Cart', JSON.stringify(this.Cart));
            this.revalidatePromoCode();
            this.calculateDelivery();
          }  
        });
      });
    });
  }

  changeqty(event: any, item: any, _qtyinc: number) {
    event.stopPropagation();    
    item.itemQuantity = item.itemQuantity + _qtyinc;
    for (let i = 0; i <= this.Cart.length - 1; i++) {
      let ExtotalPrice = 0;
      let totalPrice = 0;
      if (this.Cart[i].item.itemId === item.itemId) {
        for (let j = 0; j <= this.Cart[i].item.extraOptions.length - 1; j++) {
          ExtotalPrice = ExtotalPrice + this.Cart[i].item.extraOptions[j].value;
        }
        totalPrice = this.Cart[i].item.price.specialPrice ? ExtotalPrice + this.Cart[i].item.price.specialPrice : this.Cart[i].item.price.value;
        this.Cart[i].itemTotalPrice = totalPrice * item.itemQuantity;
      }
    }
    localStorage.setItem('Cart', JSON.stringify(this.Cart));
    this.revalidatePromoCode();
    this.calculateDelivery();
  }


  async createToaster(header, message, duration) {
    const toast = await this.toastCtrl.create({
      color: 'primary',
      header: header,
      position: 'middle',
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  deleteItem(itemId) {
    for (let i = 0; i <= this.Cart.length - 1; i++) {
      if (this.Cart[i].item.itemId === itemId) {
        this.Cart.splice(i, 1);
        this.revalidatePromoCode();
        localStorage.setItem('Cart', JSON.stringify(this.Cart));
        this.Cart = JSON.parse(localStorage.getItem('Cart'));
        this.noOfItems = this.noOfItems - 1;
      }
    }
    localStorage.setItem('Cart', JSON.stringify(this.Cart));
    this.revalidatePromoCode();
    this.calculateDelivery();
  }

  computeGrandTotal(): Promise<boolean> {
    const computeGrandTotalPromise = new Promise<any>((resolve, reject) => {
      let _subTotal = 0;
      this.dbsvc.getSettings().then((data) => {
        this.settings = data;
        for (let i = 0; i <= this.Cart.length - 1; i++) {
          _subTotal = _subTotal + this.Cart[i].itemTotalPrice;
        }
        this.subTotal = _subTotal;
        this.applyPromoCode();
        
        this.taxamt = (this.GrandTotal) * this.settings.totalGST / 100;
        this.GrandTotal = this.GrandTotal + this.taxamt + this.deliverycharge;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    });
    
    return computeGrandTotalPromise;
  }

  async nav() {
    this.applyPromoCode();
    if (localStorage.getItem('uid') == null) {
      const alert = await this.alertCtrl.create({
        header: 'SORRY!',
        subHeader: 'Please Login First!',
        buttons: [
          {
            text: 'Ok',
            handler: data => {
              this.router.navigate(['signintabs', 'login'], { queryParams: { id: 'cart'}});
            }
          }
        ]
      });
      alert.present();
    } else {
      console.log('Logging Begin Checkout');  
      let _items = this.Cart.map(x => ({item_id: x.item.itemId, item_name: x.item.title, price: x.item.totalprice, item_category: 'food', quantity: x.item.itemQuantity}));
      this.analytics.logEvent('begin_checkout', {
        currency: 'USD',
        value: this.GrandTotal,
        items: _items
      });
      this.router.navigate(['/checkout']);
    }
  }

  add(data) {
    // if (data.item.itemQuantity < 20) {
    if (data.item.itemQuantity < data.item.quantityrange.max) {
      data.item.itemQuantity = data.item.itemQuantity + 1;
      for (let i = 0; i <= this.Cart.length - 1; i++) {
        let ExtotalPrice = 0;
        let totalPrice = 0;
        if (this.Cart[i].item.itemId === data.item.itemId) {
          this.Cart[i].item.itemQuantity = data.item.itemQuantity;
          for (let j = 0; j <= this.Cart[i].item.extraOptions.length - 1; j++) {
            ExtotalPrice = ExtotalPrice + this.Cart[i].item.extraOptions[j].value;
          }
          totalPrice = this.Cart[i].item.price.specialPrice ? ExtotalPrice + this.Cart[i].item.price.specialPrice : this.Cart[i].item.price.value;
          this.Cart[i].itemTotalPrice = totalPrice * data.item.itemQuantity;
        }
      }
      localStorage.setItem('Cart', JSON.stringify(this.Cart));
      this.revalidatePromoCode();
      this.calculateDelivery();
    }
  }

  async removeitem(itemindex, data) {
    const alert = await this.alertCtrl.create({
      header: 'Remove Item',
      subHeader: 'Do you want to remove item from the cart?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.Cart.splice(itemindex, 1);
            this.noOfItems = this.Cart.length;
            localStorage.setItem('Cart', JSON.stringify(this.Cart));
            this.computeGrandTotal();
            console.log(data);
          }
        }
      ]
    });

    await alert.present();
  }

  remove(data) {
    if ((data.item.itemQuantity >= 1) && (data.item.itemQuantity > data.item.quantityrange.min)) {
      data.item.itemQuantity = data.item.itemQuantity - 1;
      for (let i = 0; i <= this.Cart.length - 1; i++) {
        let ExtotalPrice = 0;
        let totalPrice = 0;
        if (this.Cart[i].item.itemId === data.item.itemId) {
          this.Cart[i].item.itemQuantity = data.item.itemQuantity;
          for (let j = 0; j <= this.Cart[i].item.extraOptions.length - 1; j++) {
            ExtotalPrice = ExtotalPrice + this.Cart[i].item.extraOptions[j].value;
          }
          totalPrice = (this.Cart[i].item.price.specialPrice) ? ExtotalPrice + this.Cart[i].item.price.specialPrice : ExtotalPrice + this.Cart[i].item.price.value;
          this.Cart[i].itemTotalPrice = totalPrice * data.item.itemQuantity;
        }
      }
      localStorage.setItem('Cart', JSON.stringify(this.Cart));
      this.computeGrandTotal();
    }
  }

  isCartEmpty(): boolean {    
    if (this.Cart === null) {
      return true;
    } else if (this.Cart.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  timeToReviewOrder(): number {
    return 2;
  }

  gotoHome() {
    this.router.navigate(['/product-list/all']);
  }
}
