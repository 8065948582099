import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';

@Component({
  selector: 'page-news',
  templateUrl: 'news.html',
  styleUrls: ['news.scss']
})
export class NewsPage {
  newsData: Array <any> ;
  constructor(public af: AngularFireDatabase, public router: Router) {
    this.af.list('/news').snapshotChanges().subscribe((res) => {
      const _news = [];
      res.forEach((e) => {
        const _newsel: any = e.payload.toJSON();
        _newsel.$key = e.key;
        _news.push(_newsel);
      })
      this.newsData = _news;
    });
  }

  newsDetail(key) {
    this.router.navigate(['/news-detail', key]);
  }
}
