import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

interface User {
    uid: string;
    email: string;
    photoURL?: string;
    displayName?: string;
    mobileNo?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    user: User = null;

    constructor(private afAuth: AngularFireAuth, private router: Router) {
        this.afAuth.authState.subscribe((_user) => {
            if (_user != null) {
                this.user = {
                    uid: _user.uid,
                    email: _user.email,
                    photoURL: _user.photoURL,
                    displayName: _user.displayName
                };
            } else  {
                this.user = null;
            }
        });
    }

    resetPassword(email: string) {
        return this.afAuth.sendPasswordResetEmail(email);          
    }
    

    get authenticated(): boolean {
        if (this.user !== null) {
            return true;
        } else {
            return false;
        }
    }
}
