<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
  <ion-toolbar color="secondary">
      <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
      </ion-buttons>
      <ion-title class="title">Orders
      </ion-title>
      <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
            <ion-button class="cart-icon-nav" (click)="navcart()">
                <ion-icon name="cart-outline"></ion-icon> 
              <ion-badge class="carts" color="danger">{{noOfItems}}</ion-badge>
            </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{bg:!hasOrders()}">
  <div *ngIf="!hasOrders() ">
    <ion-row>
      <ion-icon class="empty-cart" name="timer" item-left></ion-icon>
    </ion-row>
    <h4>No Orders</h4>
  </div>
    <div *ngIf="hasOrders()">
        <ion-card *ngFor="let suborder of subordersDetails" class="seller-title">
            <ion-item>
                <ion-avatar slot="start">
                    <img src="{{suborder.sellerDetails.profileimage}}"/>
                </ion-avatar>
                <ion-label>
                    <h2>{{suborder.sellerDetails.storeName}}</h2>
                    <h3 style="font-weight: bold; font-style: italic;">
                        <span>{{suborder.status}}</span>
                        <span> (Delivery ETA: {{suborder.deliverydetails.etautc | date:'medium'}})</span>
                    </h3>                    
                    <p>Suborder Id: {{suborder.suborderId}}</p>
                    <p>{{suborder.createdAt | date:'medium'}}</p>
                </ion-label>
                <ion-label slot="end" style="max-width: 80px;">
                    <h2 item-right>{{currency}} {{suborder.grandTotal}}</h2>
                    <h3 item-right>({{(suborder.paymentMethod || 'RazorPay')}})</h3>
                </ion-label>
            </ion-item>
            <ion-list>
                <ion-item *ngFor="let item of suborder.items">
                    <ion-avatar slot="start">
                        <img src="{{item.thumb}}">
                    </ion-avatar>
                    <h2>{{item.title}}</h2>
                    <h2 slot="end">{{item.itemQuantity}}</h2>
                </ion-item>
            </ion-list>
        </ion-card>
    </div>
</ion-content>
