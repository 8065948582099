import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { map, take, tap } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private afAuth: AngularFireAuth, private router: Router) {
        console.log('AuthGuard ctor');
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.afAuth.authState
                    .pipe(
                        take(1), 
                        map((authState) => {
                            return !!authState;
                        }), 
                        tap((auth) => {
                            console.log('AuthGuard: redirecting to login page.')
                            return !auth ? this.router.navigate(['/signintabs', { outlets: { 'login': 'login' } }]) : true;
                        })
                    );
    }
}