import { Injectable } from "@angular/core";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { take } from "rxjs/operators";

@Injectable()
export class FcmNativeService {   
    token;
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireAuth: AngularFireAuth,
                private angularFireDB: AngularFireDatabase
        ) {
    }
    
    /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
    updateToken(userId, token) {
         // we can change this function to request our backend service
         this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                this.angularFireDB.object('users/' + userId + '/notificationTokens/' + token).set(true);
            })
    }

    requestPermission(userId) {
        FCM.requestPushPermission().then((response) => {
            console.log(response);
            if (response) {
                FCM.getToken().then((token) => {
                    console.log(token);
                        this.token = token;
                        this.updateToken(userId, token);                
                }, (err) => {
                    console.error('Unable to get permission to notify.' + err);
                });
        
                FCM.onTokenRefresh().subscribe(token => {
                    console.log('Refreshed token: ' + token);
                    this.token = token;
                    this.updateToken(userId, token);                
                });        
            }
        });
    } 

    async getToken(userid: string) {
        console.log('Calling web getToken');
        this.requestPermission(userid);
    }

    onNotification() {
        return FCM.onNotification();
    }
} 