<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="secondary">
      <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
          <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>{{"My Cart" | translate}}</ion-title>      
      <ion-buttons slot="end">
          <ion-button class="cart-icon-nav">
              <ion-icon name="cart-outline"></ion-icon> 
              <ion-badge class="carts" >{{noOfItems}}</ion-badge>
          </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'bg': isCartEmpty()}" color="secondary">
  <div *ngIf="isCartEmpty()">
    <ion-row>
      <ion-icon class="empty-cart" name="cart-outline"></ion-icon>
    </ion-row>
    <h4>Your Cart is empty!</h4>
    <ion-row>&nbsp;</ion-row>
    <ion-row>&nbsp;</ion-row>
    <ion-button expand="block" class="add-items-btn" (click)="gotoHome()">{{'Add Items' | translate}}</ion-button>
  </div>
  <div *ngIf="!isCartEmpty()">
    <ion-list style="background-color: var(--ion-color-secondary) !important;">
      <ion-item color="secondary">
        <ion-text><p>Details</p></ion-text>
        <ion-text class="ion-text-right add-more" slot="end" (click)="gotoHome()"><p>Add more...</p></ion-text>
      </ion-item>
    </ion-list>

    <ion-list *ngFor="let data of Cart; let itemindex = index;"  detail="false" no-padding style="background-color: var(--ion-color-secondary) !important; font-size: small;">
      <ion-item-sliding>
        <ion-item color="secondary" tappable lines="none" (click)="expandItem(itemindex, data)" style="border-bottom: 1px solid gray; font-size: small;" [ngClass]="{'highlight-item': data.open}">
          <ion-icon color="dark" class="ion-hide-sm-down" slot="start" name="arrow-forward" size="small" [ngClass]="{'arrow-down-outline': data.open-outline, 'arrow-right': !data.open}"></ion-icon>
          <ion-col size="3" class="ion-text-nowrap">
            <!--<ion-badge (click)="showQuantityPicker($event, data.item)">{{data.item.itemQuantity}}</ion-badge>-->
            <ion-icon name="remove-circle" size="small" (click)="changeqty($event, data.item, -1)" [style.display]="data.item.quantityrange.min <= data.item.itemQuantity ? 'inline-block' : 'none'"></ion-icon>
              <span style="font-size: small; padding: 5px;height: 13px;width: 13px;position: relative;top: -4px;" >{{data.item.itemQuantity}}</span> <!-- (click)="showQuantityPicker($event, data.item)" -->
            <ion-icon name="add-circle" size="small" (click)="changeqty($event, data.item, 1)"  [style.display]="data.item.quantityrange.max >= data.item.itemQuantity ? 'inline-block' : 'none'"></ion-icon>
            <!--<ion-input type="number" min="{{data.item.quantityrange.min}}" max="{{data.item.quantityrange.max}}" [(ngModel)]="data.item.itemQuantity" (ionChange)="onQtyChange(data, itemindex)"></ion-input>-->
          </ion-col>
          <ion-col size="6" class="ion-text-nowrap text-ellipsis">{{data.item.title}}</ion-col>
          <ion-col size="2"  *ngIf="data.item.price.specialPrice == 0" class="ion-text-nowrap ion-float-right">{{currency}}{{data.item.price.value}}</ion-col>
          <ion-col size="2"  *ngIf="data.item.price.specialPrice > 0" class="ion-text-nowrap ion-float-right">{{currency}} <span style="text-decoration: line-through;">{{data.item.price.value}}</span>&nbsp;<span>{{data.item.price.specialPrice}}</span></ion-col>
          <ion-col size="1">
            <ion-icon (click)="removeitem(itemindex, data)" color="dark" name="trash-outline"></ion-icon>
          </ion-col>          
        </ion-item>
        <ion-item-options side="end" color="secondary">
          <ion-item-option (click)="removeitem(itemindex, data)">Remove</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>

      <div [ngClass]="{'show-order-details': data.open, 'hide-order-details': !data.open}">
        <ion-card color="primary">
          <ion-card-content>
            <ion-list style="background-color: var(--ion-color-primary) !important;">
              <ion-item style="font-size: small;" color="primary">
                <ion-col><ion-label><i>Base Price</i></ion-label></ion-col>
                <ion-col><ion-label></ion-label></ion-col>
                <ion-col *ngIf="data.item.price.specialPrice == 0">
                  <ion-label>{{currency}}{{data.item.price.value}}</ion-label>
                </ion-col>
                <ion-col *ngIf="data.item.price.specialPrice > 0">
                  <ion-label style="text-decoration: line-through;">{{currency}}{{data.item.price.value}}</ion-label>
                  <ion-label>{{currency}}{{data.item.price.specialPrice}}</ion-label>
                </ion-col>
              </ion-item>  
              <div *ngIf="data.item.extraOptions.length > 0">
                <div *ngFor="let option of data.item.extraOptions;"  >
                  <ion-item *ngIf="option.quantity > 0" style="font-size: small;" color="secondary">
                    <ion-col><ion-label>{{option.name}}</ion-label></ion-col>
                    <ion-col><ion-label>({{currency}}{{option.value}} x {{option.quantity}})</ion-label></ion-col>
                    <ion-col><ion-label>{{currency}}{{option.totalcost}}</ion-label></ion-col>
                  </ion-item>    
                </div>
              </div>
              <ion-item *ngIf="data.item.specialinstr.length > 0" style="font-size: small;" color="secondary">
                <ion-col><ion-label>Special Instructions: <i>{{data.item.specialinstr}}</i></ion-label></ion-col>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>  
      </div>
    </ion-list>

    <ion-list style="background-color: var(--ion-color-secondary) !important;">
      <ion-item lines="inset" style="border-top: 1px solid black; margin-top: 10px;" color="secondary">
        <ion-col size="8">
          <ion-input style="text-transform:uppercase" placeholder="Promo Code" [(ngModel)]="promocode"></ion-input>
        </ion-col>
        <ion-col size="4" class="ion-text-right">
          <ion-button color="primary" fill="outline" (click)="validatePromoCode()"  [disabled]="promocode.length==0">
            <ion-spinner name="lines-small" *ngIf="validating"></ion-spinner>
            <ion-icon *ngIf="!promocodeneverchecked && !promocodevalid && !validating" name="close-circle"></ion-icon>
            <ion-icon *ngIf="!promocodeneverchecked && promocodevalid && !validating" name="checkmark-circle"></ion-icon> Validate
          </ion-button>
        </ion-col>
      </ion-item>
      <ion-item lines="inset" color="secondary">
        <ion-col size="8">Sub Total</ion-col>
        <ion-col size="4" class="ion-text-right">{{currency}}{{subTotal | number:'1.2-2'}}</ion-col>
      </ion-item>
      <ion-item lines="inset" color="secondary">
        <ion-col size="8">Discount</ion-col>
        <ion-col size="4" class="ion-text-right">- {{currency}}{{discamt | number:'1.2-2'}}</ion-col>
      </ion-item>
      <ion-item lines="inset" color="secondary">
        <ion-col size="8">Delivery Charge</ion-col>
        <ion-col size="4" class="ion-text-right">
          <ion-spinner name="lines-small" *ngIf="calculatingdelivery"></ion-spinner>
          <span *ngIf="!calculatingdelivery">{{currency}}{{deliverycharge | number:'1.2-2'}}</span>
        </ion-col>
      </ion-item>


      <ion-item lines="inset" color="secondary">
        <ion-col size="8">Tax ({{settings.totalGST}} % GST)</ion-col>
        <ion-col size="4" class="ion-text-right">{{currency}}{{taxamt | number:'1.2-2'}}</ion-col>
      </ion-item>

      <ion-item style="font-weight:bolder;" lines="inset" color="light" color="secondary">
        <ion-col size="8">Total</ion-col>
        <ion-col size="4" class="ion-text-right">{{currency}}{{GrandTotal | number:'1.2-2'}}</ion-col>
      </ion-item>
    </ion-list>
    <ion-row style="background-color: var(--ion-color-secondary);">
      <ion-col size="12">
        <ion-button color="primary" expand="block" class="continue-btn" (click)="nav()">{{"Checkout"| translate}}</ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
