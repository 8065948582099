<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar>
      <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>{{"Offers" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row class="full-slide"><!-- first row -->
    <ion-col size-sm="12" size-xs="12" size-lg="10" offset-lg="1" size-md="10" offset-md="1" size-xl="8" offset-xl="2">
      <div style="width: 100%; text-align: center; margin-top: 20px;">{{slideindx}} / {{noOfOffers()}}</div>
      <div style="position: relative; top: 30%;width: 100%;height: 100px;z-index: 1234;">
        <div  *ngIf="hasPrev()"
              class="leftarrow news-slider__prev" (click)="prevSlide()">
          <ion-icon name="chevron-back"></ion-icon>
        </div>
        <div  *ngIf="hasNext()"
              class="rightarrow news-slider__next" (click)="nextSlide()">
          <ion-icon name="chevron-forward"></ion-icon>
        </div>
      </div>

      <ion-slides style="top: -70px; width: 80%;"
                  [options]="slideOptions" #offerSlider>
        <ion-slide *ngFor="let data of offerData">
          <ion-grid>
            <ion-row>
              <ion-col size="12" style="padding: 0">
                <ion-img [src]="data.thumb" class="slider-img"></ion-img>
                <div class="offer-label">
                    <span class="square text-center light assertive-bg ng-binding">{{data.offer.offerPercentage}}%</span>
                    <i class="triangle"></i>
                  </div>
                  <div class="card-title">
                    <ion-icon name="arrow-dropleft" (click)="gotoPrevSlide()"></ion-icon>
                    &nbsp;&nbsp;&nbsp; {{data.title}} &nbsp;&nbsp;&nbsp;
                    <ion-icon name="arrow-dropright" (click)="gotoNextSlide()"></ion-icon>
                  </div>
              </ion-col>          
            </ion-row>        
            <ion-row>
              <ion-col size="12">
                  <p class="price"><b>{{"Original Price :" | translate}}</b> <span class="total line">{{data.price.value}}</span></p>
                  <p class="price"><b>{{"Offer Price :" | translate}} </b> <span class="total">{{data.price.specialPrice | number:0}}</span></p>
                  <p class="desc"><b>{{"Product Description :" | translate}} </b> {{data.description}}</p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="1"></ion-col>
              <ion-col size="8">
                <ion-button expand="block" fill="outline" (click)="addToCart(data.$key)">
                    <ion-icon name="cart-outline"></ion-icon>&nbsp;{{"Buy Now" | translate}}
                </ion-button>
              </ion-col>
              <ion-col size="1"></ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
      </ion-slides>
    </ion-col>
  </ion-row>
</ion-content>
