import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Configs } from '../../../app/config';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class UsersService {
    filePath = Configs[environment.name].Buyer.buerprofileimagesfolder + '/';
    dburl = Configs[environment.name].firebaseConfig.databaseURL;
    storageref = null;

    constructor(private db: AngularFireDatabase, public af: AngularFireAuth, public storage: AngularFireStorage) {
        const storageref = this.storage.ref(this.filePath);
    }

    HasRole(rolename: string, emailaddress: string): Promise<boolean> {
        console.log('Has Role called.');
        const hasRolePromise = new Promise<boolean>((resolve, reject) => {
            this.db.list('/users',  ref => ref.orderByChild('email').equalTo(emailaddress))
                .valueChanges()
                .subscribe((response: any[]) => {
                    if (response.length == 0) {
                        resolve(null);
                    } else if ((response.length === 1) && (response[0].role === rolename)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, (error) => {
                    reject(error);
                });
        });
        return hasRolePromise;
    }

    GetMenuItemsRated(userid): Promise<string[]> {
        const GetMenuItemsRatedPromise = new Promise<string[]>((resolve, reject) => {
            this.db.object('/users/' + userid + '/menuratings')
                .valueChanges()
                .subscribe((response: any) => {
                    console.log(response);
                    let menuitemsihaverated = [];
                    if (response != null) {
                        for (const key in response){
                            let menuitem  = response[key];
                            if (response.hasOwnProperty(key)) {
                                menuitemsihaverated.push(key);
                            }
                        }
                    }
                    resolve(menuitemsihaverated);
            }, (error) => {
                reject(error);
            });
        });
        return GetMenuItemsRatedPromise;
    }

    DeleteFirebaseOldImage(urltodelete) {
        console.log('File to delete: ' + urltodelete);
        this.storage.storage.refFromURL(urltodelete).delete();        
    }


    urltoFile(url, filename, mimeType){
        mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename, {type:mimeType});})
        );
    }

    UploadNewProfile(base64url, currentprofileurl, newfileid): Promise<any> {
        const UploadNewProfilePromise = new Promise<any>((resolve, reject) => {
            // https://firebasestorage.googleapis.com/v0/b/vegicious.appspot.com/o/menuitems%2F1583309393172_Aadiya%20pak.png?alt=media&token=12150432-15a0-42bb-a299-9c349145533b      
            const filename = new Date().getTime() + '_' + newfileid + '.png';

            this.urltoFile(base64url, filename, null).then((filestream) => {
                this.storage.upload(this.filePath + filename, filestream, {cacheControl: 'public, max-age=31536000'})
                .then((uploaded) => {
                    // file was uploaded. Now also delete the old image
                    uploaded.ref.getDownloadURL().then((downloadurl) => {
                        resolve(downloadurl);
                    }, (err) => {
                        reject(err);
                    });
                }, (err) => {
                    reject(err);
                });
            });
        });
        return UploadNewProfilePromise;    
    }



    // For identifying how many people are viewing a item.
    uniqueid = '';
    getUniqueId() {
        if (this.uniqueid === '') {
            let _fingerprint = localStorage.getItem('uniqueid') || '';
            if (_fingerprint == '') {
                console.log('Regenerated');
                _fingerprint = getBrowserFingerprint();
                localStorage.setItem('uniqueid', _fingerprint);    
            } else {
                console.log('From storage');
            }
            this.uniqueid = _fingerprint;
            console.log('UniqueId: ' + _fingerprint);
            return _fingerprint;
        } else {
            console.log('In mem Cached');
            console.log('UniqueId: ' + this.uniqueid);
            return this.uniqueid;
        }
    }

    itemsvisited = {
        itemids: []
    };
    logUser(itemid: string, sessionid: string) {
        console.log('Logging user');
        // Save it back to DB.
        var now = new Date;
        var utc_timestamp = Date.UTC(now.getFullYear(),now.getMonth(), now.getDate() ,  now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
        let presenceurl = '/presence/' + itemid + '/' + sessionid;
        let presenceref = this.db.object(presenceurl);
        presenceref.set({
            state: 'offline',
            last_changed: utc_timestamp
        });
        this.db.database.refFromURL(this.dburl  + presenceurl).onDisconnect().remove();
        const myObjObs = this.db.list(presenceurl); 
        if (this.itemsvisited[itemid] == null) {
            this.itemsvisited[itemid] = true;
            this.itemsvisited.itemids.push(itemid);
        };
        console.log(sessionid + ' user viewing item ' + itemid);
    }

    numUsersViewingItem(itemid: string): AngularFireList<unknown> {
        let presenceurl = '/presence/' + itemid;
        return this.db.list(presenceurl);
    }
}
