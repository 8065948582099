import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { tap, take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";


@Injectable()
export class FcmWebService {
    token;
    currentMessage = new BehaviorSubject(null);

    constructor(private platform: Platform, 
                private firebase: FirebaseX, 
                private angularFireAuth: AngularFireAuth,
                private angularFireDB: AngularFireDatabase, 
                private afMessaging: AngularFireMessaging) {
    }
    
    /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
    updateToken(userId, token) {
        // we can change this function to request our backend service
        this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                this.angularFireDB.object('users/' + userId + '/notificationTokens/' + token).set(true);
            });
    }

    requestPermission(userId) {
        this.afMessaging.requestToken.subscribe(
            (token) => {
                console.log(token);
                this.token = token;
                this.updateToken(userId, token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', JSON.stringify(err));
            }
        );
    } 

    async getToken(userid: string) {
        console.log('Calling web getToken');
        this.requestPermission(userid);
    }

    onNotification() {
        return this.afMessaging.messages;
    }
} 