<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title class="title">{{"Page not found" | translate}}
        </ion-title>     
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid class="grid">
      <ion-row>
          <h3>Sorry... You reached a non existant page.</h3>
          <a [routerLink]="['/product-list/all']" routerLinkActive="active">Click to go back to Home</a>
      </ion-row>
  </ion-grid>
</ion-content>
