<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="secondary">
      <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
          <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title class="title">{{menuItems.title}}
      </ion-title>
      <ion-buttons slot="end">
          <ion-button class="cart-icon-nav" (click)="navcart()">
            <ion-icon name="cart-outline"></ion-icon> 
            <ion-badge class="carts">{{noOfItems}}</ion-badge>
          </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed style="background-color: var(--ion-color-secondary);">
    <ion-card style="margin: 0; padding: 0;" color="secondary" fullscreen>
      <ion-card-header style="padding-top: 4px;" fullscreen>
        <ion-row>
          <ion-col size="9">
            <!--<ion-card-subtitle color="medium" class="cursor-pointer addtopmargin">{{menuItems.seller.storename}}</ion-card-subtitle>-->
            <ion-chip outline="true" color="dark" class="cursor-pointer addtopmargin" (click)="showSellerInfo($event, menuItems)">
              <ion-avatar>
                  <img [src]="menuItems.seller.thumb" />
              </ion-avatar>
              <ion-label>By {{menuItems.seller.storename}}</ion-label>
            </ion-chip>
          </ion-col>
          <ion-col size="3">
            <ion-chip color="dark" class="item-price" *ngIf="menuItems.price.specialPrice != null &&  menuItems.price.specialPrice > 0" style="font-size: 10px; float: right;">
              <ion-label>{{currency}}</ion-label>
              <ion-label style="text-decoration:line-through; margin-right: 4px; margin-left: 4px;">{{menuItems.price.value}}</ion-label>
              <ion-label>{{menuItems.price.specialPrice}}</ion-label>
            </ion-chip>                     
            <ion-chip color="dark" class="item-price" *ngIf="!(menuItems.price.specialPrice != null &&  menuItems.price.specialPrice > 0)" style="font-size: 12px; float: right;">
                <ion-label>{{currency}} {{menuItems.price.value}}</ion-label>
            </ion-chip>   
          </ion-col>        
        </ion-row>
        <br>
        <ion-row>
          <ion-card-subtitle color="dark">{{menuItems.description}}</ion-card-subtitle>
        </ion-row>
        <ion-row>
          <ion-card-subtitle color="dark" class="addtopmargin makefontitalic" *ngIf="menuItems.tagsstr.length > 0">{{menuItems.tagsstr}}</ion-card-subtitle>
        </ion-row>
        <ion-row>
          <ion-card-subtitle color="dark" class="addtopmargin makefontitalic" *ngIf="(menuItems.price.pname !== null && menuItems.price.pname.length > 0)">Portion size: {{menuItems.price.pname}}</ion-card-subtitle>
        </ion-row>
        <ion-row>
          <ion-card-subtitle color="dark" class="addtopmargin makefontitalic">Order before: {{menuItems.preptimehrs}} Hours</ion-card-subtitle>
        </ion-row>
        <ion-row *ngIf="menuItems.locationsserved != null && menuItems.locationsserved.length > 0">
          <ion-card-subtitle color="dark" class="addtopmargin makefontitalic">Locations Served: {{menuItems.locationsserved}}</ion-card-subtitle>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <div *ngIf="menuItems.thumb != null">
              <img [src]="menuItems.thumb" class="menuthumbnail">
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <div *ngIf="noofpeopleviewingthisitem > 0">
            <span>
              {{noofpeopleviewingthisitem}} people viewing this item currently.
            </span>
          </div>
          <div *ngIf="noofpeopleviewingthisitem == 0">
            <span>
              &nbsp;
            </span>
          </div>
        </ion-row>
      </ion-card-header>
    
      <ion-card-content style="margin: 0 !important; padding: 0 !important;" fullscreen>
        <!-- Size and Prices -->
        <ion-list inset="true" *ngIf="menuItems.additionalprice != null && menuItems.additionalprice.length > 0" style="background-color: var(--ion-color-secondary) !important;">
            <ion-radio-group allowEmptySelection="true" (ionChange)="sizeOptionsDeselect($event)">
              <ion-list-header color="primary">{{"Size and prices" | translate}}</ion-list-header>
        
              <ion-item *ngFor="let item of menuItems.additionalprice; let i= index" color="secondary">
                <ion-label *ngIf="item.specialPrice"> 
                  <span>{{item.pname}}&nbsp;&nbsp;(</span>
                  <span>{{currency}}&nbsp;</span> 
                  <span style="text-decoration-line: line-through;">{{item.value}}&nbsp;</span> 
                  <span>{{item.specialPrice |number:0}})</span>
                </ion-label>
                <ion-label *ngIf="!item.specialPrice">{{item.pname}} ({{currency}}{{item.value}})</ion-label>
                <ion-radio (ionSelect)="sizeOptions(item)"></ion-radio>
              </ion-item>
            </ion-radio-group>
        </ion-list>

        <ion-item>
          <ion-label style="text-overflow: none; white-space: normal;">
            <h4>Quantity (Min: {{menuItems.quantityrange.min}}, Max: {{menuItems.quantityrange.max}})</h4>
          </ion-label>
          <ion-label>
            <ion-button fill="clear"  (click)="removeQuantity()">
              <ion-icon size="large" name="remove-circle"></ion-icon>
            </ion-button>
            <ion-button fill="clear" disabled style="font-size: 14px;">{{count}}</ion-button>
            <ion-button fill="clear" (click)="addQuantity()">
              <ion-icon size="large" name="add-circle"></ion-icon>
            </ion-button>                    
          </ion-label>
        </ion-item>
        
        <ion-list *ngIf="menuItems.extraOptions != null && menuItems.extraOptions.length > 0" inset="false" lines="full">
          <div style="padding: 10px;">{{"Add-on Items" | translate}}</div>
          <ion-item *ngFor="let option of menuItems.extraOptions; let i= index" color="secondary">
            <ion-label>{{option.name}} ({{currency}} {{option.value}})</ion-label>
            <ion-label>
              <ion-button fill="clear"  (click)="removeExtraQuantity(option, i)">
                <ion-icon name="remove-circle"></ion-icon>
              </ion-button>
              <ion-button fill="clear" disabled>{{option.quantity}}</ion-button>
              <ion-button fill="clear" (click)="addExtraQuantity(option, i)">
                <ion-icon name="add-circle"></ion-icon>
              </ion-button>                    
            </ion-label>
            <!--<ion-toggle (ionChange)="checkOptions(option)"></ion-toggle>-->
            <ion-label style="max-width: 50px;">{{currency}}{{option.totalcost}}</ion-label>
          </ion-item>          
        </ion-list>

        <ion-item color="primary">
          <ion-label>
              <h4>Total</h4>
          </ion-label>
          <ion-label slot="end" style="padding-left: 40px;">
            <h4>{{currency}} {{getTotal()}}</h4>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-textarea rows="3" cols="20" placeholder="Special Instructions..."  [(ngModel)]="cart.specialinstr"></ion-textarea>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-grid>  
</ion-content>
<ion-footer>
  <ion-toolbar color="secondary">
    <ion-row>
        <ion-col size="6">
            <ion-button expand="block" icon-left color="primary" (click)="addToCart()">
                <ion-icon name="cart-outline"></ion-icon>
                {{"ADD TO CART" | translate}}
            </ion-button>
        </ion-col>
        <ion-col size="6">
            <ion-button expand="block" icon-left color="primary" (click)="home()">
              <ion-icon name="restaurant-outline"></ion-icon>
              {{"KEEP SHOPPING" | translate}}
            </ion-button>
        </ion-col>
    </ion-row>
  </ion-toolbar>      
</ion-footer>