import {NgModule} from '@angular/core';
import { RatingComponent } from './rating/rating.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';






@NgModule({
  declarations: [
    RatingComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule
  ],
  exports: [
    RatingComponent
  ]
})
export class DirectivesModule {  }
export const DirectivesComponents = [
  RatingComponent
];