import {Component} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import { Configs } from '../../config';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { AuthService } from '../../services/auth-service/authservice';


@Component({
  selector: 'page-orders',
  templateUrl: 'orders.html',
  styleUrls: ['orders.scss']
})
export class OrdersPage {
  subordersDetails: any[] = [];
  currency: string = Configs[environment.name].Currency.symbol;
  
  Cart: any = [];
  noOfItems: any;

  constructor(public af: AngularFireAuth,
              public db: AngularFireDatabase,
              public authService: AuthService,
              public router: Router,
              public loadingCtrl: LoadingController) {

    this.Cart = JSON.parse(localStorage.getItem('Cart'));
    if (localStorage.getItem('Cart') != null) {
      this.noOfItems = this.Cart.length;
    }
            
    const user = this.authService.user;
    console.log(user);
    if (this.af.currentUser) {
      this.getData();
    }
  }

  async getData() {
    const loader = await this.loadingCtrl.create({
      message: 'Please wait...',
      cssClass: 'loadingmsg'
    });
    loader.present().then(() => {
      this.af.currentUser.then((cuser) => {
        const userID = cuser.uid;
        this.db.list('/suborders', ref => ref.orderByChild('buyerId').equalTo(userID)).valueChanges().subscribe(response => {
          let unsortedsuborders: any[] = response;
          console.log(this.subordersDetails);
          unsortedsuborders.forEach(suborder => {
            suborder.sellerDetails = {
              storeName: '',
              profileimage: ''
            };
            this.db.list('/business', ref => ref.orderByChild('sellerId').equalTo(suborder.sellerId)).valueChanges().subscribe(sellerresponse => {
              if (sellerresponse[0] !== null) {
                suborder.sellerDetails = sellerresponse[0];
              }
            });
          });
          this.subordersDetails = unsortedsuborders.sort((n1, n2) => n2.createdAt - n1.createdAt);
          loader.dismiss();
        }, (error) => {
          console.error(error);
          loader.dismiss();
        });
      });
    });
  }


  orderDetails(key, index, mainorderid) {
    // console.log(index+"----index");
    this.router.navigate(['/order-details', {
      orderId: key,
      mainorderid: mainorderid,
      index: index
    }]);
  }

  hasOrders(): boolean {
    return (this.subordersDetails.length > 0);
  }

  navcart() {
    this.router.navigate(['/cart']);
  }
}
