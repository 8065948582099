import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/compat/database';
import { Configs } from '../../../app/config';
import { environment } from '../../../environments/environment';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';



@Component({
  selector: 'page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.scss']
})
export class HomePage implements OnInit {
  public Cart: any = [];
  public noOfItems: any;
  public searchrange: number = Configs[environment.name].SearchRange.distance;
  public searchrangeunit: string = Configs[environment.name].SearchRange.unit;

  public ComingData: any[] = [];
  public Categories: any[] = [];
  public comingData: AngularFireList<any>;
  public categories: AngularFireList<any>;
  public locationbased: boolean = Configs[environment.name].locationbased;
  constructor(  public router: Router,
                public af: AngularFireDatabase, 
                public loadingCtrl: LoadingController) { }
                
  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.Cart = JSON.parse(localStorage.getItem('Cart'));
    const loader = await this.loadingCtrl.create({
      message: 'Please wait...',
      cssClass: 'loadingmsg'
    });
    loader.present().then(() => {
      if (localStorage.getItem('Cart') != null) {
        this.noOfItems = this.Cart.length;
      }
      this.af.list('/coming').snapshotChanges().subscribe((data) => {
        this.ComingData = data;
      });


      this.af.list('/categories').snapshotChanges().subscribe((data) => {
        loader.dismiss();
        const _data = [];
        data.forEach((element: any) => {
          const e = element.payload.toJSON();
          e.$key = element.key;
          _data.push(e);
        });
        this.Categories = _data;
      });
    }); 
  }

  public navigate(id) {
    this.router.navigate(['/product-list', id]);
  }

  public navcart() {
    this.router.navigate(['/cart']);
  }
}
