import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastController, ModalController } from '@ionic/angular';
import { DbService } from './../../services/db-service/dbservice';

@Component({
  selector: 'page-rate-item',
  templateUrl: 'rate-item.html',
  styleUrls: ['rate-item.scss']
})
export class RateItemPage implements OnInit{
  @Input() public itemstorate: any;
  currentitemindex = 0;
  sellerindex = 0;
  indexer: number[] = [];
  ratingforall = 0;
  currentrating = 0;
  maxitems = 0;
 
  constructor(public router: Router,
    public route: ActivatedRoute,
    public af: AngularFireAuth,
    public db: AngularFireDatabase,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public dbService: DbService) {
  }

  ngOnInit(): void {
    console.log('Got the following orders to rate...');
    console.log(this.itemstorate);
    this.initializeIndexerArray();
    console.log(this.indexer);
  }

  initializeIndexerArray() {
    this.itemstorate.forEach(element => {
      let itemslen = element.items.length;
      for(var i = 0; i < itemslen; i++)
      {
        element.items[i].currentrating = 0;
        this.indexer.push(i);
      }
    });
    this.maxitems = this.indexer.length;
    console.log('Max len:' + this.maxitems);
  }
  
  dismissModal() {
    console.log('Dismissed');
    let ensureuniquemenuitems = {};

    this.itemstorate.forEach(element => {
      let itemslen = element.items.length;
      for(var i = 0; i < itemslen; i++)
      {
        let menuitemid = element.items[i].itemId;
        let newrating = element.items[i].currentrating;
        if (ensureuniquemenuitems[menuitemid] == null) {
          ensureuniquemenuitems[menuitemid] = {
            menuitemid: menuitemid,
            rating: newrating
          };
          this.dbService.updateRatingforMenu(menuitemid, newrating);
        }        
      }
    });

    this.dbService.updateItemsIhaveRated(ensureuniquemenuitems);

    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async createToaster(message, duration) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration
    });
    toast.present();
  }

  ApplyAllSameRating() {
    this.itemstorate.forEach(element => {
      let itemslen = element.items.length;
      for(var i = 0; i < itemslen; i++)
      {
        element.items[i].currentrating = this.ratingforall;
      }
    });
    console.log(this.itemstorate);
  }

  nextitem() {
    if (this.indexer[this.currentitemindex+1] <= this.indexer[this.currentitemindex]) {
      this.sellerindex++;
    }
    this.currentitemindex++;
    console.log('New Rating: ' + this.currentrating);
  }
}
