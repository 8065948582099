import { GeoService } from '../services/geofire/geo.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersService } from './users/userservice';
import { AuthService } from './auth-service/authservice';
import { AuthGuard } from './auth-service/authguard';
import { FcmWebService } from './fcm/fcmweb.service';
import { FcmNativeService } from './fcm/fcmnative.service';
import { CartService } from './cart-service/cart.service';
import { ThemeService } from './theme-service/theme.service';
import { DbService } from './db-service/dbservice';

@NgModule({
    imports: [        
    ],
    providers: [
        GeoService,
        UsersService,
        FcmWebService,
        FcmNativeService,
        CartService,
        AuthService,
        DbService,
        AuthGuard,
        ThemeService
    ],
    declarations: [
    ],
    exports: [
    ]
})

export class ServicesModule {
    static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule
        };
    }
}
