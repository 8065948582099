import { Component, OnInit } from '@angular/core';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Configs } from '../../../../app/config';
import { environment } from '../../../../environments/environment';
import { UsersService } from '../../../services/users/userservice';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FcmWebService } from '../../../services/fcm/fcmweb.service';

@Component({
  selector: 'page-login',
  templateUrl: 'login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  tagHide = true;
  valForm: FormGroup;
  isIOS = false;
  isBrowser = false;
  toastduration = Configs[environment.name].toastduration;
  showPasswordText = false;
  defaultlandingpage = './product-list/all'; // ./home
  loginlandingpage = this.defaultlandingpage;
  redirecttopage:string = null;

  constructor(public navCtrl: Router,
    public af: AngularFireAuth,
    public fb: FormBuilder,
    public db: AngularFireDatabase,
    public userssvc: UsersService,   
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private signInWithApple: SignInWithApple,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    private route: ActivatedRoute,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    private fcm: FcmWebService) { 
      this.isIOS = this.platform.is('ios');
      if (window["cordova"] != null) {
        this.isBrowser = (window["cordova"].platformId || '') === 'browser';
      } else {
        this.isBrowser = true;
      }
      
      console.log('IOS: ' + this.isIOS);
      this.valForm = this.fb.group({
        'email': ['', [Validators.required, Validators.email]],
        'password': ['', Validators.required]
      });
    }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.redirecttopage = params['id'] || null;
      if ((this.redirecttopage != null) && (this.redirecttopage.length > 0)) {
        this.loginlandingpage = this.redirecttopage;
      }
    });
  }

  toggleRegister() {
    this.tagHide = this.tagHide ? false : true;
  }

  async createToaster(message, duration) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration
    });
    toast.present();
  }

  OnLogin($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.userssvc.HasRole('User', value.email).then((success) => {
        if (success) {
          this.af.signInWithEmailAndPassword(value.email, value.password).then((_user) => {
            localStorage.setItem('uid', _user.user.uid);
            if (_user.user.emailVerified) {
              this.navCtrl.navigate([this.loginlandingpage]);
            } else  {
              this.navCtrl.navigate(['./verifyemail']);
            }              
          })
          .catch((error) => {
            console.log('Firebase failure: ' + JSON.stringify(error));
            this.createToaster('Firebase failure: ' + error.message, this.toastduration);
          });
        } else {
          this.createToaster('Login Failure: User not a buyer.', this.toastduration);       }
      }, (error) => {
        this.createToaster('Login Failure: User not a buyer.', this.toastduration);
      });
    }
  }

  /*
    "response": {
    "email": "foo@bar.com",
    "identityToken": "importantToken",
    "familyName": "Grimm",
    "user": "AppleUserId",
    "givenName": "Simon",
    "authorizationCode": "authCode"
  }
  */

 async appleNonIosLoginAsPopup() {
  console.log('Performing non ios apple login.');
  const loading = await this.loadingCtrl.create({
    message: 'Login Please Wait...',
    cssClass: 'loadingmsg'
  });
  try {
    loading.present();

    var provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('name email');
    this.af.signInWithPopup(provider).then((result) => {
      console.log(result);
      // The signed-in user info.
      // alert('Apple token: ' + result);
      var user = result.user;
      // You can also get the Apple OAuth Access and ID Tokens.
      // var accessToken = result.credential.accessToken;
      // var idToken = result.credential.idToken;

      // check if the user has role.
      console.log('Calling hasrole.');
      this.userssvc.HasRole('User', result.user.email).then(async (hasrolesuccess) => {
        if (hasrolesuccess) {
          console.log('Login successful');              
          localStorage.setItem('uid', result.user.uid);
          loading.dismiss();
          this.navCtrl.navigate([this.loginlandingpage]);
        } else {
          loading.dismiss();
          const alert = await this.alertCtrl.create({
            header: 'Vegecious Sign up',
            message: 'Creating your user profile',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  console.log('Confirm Cancel: blah');
                  this.createToaster('Login Failure: Account does not exist.', this.toastduration);
                  this.af.signOut();
                }
              }, {
                text: 'Ok',
                handler: () => {
                    console.log('Firebase -fb success: ' + JSON.stringify(result));
                    this.db.object('/users/' + result.user.uid).update({
                      name: result.user.displayName,
                      email: result.user.email,
                      profilepictureurl: result.user.photoURL || 'assets/img/profile.jpg',
                      emailVerified: true,
                      role: 'User'
                    });
                    localStorage.setItem('uid', result.user.uid);
                    window.setTimeout(() => {
                      // facebook login creates unverified email address. we added cloud function to update the email verification. This should not cause the email verified page to be shown.
                      this.navCtrl.navigate(['./settings']);
                    }, 1000);
                }
              }
            ]
          });          
          await alert.present();              
        
        }
      });
    }, (error) => {
      loading.dismiss();
      this.createToaster('Login Failure: Error.' + error, this.toastduration);
    });          
  }
  catch{error => {
      console.log(error.code + ' ' + error.localizedDescription);
      console.error(error);
      loading.dismiss();
      this.createToaster('Login Failure: Error.' + error, this.toastduration);
    }
  };
}

async appleNonIosLoginAsRedirect() {
    console.log('Performing non ios apple login.');
    const loading = await this.loadingCtrl.create({
      message: 'Login Please Wait...',
      cssClass: 'loadingmsg'
    });
    try {
      loading.present();

      var provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      this.af.signInWithRedirect(provider).then((result: any) => {
        if (result.credential){
          // You can get the Apple OAuth Access and ID Tokens.
          var accessToken = result.credential.accessToken;
          var idToken = result.credential.idToken;
           // The signed-in user info.
          // alert('Apple token: ' + result);
          var user = result.user;
          // You can also get the Apple OAuth Access and ID Tokens.
          // var accessToken = result.credential.accessToken;
          // var idToken = result.credential.idToken;
          console.log(result);
          // check if the user has role.
          console.log('Calling hasrole.');
          this.userssvc.HasRole('User', result.user.email).then(async (hasrolesuccess) => {
            if (hasrolesuccess) {
              console.log('Login successful');              
              localStorage.setItem('uid', result.user.uid);
              loading.dismiss();
              this.navCtrl.navigate([this.loginlandingpage]);
            } else {
              const alert = await this.alertCtrl.create({
                header: 'Vegecious Sign up',
                message: 'Creating your user profile',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                      console.log('Confirm Cancel: blah');
                      this.createToaster('Login Failure: Account does not exist.', this.toastduration);
                    }
                  }, {
                    text: 'Ok',
                    handler: () => {
                        console.log('Firebase -fb success: ' + JSON.stringify(result));
                        this.db.object('/users/' + result.user.uid).update({
                          name: result.user.displayName,
                          email: result.user.email,
                          profilepictureurl: result.user.photoURL || 'assets/img/profile.jpg',
                          emailVerified: true,
                          role: 'User'
                        });
                        localStorage.setItem('uid', result.user.uid);
                        window.setTimeout(() => {
                          // facebook login creates unverified email address. we added cloud function to update the email verification. This should not cause the email verified page to be shown.
                          this.navCtrl.navigate(['./product-list/all']);
                        }, 1000);
                    }
                  }
                ]
              });          
              await alert.present();              
            
            }
          });
        } else {
          console.log('no credentials retrieved...');
          loading.dismiss();
          this.createToaster('no credentials retrieved....', this.toastduration);
        }
      }, (error) => {
        loading.dismiss();
        this.createToaster('Login Failure: Error.' + error, this.toastduration);
      });          
    }
    catch{error => {
        console.log(error.code + ' ' + error.localizedDescription);
        console.error(error);
        loading.dismiss();
        this.createToaster('Login Failure: Error.' + error, this.toastduration);
      }
    };
  }
  
  async appleIosLogin() {
    console.log('Performing ios native apple login.');
    try {
      const loading = await this.loadingCtrl.create({
        message: 'Login Please Wait...',
        cssClass: 'loadingmsg'
      });
      loading.present();

      this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      })
      .then((appleCredential: AppleSignInResponse) => {
        this.userssvc.HasRole('User', appleCredential.email).then(async (hasrolesuccess) => {
          console.log('step 0');
          if (hasrolesuccess) {
            console.log(appleCredential);
            const credential =  new firebase.auth.OAuthProvider('apple.com').credential(appleCredential.identityToken);
            this.af.signInWithCredential(credential)
              .then((response) => {
                console.log('Login successful');                  
                localStorage.setItem('uid', response.user.uid);
                loading.dismiss();
                this.navCtrl.navigate([this.loginlandingpage]);
              })
              .catch((error) => {
                console.log(error);
                loading.dismiss();
                this.createToaster('Login Failure: Error.' + error, this.toastduration);
              });
          } else {
            loading.dismiss();
            console.log('step 1');
            const alert = await this.alertCtrl.create({
              header: 'Vegecious Sign up',
              message: 'Creating your user profile',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: (blah) => {
                    console.log('Confirm Cancel: blah');
                    this.createToaster('Login Failure: Account does not exist.', this.toastduration);
                    this.af.signOut();
                  }
                }, {
                  text: 'Ok',
                  handler: () => {
                    console.log('step 2');
                    const credential =  new firebase.auth.OAuthProvider('apple.com').credential(appleCredential.identityToken);
                    this.af.signInWithCredential(credential)
                      .then((result) => {
                        console.log('step 3');
                        console.log('Login successful');                  
                        console.log('Firebase -fb success: ' + JSON.stringify(result));
                        this.db.object('/users/' + result.user.uid).update({
                          name: result.user.displayName,
                          email: result.user.email,
                          profilepictureurl: result.user.photoURL || 'assets/img/profile.jpg',
                          emailVerified: true,
                          role: 'User'
                        });
                        localStorage.setItem('uid', result.user.uid);
                        window.setTimeout(() => {
                          // facebook login creates unverified email address. we added cloud function to update the email verification. This should not cause the email verified page to be shown.
                          this.navCtrl.navigate(['./settings']);
                          localStorage.setItem('uid', result.user.uid);
                        }, 1000);
                      })
                      .catch((error) => {
                        console.log(error);
                        loading.dismiss();
                        this.createToaster('Login Failure: Error.' + error, this.toastduration);
                      });
                  }
                }
              ]
            });
          }
        });
      })
      .catch((error: AppleSignInErrorResponse) => {
        console.log(error);
        loading.dismiss();
        if (error === 'Missing Command Error') {
          this.appleNonIosLoginAsPopup();
        } else {
          this.createToaster('Login Failure: Error.' + error, this.toastduration);
        }        
      });  
    }
    catch{error => {
        console.log(error.code + ' ' + error.localizedDescription);
        console.error(error);
      }
    }
  }
  
  async appleLogin() {
    if (this.isIOS) {
      this.appleIosLogin();
    } else {
      //this.appleNonIosLoginAsPopup();
      this.appleNonIosLoginAsRedirect();
    }
  }

  doFbLogin() {
    let permissions = new Array();
    permissions = ['email'];

    this.facebook.login(permissions)
      .then((success) => {
        console.log('facebook Success response->' + JSON.stringify(success));
        // tslint:disable-next-line:ban-comma-operator
        this.facebook.api('/me?fields=id,name,email,gender,first_name,last_name', permissions).then((user) => {
          console.log('user details' + JSON.stringify(user));
          console.log('access token-' + JSON.stringify(success.authResponse.accessToken));
          this.userssvc.HasRole('User', user.email).then(async (hasrolesuccess) => {
            if (hasrolesuccess) {
              const provider = firebase.auth.FacebookAuthProvider.credential(success.authResponse.accessToken);

              this.af.signInWithCredential(provider)
                .then((response) => {
                  console.log('Firebase -fb success: ' + JSON.stringify(response));                  
                  localStorage.setItem('uid', response.user.uid);
                  this.navCtrl.navigate([this.loginlandingpage]);
                })
                .catch((error) => {
                  console.log('Firebase failure:--- ' + JSON.stringify(error));
                  // this.createToaster('Login Failure: ' + error.message, this.toastduration);
                  if (error.code === 'auth/account-exists-with-different-credential') {
                    this.linkwithanotherprovider(error);
                  }
                });
            } else {
              const alert = await this.alertCtrl.create({
                header: 'Vegecious Sign up',
                message: 'Creating your user profile',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                      console.log('Confirm Cancel: blah');
                      this.createToaster('Login Failure: Account does not exist.', this.toastduration);
                    }
                  }, {
                    text: 'Ok',
                    handler: () => {
                      const provider = firebase.auth.FacebookAuthProvider.credential(success.authResponse.accessToken);

                      this.af.signInWithCredential(provider)
                        .then((response) => {
                          console.log('Firebase -fb success: ' + JSON.stringify(response));
                          this.db.object('/users/' + response.user.uid).update({
                            name: response.user.displayName,
                            email: response.user.email,
                            profilepictureurl: response.user.photoURL || 'assets/img/profile.jpg',
                            emailVerified: true,
                            role: 'User'
                          });
                          localStorage.setItem('uid', response.user.uid);
                          window.setTimeout(() => {
                            // facebook login creates unverified email address. we added cloud function to update the email verification. This should not cause the email verified page to be shown.
                            this.navCtrl.navigate(['./product-list/all']);
                          }, 1000);
                        })
                        .catch((error) => {
                          let errmsg = JSON.stringify(error);
                          console.log('Firebase failure:--- ' + errmsg);
                          this.createToaster('Firebase failure: ' + errmsg, this.toastduration);
                        });
                    }
                  }
                ]
              });          
              await alert.present();              
            
            }

          }, (hasroleerror) => {
            this.createToaster('Login Failure: User not a buyer.', this.toastduration);
          });
        }),
          (error) => {
            var errormsg = JSON.stringify(error);
            console.log(errormsg);
            this.createToaster('Login Failure: User not a buyer.' + errormsg, this.toastduration);
          };

      }, (error) => {
        var errormsg = JSON.stringify(error);
            console.log(errormsg);
            this.createToaster('Login Failure: User not a buyer.' + errormsg, this.toastduration);
      });
  }

  async promptUserForPassword(emailaddress: string): Promise<string> {
    let promptUserForPasswordPromise = new Promise<string>(async (resolve, reject) => {
      const prompt = await this.alertCtrl.create({
        header: 'Login with Email',
        message: "Seems you have an account with email login. Please enter the password to login.",
        inputs: [
          {
            name: 'title',
            placeholder: 'Title',
            value: emailaddress
          },{
            name: 'password',
            placeholder: 'Password',
            type: 'password',
            id: 'emailpassword'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Save',
            handler: data => {
              resolve(data.password);
            }
          }
        ]
      });
      await prompt.present();
    });
    return promptUserForPasswordPromise;
  }

  async promptWithProviders(emailaddress: string, methods: []){
    var _buttons = [];
    methods.forEach((_method: string) => {
      switch(_method) {
        case 'google.com':
          _buttons.push({
        
            text: 'Sign in with Google',
            handler: data => {
              console.log('Try google');
            }
          })
          break;

        case 'facebook.com':
          _buttons.push({
        
            text: 'Sign in with Facebook',
            handler: data => {
              console.log('Try facebook');
            }
          })
          break;

          case 'apple.com':
            _buttons.push({
          
              text: 'Sign in with Apple',
              handler: data => {
                console.log('Try apple');
              }
            })
            break;

      } 
      
    });
    let prompt = await this.alertCtrl.create({
      header: 'Multi ID Login',
      message: "You already have account created using the following social logins. Click any of them to link this account.",
      buttons: _buttons
    });
    await prompt.present();
  }

  async presentAlertConfirm(_buttons: any[]) {
    const alert = await this.alertCtrl.create({
      header: 'Link your social login accounts',
      message: "Wait! You logged in with this provider first time. Seems you have an account with following social logins. Please login with one of these. The current login will be linked to your vegecious account.",
      buttons: _buttons
    });

    await alert.present();
  }

  linkwithanotherprovider(error: any) {
      var pendingCred = error.credential;
      var email = error.email;
      this.af.fetchSignInMethodsForEmail(email).then((methods) => {
        if (methods[0] === 'password') {
          this.promptUserForPassword(email).then((password) => {
            this.af.signInWithEmailAndPassword(email, password).then((user: firebase.auth.UserCredential) => {
              user.user.linkWithCredential(pendingCred);
              this.createToaster('Login successfull linked for email ' + email, this.toastduration);
              this.navCtrl.navigate([this.loginlandingpage]);
            });
          }); // TODO: implement promptUserForPassword.
        } else {
          var _buttons = [];
          methods.forEach((_method: string) => {
            switch(_method) {
              case 'google.com':
                _buttons.push({
                  text: 'Google Login',
                  handler: data => {
                    var webclientid = Configs[environment.name].googleConfig.clientid;
                    this.googlePlus.login({
                      'scopes': 'profile email',
                      'webClientId': webclientid,
                      'offline': false
                    })
                    .then(async (success) => {
                      const provider = firebase.auth.GoogleAuthProvider.credential(success.idToken);
                      this.af.signInWithCredential(provider)
                      .then((response) => {                
                        localStorage.setItem('uid', response.user.uid);
                        response.user.linkWithCredential(pendingCred).then((usercred) => {
                          this.createToaster('Google login successfull linked for email ' + email, this.toastduration);
                          this.navCtrl.navigate([this.loginlandingpage]);
                        });
                      })
                      .catch((error) => {
                        console.log('Firebase failure:--- ' + JSON.stringify(error));
                        this.createToaster('Login Failure: ' + error.message, this.toastduration);
                      });
                    });
                 }
                });
                break;
      
              case 'facebook.com':
                _buttons.push({
                  text: 'Facebook Login',
                  handler: data => {
                    console.log('Try facebook');
                  }
                })
                break;
      
                case 'apple.com':
                  _buttons.push({
                    text: 'Apple Login',
                    handler: data => {
                      console.log('Try apple');
                    }
                  })
                  break;
      
            }  
          });
          // await this.alertCtrl.create({
          //   header: 'Social Account Login',
          //  message: "Wait! You logged in with this provider first time. Seems you have an account with following social logins. Please login with those. The current login will be linked to your vegecious account.",
          //  buttons: _buttons
          // });
          this.presentAlertConfirm(_buttons);
          //prompt.present();
          // var provider = getProviderForProviderId(methods[0]);
          // At this point, you should let the user know that they already has an account
          // but with a different provider, and let them validate the fact they want to
          // sign in with this provider.
          // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
          // so in real scenario you should ask the user to click on a "continue" button
          // that will trigger the signInWithPopup.
          // auth.signInWithPopup(provider).then(function(result) {
            // Remember that the user may have signed in with an account that has a different email
            // address than the first one. This can happen as Firebase doesn't control the provider's
            // sign in flow and the user is free to login using whichever account they own.
            // Step 4b.
            // Link to Google credential.
            // As we have access to the pending credential, we can directly call the link method.
            // result.user.linkAndRetrieveDataWithCredential(pendingCred).then(function(usercred) {
              // Google account successfully linked to the existing Firebase user.
              // goToApp();
            // });
          // });
        }
      });
  }

  checkProducts() {
    this.navCtrl.navigate([this.loginlandingpage]);
  }

  async googleLogin() {
    try {
      var webclientid = Configs[environment.name].googleConfig.clientid;
      console.log(webclientid);
      
      const loading = await this.loadingCtrl.create({
        message: 'Login Please Wait...',
        cssClass: 'loadingmsg'
      });
      loading.present();

      this.googlePlus.login({
        'scopes': 'profile email',
        'webClientId': webclientid,
        'offline': false
        //'ux_mode': 'redirect',
        //'redirect_uri': 'https://app-dev.vegecious.com/product-list/all'
      })
      .then(async (success) => {
        this.userssvc.HasRole('User', success.email).then(async (hasrolesuccess) => {
            const profileimage = success.imageUrl;
            if (hasrolesuccess) {
              // console.log("you have been successfully logged in by googlePlus!" + JSON.stringify(success));
              const provider = firebase.auth.GoogleAuthProvider.credential(success.idToken);
              this.af.signInWithCredential(provider)
              .then((response) => {                
                localStorage.setItem('uid', response.user.uid);
                loading.dismiss();
                this.navCtrl.navigate([this.loginlandingpage]);
              })
              .catch((error) => {
                loading.dismiss();
                console.log('Firebase failure:--- ' + JSON.stringify(error));
                this.createToaster('Login Failure: ' + error.message, this.toastduration);
              });
            } else {
              loading.dismiss();
              const alert = await this.alertCtrl.create({
                header: 'Vegecious Sign Up',
                message: 'Creating your user profile.',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                      console.log('Confirm Cancel: blah');
                      this.createToaster('Login Failure: Account does not exist.', this.toastduration);
                    }
                  }, {
                    text: 'OK',
                    handler: () => {
                      const provider = firebase.auth.GoogleAuthProvider.credential(success.idToken);
                      this.af.signInWithCredential(provider)
                        .then((response) => {                
                          this.db.object('/users/' + response.user.uid).update({
                            name: response.user.displayName,
                            email: response.user.email,
                            profilepictureurl: profileimage || 'assets/img/profile.jpg',
                            role: 'User'
                          });
                          localStorage.setItem('uid', response.user.uid);
                          loading.dismiss();
                          this.navCtrl.navigate([this.loginlandingpage]);
                        })
                        .catch((error) => {
                          loading.dismiss();
                          console.log('Firebase failure:--- ' + JSON.stringify(error));
                          this.createToaster('Login Failure: ' + error.message, this.toastduration);
                        });
                    }
                  }
                ]
              });          
              await alert.present();              
            }
          }, (error) => {
            loading.dismiss();
            this.createToaster('Login Failure: User not a buyer.', this.toastduration);
          });
      },
      (error) => {
        const err = JSON.stringify(error);
        console.log('error--' + err);
        loading.dismiss(err);
        this.createToaster(err, this.toastduration);
      });
    }
    catch(err) {
      console.log(err);
    }
  }
    
  /*
  twitterLogin() {
      this.platform.ready().then((res) => {
        if (res === 'cordova') {
        this.twitter.login().then((result) => {
          console.log('twitter res--' + JSON.stringify(result));

          this.twitter.showUser().then( (onError) => {
            console.log('user--' + JSON.stringify(onError));
          }, async (user) => {
            // console.log("user--"+JSON.stringify(user));
            const loading = await this.loadingCtrl.create({
                message: 'Login Please Wait...'
              });
              loading.present();

            const provider = auth.TwitterAuthProvider.credential(result.token, result.secret);

            this.af.auth.signInWithCredential(provider)
                .then((response) => {
                  console.log('Firebase -G success: ' + JSON.stringify(response));

                  this.userssvc.HasRole('User', response.user.email).then((hasrolesuccess) => {
                    if (hasrolesuccess) {
                      localStorage.setItem('uid', response.user.uid);
                      loading.dismiss();
                      this.navCtrl.navigate([this.loginlandingpage]);
                    } else {
                      loading.dismiss();
                      this.createToaster('Login Failure: User not a buyer.', this.toastduration);
                    }
                  }, (hasroleerror) => {
                    loading.dismiss();
                    this.createToaster('Login Failure: User not a buyer.', this.toastduration);
                  });
                })
                .catch((error) => {
                  console.log('Firebase failure:--- ' + JSON.stringify(error));
                  this.createToaster('Login Failure: ' + error.message, this.toastduration);
                });
          });
        });
      }}
    );
  }    */
}
