import { Component, OnInit } from '@angular/core';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Configs } from '../../../app/config';
import { environment } from '../../../environments/environment';
import { UsersService } from '../../services/users/userservice';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FcmWebService } from '../../services/fcm/fcmweb.service';

@Component({
  selector: 'verifyemail-login',
  templateUrl: 'verifyemail.page.html',
  styleUrls: ['./verifyemail.page.scss']
})
export class VerifyEmailPage {
  currentuser: any = {
    email: '',
    isVerified: false
  }

  constructor(public navCtrl: Router,
    public af: AngularFireAuth,
    public fb: FormBuilder,
    public db: AngularFireDatabase,
    public userssvc: UsersService,   
    private googlePlus: GooglePlus,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    public toastCtrl: ToastController,
    private fcm: FcmWebService) { 
      this.af.onAuthStateChanged((user) => {
        if (user) {
          this.currentuser = user;
        }
      });
    }

    doResendEmail() {
      this.af.currentUser.then((cuser) => {
        cuser.sendEmailVerification().then(() => {
          this.navCtrl.navigate(['./verifyemail']);
        });
      });
    }
}
