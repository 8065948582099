import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Configs } from '../../../app/config';
import { environment } from '../../../environments/environment';
import { UsersService } from '../../services/users/userservice';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth-service/authservice';

@Component({
  selector: 'page-forgotpassword',
  templateUrl: 'forgotpassword.page.html',
  styleUrls: ['./forgotpassword.page.scss']
})
export class ForgotPasswordPage {
  tagHide = true;
  valForm: FormGroup;
  toastduration = Configs[environment.name].toastduration;
  constructor(public navCtrl: Router,   
    public fb: FormBuilder,
    public db: AngularFireDatabase,
    public userssvc: UsersService,   
    public loadingCtrl: LoadingController,
    public platform: Platform,
    public authService: AuthService,    
    public toastCtrl: ToastController) { 
      this.valForm = this.fb.group({
        'email': ['', [Validators.required, Validators.email]]
      });
    }

    async createToaster(message, duration) {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: duration
      });
      return toast.present();
    }

    async OnResetPwd($ev, value: any) {
      $ev.preventDefault();

      const loading = await this.loadingCtrl.create({
        message: 'Login Please Wait...',
        cssClass: 'loadingmsg'
      });
      loading.present();
      
      this.authService.resetPassword(value.email)
        .then(() => {
          console.log('Password Resetted: ');
          loading.dismiss();
          this.createToaster('Password reset successfully. Please check your email for reset instructions.', 5000)
            .then(() => {
              this.navCtrl.navigate(['/signintabs', { outlets: { 'login': 'login' } }]);
            });          
        })
        .catch((error) => {
          console.log('Error resetting password: ' + error);
          loading.dismiss();
          console.log('Firebase failure:--- ' + JSON.stringify(error));
          this.createToaster('Login Failure: ' + error.message, this.toastduration);
        });
    }
}
