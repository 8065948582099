import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliverablePipe',
  pure: false
})
export class DeliverablePipe implements PipeTransform {
  transform(allmenuItems: any[]) {
    return allmenuItems.filter(item => item.isdeliverable);
  }
}
