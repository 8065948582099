import { Component, ViewChild } from '@angular/core';
import { IonSlides, Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';


@Component({
  selector: 'page-about-us',
  templateUrl: 'about-us.html',
  styleUrls: ['about-us.scss']
})

export class AboutUsPage {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  contactNo: any = +12818710073;

  constructor(public platform: Platform,
              public router: Router,
              public route: ActivatedRoute,
              private iab: InAppBrowser
  ) {}

  ionViewDidLoad() {
  }

  goToVegecious(url: string) {
    /*if (this.platform.is('ios')) {
      this.iab.create(url, '_system');
    } else {
      window.open(url, '_system');
    }*/
    if (InAppBrowser.installed()) {
      this.iab.create(url, '_system');
    } else {
      window.open(url, '_system');
    }
  }
  
  goToSlide() {
    this.slides.slideTo(2, 500);
  }
}
