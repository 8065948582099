export const Configs = {
  dev: {
    locationbased: false,
    sellertimezone: 'Asia/Colombo',
    toastduration: 6000,
    currentversion: '7.0.0',
    enableServiceWorker: true,
    firebaseConfig: {
      apiKey: 'AIzaSyAIxYuLChLWz3UN3fz8wFjIqVHeEGCfY7w',
      authDomain: 'vegecious-dev.firebaseapp.com',
      databaseURL: 'https://vegecious-dev.firebaseio.com',
      projectId: 'vegecious-dev',
      storageBucket: 'vegecious-dev.appspot.com',
      messagingSenderId: '1006689624516',
      appId: '1:1006689624516:web:0a8e71ecdcc9189ecb4491',
      measurementId: 'G-1XQ70KJJG7'
    },
    googleConfig: {
      clientid: '1006689624516-9bmf48g4udld4r3327gu4r04eeb7mi54.apps.googleusercontent.com',
      clientsecret: 'YAS2QU9eiiDQ7Hr10-Vf_zpW',
      reverseclientid: 'com.googleusercontent.apps.1006689624516-bc4gregoo8qfeo1en4d19j5473eqi3ie',
      SHA1Key: '10:0A:92:51:A0:47:17:43:FF:46:5E:DF:87:87:77:E2:C7:FA:52:4C',
      mapsapikey: 'AIzaSyA_mxMrjt_vnYZzlU_wx_-pSCbD9aYYgP4'
    },
    menuitems: {
      imagesize: {
        width: 300,
        height: 300
      },
      missingmenuitem: 'missingmenu.svg',
      menuitemsimagesfolder: 'menuitems',
      missingchefprofile: 'defaultchef.svg',
      chefprofilefolder: 'userprofile',
      minquantitylimit: 1,
      maxquantitylimit: 100
    },
    razorpay: {
      description: 'Vegecious Buyer Purchase (Dev)',
      image: 'https://app-dev.vegecious.com/assets/img/splash.png',
      currency: 'INR',
      key: 'rzp_test_qvRmW04zZ2PSGQ',
      amount: '5000',
      name: 'foo',
      prefill: {
        email: 'info@vegecious.com',
        contact: '2818710073',
        name: 'Yogin Mashruwala'
      },
      theme: {
        color: '#F37254'
      }
    },
    Currency: {
      symbol: '₹ ',
      desc: 'INR'
    },
    SearchRange: {
      distance: 20000,
      unit: 'kms'
    },
    Buyer: {
      defaultprofileimage: 'profile.jpg',
      buerprofileimagesfolder: 'buyerprofile'
    }
  },
  prod: {
    locationbased: false,
    sellertimezone: 'Asia/Colombo',
    toastduration: 6000,
    currentversion: '7.0.0',
    enableServiceWorker: true,
    firebaseConfig: {
      apiKey: 'AIzaSyCbKun0hUW0Iw-e7MnM6-sFpTnCGUurlk0',
      authDomain: 'vegicious.firebaseapp.com',
      databaseURL: 'https://vegicious.firebaseio.com',
      projectId: 'vegicious',
      storageBucket: 'vegicious.appspot.com',
      messagingSenderId: '729500759064',
      appId: "1:729500759064:web:f833135b685f9572da7f6d",
      measurementId: "G-0GYZN68MK1"      
    },
    twitterConfig: {
      FABRIC_KEY: 'e4b7f2b96da3bb2fb577202c2aee06b3cb928374',
      TWITTER_KEY: 'E4GMph8VxlsYk8LSnv6M3ccHE',
      TWITTER_SECRET: '1sYHCikqkfBXmplNXKFJMncsjeq4tjmTaZDpXW3MqOuPha8KpP'
    },
    googleConfig: {
      clientid: '729500759064-6l3j6ig97f54kougd652bp5qeedijghm.apps.googleusercontent.com',
      clientsecret: '1nfZ8nzp4lTSms1InA2wxHRm',
      reverseclientid: 'com.googleusercontent.apps.729500759064-kgsol7qlf96jn2rto8u0kik86oqlk7fu',
      SHA1Key: 'D6:0D:3A:09:BF:80:9D:6D:D3:63:6E:89:83:1D:D4:6D:C9:92:3F:78',
      mapsapikey: 'AIzaSyA_mxMrjt_vnYZzlU_wx_-pSCbD9aYYgP4'
    },
    menuitems: {
      imagesize: {
        width: 300,
        height: 300
      },
      missingmenuitem: 'missingmenu.svg',
      menuitemsimagesfolder: 'menuitems',
      missingchefprofile: 'defaultchef.svg',
      chefprofilefolder: 'userprofile',
      minquantitylimit: 1,
      maxquantitylimit: 100
    },
    Buyer: {
      defaultprofileimage: 'profile.jpg',
      buerprofileimagesfolder: 'buyerprofile'
    },
    razorpay: {
      description: 'Vegicious Buyer Purchase',
      image: 'https://app.vegecious.com/assets/img/splash.png',
      currency: 'INR',
      key: 'rzp_live_FGxrrmMaxxPjt3',
      amount: '5000',
      name: 'foo',
      prefill: {
        email: 'info@vegecious.com',
        contact: '2818710073',
        name: 'Vegecious Admin'
      },
      theme: {
        color: '#022D41'
      }
    },
    Currency: {
      symbol: '₹ ',
      desc: 'INR'
    },
    SearchRange: {
      distance: 20000,
      unit: 'kms'
    }
  }
};
