import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "../../services/db-service/dbservice";
import { Configs } from "../../../app/config";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'page-chef',
    templateUrl: 'chef.html',
    styleUrls: ['chef.scss']
})
export class ChefPage implements OnInit {
    seller: any = {
        sellerid: '',
        name: '',
        storename: '',
        thumb: '',
        description: '',
        rating: {
            currentrating: 0,
            totalrating: 0
        }
    }
    currency: string = Configs[environment.name].Currency.symbol;
    
    constructor(public dbService: DbService, public route: ActivatedRoute, public router: Router, public modalCtrl: ModalController) {
        console.log('Chef...');
    }
    ngOnInit(): void {
        console.log('SellerInfo: ' + this.seller);
        // http://localhost:8100/seller/17FtIvI75SfmoW7RvQ6c5jxRQjn2
        this.route.paramMap.subscribe(params => {
            let sellershortname = params.get('id');
            console.log('Seller ShortName=' + sellershortname);
            if (
                (sellershortname != null) 
                //&& (sellershortname.length > 0)
            ) {
                this.getSellerInfoByShortName(sellershortname);
            } else {
                this.router.navigate(['/product-list/all']);
            }            
        });
        
    }

    getSellerInfoByShortName(sellersn: string) {
        console.log('Getting seller by shortname');
        this.dbService.getSellerInfoByShortName(sellersn).then((sellerinfo: any) => {
            console.log(sellerinfo);
            this.router.navigate(['/product-by-chef/' + sellerinfo.storeName]);
        }, (err) => {
            console.log('Error: ' + JSON.stringify(err));
            this.router.navigate(['product-list/all']);
        });
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            'dismissed': true
          });
    }

    navigate(item) {
        // console.log("id---"+item)
        let data = { 'itemid': item };
        // this.modalCtrl.dismiss(data);
        this.router.navigate(['/product-details', item]);
    }
}