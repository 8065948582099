import { Component } from '@angular/core';
import { AlertController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { Configs } from '../../config';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from '../../services/cart-service/cart.service';
import { SellerInfoPage } from '../sellerinfo/sellerinfo';
import { DbService } from '../../services/db-service/dbservice';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../../services/users/userservice';


@Component({
  selector: 'page-product-details',
  templateUrl: 'product-details.html',
  styleUrls: ['./product-details.scss']
})
export class ProductDetailsPage {

  FireVisible = false;
  id: any;
  count: any = 1;
  resFevrt: any = {};
  currency: string = Configs[environment.name].Currency.symbol;
  deltacounter = 0;
  public menuItems: any = {
    title: '',
    description: '',
    thumb: null,
    sellerid: '',
    tags: {},
    tagsstr: '',
    isActive: false,    
    offer: {
      hasOffer: false,
      offerPercentage: 0
    },
    preptimehrs: 0,
    quantityrange: {
      min: Configs[environment.name].menuitems.minquantitylimit || 1, 
      max: Configs[environment.name].menuitems.maxquantitylimit || 20, 
    },
    price: {
      pname: '',
      value: 0,
      specialPrice: 0
    },
    additionalprice: {
      pname: '',
      value: 0,
      specialPrice: 0
    },
    extraOptions: [],
    category: {
      id: '',
      name: ''
    }, 
    seller: {
      name: '',
      thumb: ''
    }
  };
  public cart = {
    itemId: '',
    sellerdetails: {
      storename: ''
    },
    sellerId: '',
    specialinstr: '',
    extraOptions: [],
    additionalprice: {
      pname: '',
      value: 0,
      specialPrice: 0
    },
    quantityrange: {
      min: Configs[environment.name].menuitems.minquantitylimit || 1, 
      max: Configs[environment.name].menuitems.maxquantitylimit || 20, 
    },
    price: {
      pname: '',
      value: 0,
      specialPrice: 0
    },
    totalprice: 0,
    title: '',
    thumb: String,
    itemQuantity: this.count
  };
  Cart: any = [];
  noOfItems: any;
  missingmenuitem: string;


  public selectedItems: Array<any> = [];

  menuItem: AngularFireObject<any>;

  toArray(parm: any): any[] {
    const result = [];
    if (parm != null) {
      Object.keys(parm).forEach((key) => {
        result.push(parm[key])
      });  
    }
    return result;
  }
  constructor(public router: Router,
              public af: AngularFireAuth,
              public analytics: AngularFireAnalytics,
              public dbsvc: DbService,
              public usersvc: UsersService,
              public db: AngularFireDatabase,
              public route: ActivatedRoute,
              private titleService: Title,
              public cartService: CartService,
              public alertCtrl: AlertController,
              public loadingCtrl: LoadingController,
              public modalController: ModalController,
              public toastCtrl: ToastController) {

    this.getcartcounts();

    this.dbsvc.getMissingMenuThumb().then((url) => {
      this.missingmenuitem = url;
    }, (err) => {
      this.missingmenuitem = '';
    })


    this.route.paramMap.subscribe(params => {
      this.getData(params.get('id'));
    });

  }

  getcartcounts() {
    this.Cart = JSON.parse(localStorage.getItem('Cart'));
    if (localStorage.getItem('Cart') != null) {
      this.noOfItems = this.Cart.length;
    }
  }
  ionViewWillEnter() {
    this.getcartcounts();
  }

  noofpeopleviewingthisitem = 0;
  getData(id: string) {
    this.id = id;
    this.menuItem = this.db.object('/menuItems/' + this.id);

    this.menuItem.snapshotChanges().subscribe((_data) => {
      const data: any = _data.payload.toJSON();
      let uniqueid = this.usersvc.getUniqueId();
      this.usersvc.logUser(this.id, uniqueid);
      this.usersvc.numUsersViewingItem(this.id).snapshotChanges().subscribe((res: []) => {
        this.noofpeopleviewingthisitem = res.length-1;
        console.log('viewsers viewing: ' + this.noofpeopleviewingthisitem);
      });
      this.analytics.logEvent('menu_item_view', {
        menu_name: data.title
      });
      this.titleService.setTitle("Vegecious App: " + data.title);
      data.$key = _data.key;
      data.$value = null;
      data.additionalprice = this.toArray(data.additionalprice);
      data.extraOptions = this.toArray(data.extraOptions);
      data.extraOptions.forEach(extraoption => {
        extraoption.quantity = 0;
        extraoption.totalcost = 0.0;
      });
      data.specialinstr = data.specialinstr || '';
      this.menuItems = data;
      if (this.menuItems.thumb === '') {
        this.menuItems.thumb = this.missingmenuitem;
      }
      let _tags = [];
      for (const [key, value] of Object.entries(this.menuItems.ntags || {})) {
        _tags.push(key);
      }
      this.menuItems.tagsstr = _tags.join(', ');
      console.log(this.menuItems.price);
      console.log('Logging ItemView');  
      this.analytics.logEvent('view_item', {
        currency: 'USD',
        value: this.menuItems.price,
        items: [{
          item_id: data.$key,
          item_name: data.title,
          item_category: 'food'
        }]
      });

      if ((this.menuItems.additionalprice != null) && (this.menuItems.additionalprice.length > 0)) {
        this.menuItems.additionalprice[0].isselected = (this.menuItems.additionalprice.length > 0);
      }
      
      this.cart.title = data.title;
      this.cart.itemId = data.$key;
      this.cart.thumb = data.thumb;
      this.cart.specialinstr = data.specialinstr;
      this.cart.sellerdetails = data.seller;
      this.cart.sellerId = data.sellerid;
      this.cart.price = data.price;
      this.cart.quantityrange = data.quantityrange;
      this.deltacounter = (data.quantityrange.min < 1) ? data.quantityrange.min : 1;
      this.cart.quantityrange.min = (this.cart.quantityrange.min > 0 ? this.cart.quantityrange.min : 1) || Configs[environment.name].menuitems.minquantitylimit || 1;
      this.cart.quantityrange.max = (this.cart.quantityrange.max <= 0 ? 1 : this.cart.quantityrange.max) || Configs[environment.name].menuitems.maxquantitylimit || 100;
      this.cart.itemQuantity = this.count = data.quantityrange.min;
      this.cart.additionalprice = {
        pname: '',
        value: 0,
        specialPrice: 0
      };
      this.cart.extraOptions = this.toArray(data.extraOptions);
      this.cart.extraOptions.forEach(extraoption => {
        extraoption.quantity = 0;
        extraoption.totalcost = 0.0;
      });;
      console.log(this.cart);

      /*
      if (this.af.currentUser) {
        this.af.currentUser.then((cuser) => {
          this.db.object('/users/' + cuser.uid + '/favourite/' + data.$key).valueChanges().subscribe((res: any) => {
            res = res || {};
            res.$key = data.$key;
            res.$value = res.$value || null;
            this.resFevrt = res;
          });
        });
      }*/
    });
  }

  addQuantity() {
    if (this.count < this.menuItems.quantityrange.max) {
      this.count = this.count + this.deltacounter;
      this.cart.itemQuantity = this.count;
    }
    return false;
  }

  removeQuantity() {
    if (this.count > this.menuItems.quantityrange.min) {
      this.count = this.count - this.deltacounter;
      this.cart.itemQuantity = this.count;
    }
    return false;
  }

  addExtraQuantity(extraoption, _index) {
    extraoption.quantity++;
    extraoption.totalcost = extraoption.quantity*extraoption.value;

      // Update the cart also.
      this.cart.extraOptions[_index].quantity = extraoption.quantity; 
      this.cart.extraOptions[_index].totalcost = this.cart.extraOptions[_index].quantity*this.cart.extraOptions[_index].value;
    return false;
  }

  removeExtraQuantity(extraoption, _index) {
    if (extraoption.quantity > 0) {
      extraoption.quantity--;
      extraoption.totalcost = extraoption.quantity*extraoption.value;
      
      // Update the cart also.
      this.cart.extraOptions[_index].quantity = extraoption.quantity; 
      this.cart.extraOptions[_index].totalcost = this.cart.extraOptions[_index].quantity*this.cart.extraOptions[_index].value;
    }
    return false;
  }

  getSum(items: any[]) {
    let s = 0;
    items.forEach((o, i) => {
      s += (o.specialPrice != null && o.specialPrice > 0 ? o.specialPrice : o.totalcost);
    });
    return s;
  }

  getTotal() {
    let baseprice = (this.cart.price.specialPrice > 0 ? this.cart.price.specialPrice : this.cart.price.value);
    let additionalprice = this.cart.additionalprice != null ? 
                          (
                            (this.cart.additionalprice.specialPrice != null && this.cart.additionalprice.specialPrice > 0) ? this.cart.additionalprice.specialPrice : (this.cart.additionalprice.value)
                          ) : 0;
    let extraoptionprice = (this.cart.extraOptions.length > 0 ? this.getSum(this.cart.extraOptions) : 0);
    let total = (this.cart.itemQuantity * (additionalprice != null && additionalprice > 0 ? additionalprice : baseprice)) + extraoptionprice;
    return total;
  }
  navcart() {
    this.router.navigate(['/cart']);
  }

  home() {
    this.router.navigate(['/product-list/all']);
  }

  sizeOptions(aprice) {
    this.cart.additionalprice = aprice;
  }
  sizeOptionsDeselect(event: CustomEvent) {
    if (event.detail.value == null) {
      this.cart.additionalprice = null;
    }    
  }


  checkOptions(extraOption) {
    if (this.cart.extraOptions.length !== 0) {
      for (let i = 0; i <= this.cart.extraOptions.length - 1; i++) {
        if (this.cart.extraOptions[i].name === extraOption.name) {
          this.cart.extraOptions.splice(i, 1);
          break;
        } else {
          this.cart.extraOptions.push(extraOption);
          break;
        }
      }
    } else {
      this.cart.extraOptions.push(extraOption);
    }
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Please!',
      subHeader: 'Select Size and Price!',
      buttons: ['OK']
    });

    await alert.present();
  }


  saveItemtoCart() {
    this.cart.totalprice = this.getTotal();
    console.log(this.cart);
    this.cartService.OnsaveLS(this.cart);
    console.log('Logging AddtoCart');  
      this.analytics.logEvent('add_to_cart', {
        currency: 'USD',
        value: this.cart.price.value,
        items: [{
          item_id: this.cart.itemId,
          item_name: this.cart.title,
          item_category: 'food'
        }]
      });
    this.router.navigate(['/cart']);  
  }

  async presentDifferentSellerAlert(existingsellername, newsellername) {
    const alert = await this.alertCtrl.create({
      //header: 'Delete current cart and start new order?',
      message: 'Your cart has items from </i>' + existingsellername + '</i>. You can order from one seller at a time.',
      buttons: [
        {
          text: 'Keep current cart items.',
          role: 'cancel',
          handler: () => {
            console.log('Retain cart items...');
          }
        },
        {
          text: 'Start new order.',
          cssClass: '',
          handler: () => {
            console.log('remove all items from cart..');
            this.cartService.clearCart();
            this.saveItemtoCart();
          }
        }
      ]
    });

    await alert.present();
  }

  carthassameseller() {
    let newseller = {
      name: this.cart.sellerdetails.storename,
      id: this.cart.sellerId
    };


    if (this.Cart == null) {
      return {
        newsellername: newseller.name,
        existingsellername: '',
        isSame: true
      };
    }
    if (this.Cart.length == 0) {
      return {
        newsellername: newseller.name,
        existingsellername: '',
        isSame: true
      };
    }

    let existingseller = {
      name: this.Cart[0].item.sellerdetails.storename,
      id: this.Cart[0].item.sellerId
    };
    
    return {
      newsellername: newseller.name,
      existingsellername: existingseller.name,
      isSame: existingseller.id === newseller.id
    };
  }

  addToCart() {
    console.log('item.cart' + JSON.stringify(this.cart));
    let comparesellers = this.carthassameseller();
    if (comparesellers.isSame) {
      this.saveItemtoCart();
    } else {
      this.presentDifferentSellerAlert(comparesellers.existingsellername, comparesellers.newsellername);
      console.log('Different seller items exist in cart...');
    }
  }

  visible = false;


  addToFevrt(key) {
        if (this.af.currentUser) {
          this.af.currentUser.then((cuser) => {
            this.db.object('/users/' + cuser.uid + '/favourite/' + key).update({
              thumb: this.menuItems.thumb,
              title: this.menuItems.title,
              description: this.menuItems.description
            }).then(res => {
              this.createToaster('added to favourites', '3000');
            });
          })
        }
  }

  removeFevrt(key) {
    if (this.af.currentUser) {
      this.af.currentUser.then((cuser) => {
        console.log('uid' + cuser.uid);
        this.db.object('/users/' + cuser.uid + '/favourite/' + key).remove();
        this.createToaster('removed from favourites', '3000');
      });
    }
  }

  async createToaster(message, duration) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
  }


  // Seller info.
  async presentSellerInfoModal(item: any) {
    const modal = await this.modalController.create({
      component: SellerInfoPage,
      cssClass: 'seller-info.scss',
      animated: true,
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      swipeToClose: true,
      componentProps: {
        'seller': {
          'sellerid': item.sellerid,
          'name': item.seller.name,
          'storename': item.seller.storename,
          'thumb': item.seller.thumb,
          'description': item.seller.description
        }
      }
    });
    modal.onDidDismiss().then((modalResult) => {
      if (modalResult.data != null) {
        this.navigate(modalResult.data.itemid);
      }
    });
    return await modal.present();
  }


  showSellerInfo(event: Event, item: any) {
    event.stopPropagation();
    console.log('item: ' + JSON.stringify(item));
    this.presentSellerInfoModal(item);
    return true;
  }

  navigate(item) {
    // console.log("id---"+item)
    this.router.navigate(['/product-details', item]);
  }

}
