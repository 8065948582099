<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Password Reset</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="login">
  <img class="logo" src="assets/img/logo-icon.png">

  <!--inputs-->
  <!--email-->
  <form [formGroup]="valForm" (submit)="OnResetPwd($event, valForm.value)">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <!--<ion-label>{{"Email:" | translate}}</ion-label>-->
          <ion-input class="form-control" id="inputEmail" type="email" name="email" placeholder="Enter email" autocomplete="off" formControlName="email" required></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row style="margin-top: 10px; margin-bottom: 10px;">
      <ion-col size="12">
        <ion-button class="reset-btn" expand="block" type="submit" [disabled]="!valForm.valid">{{"Reset" | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </form>
</ion-content>