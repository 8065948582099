<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{"Settings" |translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>     
    <ion-list>
        <ion-item>
            <ion-grid>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        <ion-avatar class="ion-text-center" style="margin: auto; width: 128px; height: 128px;">
                            <ion-img [src]="user.profilepictureurl"></ion-img>
                        </ion-avatar>
                        <div>{{user.name}}</div>
                        <br/>
                        <ion-button fill="outline" (click)="takePicture()">
                            <ion-icon slot="icon-only" name="cloud-upload"></ion-icon>
                            &nbsp;Change Profile Picture
                        </ion-button>
                        
                        <!--<input style="display: none" [imageToDataUrl]="imageoptions" type="file" (imageSelected)="readUrl($event)" #fileInput>
                        <ion-button fill="outline" (click)="fileInput.click()">
                            <ion-icon slot="icon-only" name="cloud-upload"></ion-icon>
                            &nbsp;Change Profile Picture
                        </ion-button>
                    -->
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>        
        <!--<ion-item>
            <ion-label>Languages</ion-label>
            <ion-select [(ngModel)]="value" (ionChange)="changeLanguage()">
                <ion-select-option *ngFor="let option of options" value="{{option.value}}"> {{option.language }}
                </ion-select-option>
            </ion-select>
        </ion-item>-->
    </ion-list>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <ion-list>
            <!--<ion-item *ngIf="user.notification == true">
                <ion-label primary> Notifications</ion-label>
                <ion-toggle primary checked="true" name="name" id="name" [(ngModel)]="user.notification"></ion-toggle>

            </ion-item>
            <ion-item *ngIf="user.notification !== true">
                <ion-label primary> Notifications</ion-label>
                <ion-toggle primary checked="false" name="notification" id="notification" [(ngModel)]="user.notification"></ion-toggle>

            </ion-item>-->
            <!--card no-->
            <ion-item>
                <ion-label>User Name:</ion-label>
                <ion-input type="text" name="name" id="name" autocomplete="name" placeholder="name" [(ngModel)]="user.name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>Email:</ion-label>
                <ion-input type="email" name="email" autocomplete="email" id="email" placeholder="email" readonly [(ngModel)]="user.email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>Mobile Number:</ion-label>
                <ion-input type="text" name="mobileNo" autocomplete="tel-national" id="mobileNo" placeholder="mobile no" [(ngModel)]="user.mobileNo"></ion-input>
            </ion-item>
        </ion-list>
        <!--button-->
        <ion-button class="login-btn1" expand="block" type="submit">Save</ion-button>
    </form>

</ion-content>