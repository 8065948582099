import {Injectable} from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class CartService {
    Cart: any[] = [];
    itemCart: any = {};
    itemInCart = [];

    constructor(private functions: AngularFireFunctions) {
        this.Cart = JSON.parse(localStorage.getItem('Cart'));
    }

    getSellerFromCart(): string {
        this.Cart = JSON.parse(localStorage.getItem('Cart'));
        console.log(this.Cart);
        return (this.Cart == null) ? '' : 
                                            (this.Cart.length > 0) ? 
                                                                        this.Cart[0].item.sellerId : '';
    }


    clearCart() {
        localStorage.removeItem('Cart');
        localStorage.removeItem('promocode');
    }

    OnsaveLS(item: any) {
        this.Cart = JSON.parse(localStorage.getItem('Cart'));
        this.itemInCart = [];
        if (this.Cart == null) {
            this.itemCart.itemTotalPrice = item.totalprice;
            this.itemCart.item = item;
            this.itemInCart.push(this.itemCart);
            localStorage.setItem('Cart', JSON.stringify(this.itemInCart));
        } else {
            for (let i = 0; i <= this.Cart.length - 1; i++) {
                if (this.Cart[i].item.itemId === item.itemId) {
                    this.Cart.splice(i, 1);
                }
            }
            this.itemCart.itemTotalPrice = item.totalprice;
            this.itemCart.item = item;
            this.Cart.push(this.itemCart);
            localStorage.setItem('Cart', JSON.stringify(this.Cart));
        }
    }


    validateCoupon(cart: any) {
        const validateCouponPromise = new Promise<string>((resolve, reject) => {
            const validateCouponCallable = this.functions.httpsCallable('validateCoupon');
            validateCouponCallable(cart).subscribe(async res => {
                console.log(res);
                resolve(res);
            }, (err) => {
                console.error('Error: ' + err);
            });
        });

        return validateCouponPromise;
    }
}
