import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { Configs } from "./../../../app/config";
import { environment } from "../../../environments/environment";
import { DbService } from "../../../app/services/db-service/dbservice";
import { Observable } from "rxjs";

@Component({
    selector: 'filter',
    templateUrl: 'filter.html',
    styleUrls: ['filter.scss']
})
export class FilterPage implements OnInit {
    @Input() public filter: any;
    currency: string = Configs[environment.name].Currency.symbol;
    //allLocations$: Observable<string[]>;    
    allLocations$ = [];
    selectlocationfilter = ''
   
    constructor(public dbService: DbService, public router: Router, public modalCtrl: ModalController) {
       this.dbService.getLocations().then((data) => {
        this.allLocations$ = data;
        this.selectlocationfilter = this.filter.location;
       });
    }
    ngOnInit(): void {
        console.log('Filter: ' + this.filter);
    }

    setLocationFilter($event) {
        this.filter.location = this.selectlocationfilter;
    }

    dismissModal() {
        this.modalCtrl.dismiss(this.filter);
    }

    clearallfilters() {
        this.selectlocationfilter = '';
    }

    applyfilters() {
        this.filter.location = this.selectlocationfilter;
        this.modalCtrl.dismiss(this.filter);
    }

    cancelfilters() {
        this.selectlocationfilter = '';
        this.modalCtrl.dismiss(this.filter);
    }
}