import { Component}  from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Configs } from '../../config';
import { environment } from '../../../environments/environment';
import { AlertController, ToastController } from '@ionic/angular';
import { DbService } from '../../services/db-service/dbservice';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'page-checkout',
  templateUrl: 'checkout.html',
  styleUrls: ['checkout.scss']
})
export class CheckoutPage {
  ordertime: any;
  orderId: any;
  tagHide1 = true;
  tagHide2 = true;
  cart: Array<any>;
  noOfItems: any;
  subTotal = 0;
  totalwithoutdiscount = 0;
  GrandTotal = 0;
  taxamt = 0;
  settings: any;

  deductedPrice: number = 0;
  suborders: Array<any> = [];
  address: any = {
    name: '',
    address: '',
    city: '',
    zipcode: '',
    phoneno: '',
    instructions: ''
  };
  cardDetails: any = {};
  userId: any;
  buyerDetails = {
    email: '',
    name: '',
    mobileNo: '',
    userid: ''
  };
  suborderslist: AngularFireList<any>;
  orderslist: AngularFireList<any>;
  userDetail: Observable<any>;
  bookings: Observable<any>;
  color: any;
  str: any;
  showtextspinner = false;

  constructor(public af: AngularFireAuth,
              public db: AngularFireDatabase,
              public analytics: AngularFireAnalytics,
              public router: Router,
              public dbservice: DbService,
              public alertCtrl: AlertController,
              public toastCtrl: ToastController,
              public route: ActivatedRoute) {
    console.log('Checkout');
    this.getData();
    this.dbservice.getSettings().then((_settings) => {
      console.log('Settings: ' + JSON.stringify(_settings));
      this.settings = _settings;
      this.computeGrandTotal();
    });
  }

  promodetails: any = {
    discamt: 0,
    discpct: 0,
    isvalid: false,
    promocode: ''
  };
  deliverydetails = {
    deliverycharge: 0,
    deliveryby: 'Unknown'
  };

  getData() {            
    this.str = '#';
    console.log('Checkout..');
    this.userId = localStorage.getItem('uid');
    if (this.userId != null) {
      this.userDetail = this.db.object('/users/' + this.userId).valueChanges();
      this.userDetail.subscribe((res) => {
        this.address = {
          name: res.name,
          address: '',
          city: '',
          zipcode: '',
          phoneno: res.mobileNo,
          instructions: ''
        };
        this.buyerDetails = {
          email: res.email,
          name: res.name,
          mobileNo: res.mobileNo,
          userid: this.userId
        };
      });
      this.cart = JSON.parse(localStorage.getItem('Cart'));        
      this.promodetails = JSON.parse(localStorage.getItem('promocode')) ||  {
        discamt: 0,
        discpct: 0,
        isvalid: false,
        promocode: ''
      };
      console.log(this.promodetails);
      if (this.cart != null) {
        this.noOfItems = this.cart.length;
      }

      this.suborderslist = this.db.list('/suborders');
      this.orderslist = this.db.list('/orders');
    }
  } 
  
  applyPromo() {    
      this.subTotal = this.subTotal - (this.promodetails.discamt || 0);
  }

  computeGrandTotal() {
    let _subTotal = 0;
    if (this.settings == null) {
      let setting = this.db.object('/settings').valueChanges();
      setting.subscribe((data) => {
        this.settings = data;
        for (let i = 0; i <= this.cart.length - 1; i++) {
          _subTotal = _subTotal + this.cart[i].itemTotalPrice;
        }
        this.subTotal = _subTotal;
        this.totalwithoutdiscount = _subTotal;
        this.applyPromo();
        this.taxamt = (this.subTotal) * this.settings.totalGST / 100;
        this.GrandTotal = this.subTotal + this.taxamt + this.deliverydetails.deliverycharge;
      });  
    } else {
      for (let i = 0; i <= this.cart.length - 1; i++) {
        _subTotal = _subTotal + this.cart[i].itemTotalPrice;
      }
      this.subTotal = _subTotal;
      this.totalwithoutdiscount = _subTotal;
      this.applyPromo();
      
      this.taxamt = (this.subTotal) * this.settings.totalGST / 100;
      this.GrandTotal = this.subTotal + this.taxamt + this.deliverydetails.deliverycharge;
    }
  }
  

  redeempromoandgenerateorderid(cart: any, promocode: any, grandtotal: number): Promise<any> {
    if ((promocode != null) && (promocode.length > 0)){
      const redeempromoandgenerateorderidPromise1 = new Promise<any>((resolve, reject) => {
        this.dbservice.redeempromoandgenerateorderid(cart, promocode, grandtotal).then((promodetails: any) => {
          let totalfordlv:number = grandtotal;
          if (promodetails.isValid) {
            totalfordlv = totalfordlv - promodetails.discamt;
          }          
          this.dbservice.calculateDelivery(cart, totalfordlv).then((dlvdetails: any) => {
            resolve({
              "isValid": promodetails.isValid,
              "discpct": promodetails.discpct,
              "discamt": promodetails.discamt,
              "promokey": promodetails.promokey,
              "reason": promodetails.reason,
              'deliverycharge': dlvdetails.deliverycharge,
              'deliveryby': dlvdetails.deliveryby,
              "neworderid": promodetails.neworderid
            });
          });
        });
      });
      return redeempromoandgenerateorderidPromise1;
      
    } else {
      const redeempromoandgenerateorderidPromise = new Promise<any>((resolve, reject) => {
        let deliverypromise = this.dbservice.calculateDelivery(cart, grandtotal);
        let neworderpromise = this.dbservice.getNewOrderId();
        Promise.all([deliverypromise, neworderpromise]).then((values: any[]) => {
          resolve({
            "isValid":false,
            "discpct":0,
            "discamt":0,
            "promokey":"",
            "reason":"no promo",
            'deliverycharge': values[0].deliverycharge,
            'deliveryby': values[0].deliveryby,
            "neworderid":values[1]
          });  
        });
      });
      return redeempromoandgenerateorderidPromise;
    }    
  }


  async onRazorPayCheckout() {
    if (this.buyerDetails.mobileNo == null) {
      const toast = await this.toastCtrl.create({
        message: 'Complete your profile... Mobile No. required.',
        duration: 5000
      });
      toast.present().then(() => {
        return this.router.navigate(['/settings']);
      });
    } else {
      console.log(this.cart);
      this.redeempromoandgenerateorderid(this.cart, this.promodetails.promocode, this.totalwithoutdiscount).then((x) => {
        console.log('x: ' + JSON.stringify(x));
        this.promodetails = {
          discamt: x.discamt,
          discpct: x.discpct,
          isvalid: x.isValid,
          promocode: this.promodetails.promocode
        };
        this.deliverydetails = {
          deliverycharge: x.deliverycharge,
          deliveryby: x.deliveryby
        };

        this.computeGrandTotal();
        this.orderId = x.neworderid;      
        RazorpayCheckout.open({
          description: 'Vegecious Buyer Payment',
          image: Configs[environment.name].razorpay.image,
          currency: 'INR',
          key: Configs[environment.name].razorpay.key,
          notes: {
            merchant_order_id: x.neworderid,
            comments: 'Vegecious Order.' + JSON.stringify(this.cart)
          },
          amount: (Math.floor(this.GrandTotal * 100)).toString(),
          name: this.buyerDetails.name,
          prefill: {
            email: this.buyerDetails.email,
            contact: this.buyerDetails.mobileNo,
            name: this.buyerDetails.name
          },
          theme: {
            color: '#F37254'
          },
          modal: {
            ondismiss: function() {
              console.log('dismissed');
            }
          }
        }, (payment_id: number) => {
          console.log('payment_id: ' + payment_id.toString());
          console.log('Logging Purchase');  
          let _items = this.cart.map(x => ({item_id: x.item.itemId, item_name: x.item.title, price: x.item.totalprice, item_category: 'food', quantity: x.item.itemQuantity}));
          this.analytics.logEvent('purchase', {
            transaction_id: this.orderId,
            affiliation: 'Vegecious',
            currency: 'INR',
            value: this.GrandTotal, // Total Revenue
            tax: this.taxamt,
            shipping: 0,
            coupon: this.promodetails.promocode,            
            items: _items
          });
          this.saveOrder(payment_id, 'RazorPay');
          this.clearCart();
          this.router.navigate(['/orders']);
        }, (error: any) => {
          alert(error.description + ' (Error ' + error.code + ')');
        });  
      });
    }
  }
 

  async onCODCheckout() {
    if (this.buyerDetails.mobileNo == null) {
      const toast = await this.toastCtrl.create({
        message: 'Complete your profile... Mobile No. required.',
        duration: 5000
      });
      toast.present().then(() => {
        return this.router.navigate(['/settings']);
      });
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Payment Type',
        subHeader: 'Cash on delivery payment',
        message: 'Please confirm, that you select to pay cash on delivery of this order.',
        buttons: [
          {
            text: 'Ok',
            handler: data => {
              console.log('COD checkout confirmed by user.');
              this.showtextspinner = true;
              //this.dbservice.getNewOrderId().then(async (neworderid: string) => {
              this.redeempromoandgenerateorderid(this.cart, this.promodetails.promocode, this.totalwithoutdiscount).then((x) => {
                console.log('x: ' + JSON.stringify(x));
                this.promodetails = {
                  discamt: x.discamt,
                  discpct: x.discpct,
                  isvalid: x.isValid,
                  promocode: this.promodetails.promocode
                };
                this.deliverydetails = {
                  deliverycharge: x.deliverycharge,
                  deliveryby: x.deliveryby
                }
                this.computeGrandTotal();
                this.orderId = x.neworderid;                
                console.log('Logging Purchase');  
                let _items = this.cart.map(x => ({item_id: x.item.itemId, item_name: x.item.title, price: x.item.totalprice, item_category: 'food', quantity: x.item.itemQuantity}));
                this.analytics.logEvent('purchase', {
                  transaction_id: this.orderId,
                  affiliation: 'Vegecious',
                  currency: 'INR',
                  value: this.GrandTotal, // Total Revenue
                  tax: this.taxamt,
                  shipping: this.deliverydetails.deliverycharge,
                  coupon: this.promodetails.promocode || '',            
                  items: _items
                });
                this.saveOrder(null, 'COD');
                this.clearCart();
                this.showtextspinner = false;
                this.router.navigate(['/orders']);
                  
              });
            }
          },
          {
            text: 'Cancel'
          }
        ]
      });
      alert.present();
    }
  }

  onCheckOut(form: NgForm){
  }
  
  clearCart() {
    localStorage.removeItem('Cart');
    localStorage.removeItem('promocode');
  }

  saveOrder(payment_id: number, paymenttype: string) {
    const buyerId = this.buyerDetails.userid;
    const subordersfinal = {};

    let promocodedetails: any = JSON.parse(localStorage.getItem('promocode')) || {
      discamt: 0,
      discpct: 0,
      isvalid: false,
      promocode: ""
    };
    
    let date = new Date(); 
    let ordertime = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());    
    let taxpct = this.settings.totalGST;
    
    let suborderindex = 1;
    this.cart.forEach((cartitem) => {
      const sellerId = cartitem.item.sellerId;
      const thissuborder = subordersfinal[sellerId] || {
        suborderId: 0,
        buyerId: buyerId,
        buyerDetails: this.buyerDetails,
        sellerId: sellerId,
        createdAt: ordertime,
        promocode: this.promodetails.promocode,
        promodiscpct: this.promodetails.discpct,
        promodiscamt: this.promodetails.discamt,
        grandTotal: 0,
        totalitemprice: 0,
        taxpct: taxpct,
        taxamt: 0,
        status: 'Pending',
        deliverydetails: this.address,
        deliverycharge: this.deliverydetails.deliverycharge,
        deliveryby: this.deliverydetails.deliveryby,
        items: []
      };

      if (thissuborder.suborderId === 0) {
        thissuborder.suborderId = this.orderId + '-' +suborderindex;
        suborderindex++;
      }
      thissuborder.totalitemprice += cartitem.itemTotalPrice;

    const newcartitem = {
        itemId: cartitem.item.itemId,
        itemQuantity: cartitem.item.itemQuantity,
        thumb: cartitem.item.thumb,
        title: cartitem.item.title,
        itemTotalPrice: cartitem.itemTotalPrice,
        specialinstr: cartitem.item.specialinstr || '',
        price: cartitem.item.price,
        extraOptions: cartitem.item.extraOptions
      };
      thissuborder.items.push(newcartitem);
      subordersfinal[sellerId] = thissuborder;
    });

    const orderobj = {
      orderId: this.orderId,
      buyerId: buyerId,
      paymentMethod: paymenttype,
      razorpay_paymentid: payment_id,
      promocode: this.promodetails.promocode,
      promodiscpct: this.promodetails.discpct,
      promodiscamt: this.promodetails.discamt,
      suborders: []
    };
    for (const prop in subordersfinal) {
      if (subordersfinal.hasOwnProperty(prop)) {
        subordersfinal[prop].paymentMethod = paymenttype;
        orderobj.suborders.push(subordersfinal[prop].suborderId);
        
        // calculate the suborders totals.
        var suborderobj = subordersfinal[prop];
        suborderobj.razorpay_paymentid = payment_id;
        suborderobj.promodiscamt = this.promodetails.discamt;
        let totalpriceofsuborder = suborderobj.totalitemprice - suborderobj.promodiscamt + suborderobj.deliverycharge;
        suborderobj.taxamt = (suborderobj.totalitemprice - suborderobj.promodiscamt) * suborderobj.taxpct / 100;
        suborderobj.grandTotal = totalpriceofsuborder + suborderobj.taxamt;
        this.suborderslist.push(subordersfinal[prop]);
      }
    }

    this.orderslist.push(orderobj);
    console.log(subordersfinal);
    this.clearCart();
  }

  navcart() {
    this.router.navigate(['/cart']);
  }

  toggle2() {
    this.tagHide2 = this.tagHide2 ? false : true;
  }

  toggle3() {
    this.tagHide2 = true;
  }

}
