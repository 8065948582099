import { Component, Input, OnInit } from "@angular/core";
import { Configs } from "../../config";
import { environment } from "../../../environments/environment";
import { PopoverController } from "@ionic/angular";

@Component({
    selector: 'listitems-popup',
    templateUrl: 'listitems-popup.html',
    styleUrls: ['listitems-popup.scss']
})
export class listitemsPopupPage implements OnInit {
    @Input() public data: any;
    currency: string = Configs[environment.name].Currency.symbol;
    
    constructor(private popoverController: PopoverController) {        
    }

    ngOnInit(): void {    
        console.log('Data: ' + this.data);    
    }

    dismissPopover()
    {
        this.popoverController.dismiss();
    }    
}