import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "../../services/db-service/dbservice";
import { Configs } from "../../../app/config";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'page-seller',
    templateUrl: 'seller.html',
    styleUrls: ['seller.scss']
})
export class SellerPage implements OnInit {
    seller: any = {
        sellerid: '',
        name: '',
        storename: '',
        thumb: '',
        description: '',
        rating: {
            currentrating: 0,
            totalrating: 0
        }
    }
    currency: string = Configs[environment.name].Currency.symbol;
    
    constructor(public dbService: DbService, public route: ActivatedRoute, public router: Router, public modalCtrl: ModalController) {
        
    }
    ngOnInit(): void {
        console.log('SellerInfo: ' + this.seller);
        // http://localhost:8100/seller/17FtIvI75SfmoW7RvQ6c5jxRQjn2
        this.route.paramMap.subscribe(params => {
            let sellerid = params.get('id');
            if ((sellerid != null) && (sellerid.length > 0)) {
                this.getSellerInfo(sellerid);
            } else {
                this.router.navigate(['/product-list/all']);
            }            
        });
        
    }

    getSellerInfo(sellerid: string) {
        this.dbService.getSellerMenuItems(sellerid).then((menuitems: any[]) => {
            let nitems = menuitems.length;
            let avgrating = 0;
            let totalratings = 0;
            menuitems.forEach((x) => {
                if (x.rating != null) {
                    avgrating += x.rating.currentrating || 0;
                    totalratings += x.rating.totalratings || 0;
                }
            });
            this.seller.rating.currentrating = avgrating/nitems;
            this.seller.rating.totalrating = totalratings;
            this.dbService.getSellerDetails(sellerid).then((sellerdetails: any) => {
                this.seller = {
                    sellerid: sellerid,
                    name: '',
                    storename: sellerdetails.storeName,
                    thumb: sellerdetails.profileimage,
                    description: sellerdetails.description,
                    rating: {
                        currentrating: 0,
                        totalrating: 0
                    },
                    menuitems: menuitems
                };
                console.log(this.seller);
            });
        });
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            'dismissed': true
          });
    }

    navigate(item) {
        // console.log("id---"+item)
        let data = { 'itemid': item };
        // this.modalCtrl.dismiss(data);
        this.router.navigate(['/product-details', item]);
    }
}