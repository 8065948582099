import { Component, ViewEncapsulation, NgZone } from '@angular/core';
import { Platform, ToastController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
//import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '../environments/environment';
import { Configs } from './config';
import { AuthService } from './services/auth-service/authservice';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { FcmWebService } from './services/fcm/fcmweb.service';
import { filter, tap } from 'rxjs/operators';
import { FcmNativeService } from './services/fcm/fcmnative.service';
// import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { ThemeService } from './services/theme-service/theme.service';
import { DbService } from './services/db-service/dbservice';
import { RateItemPage } from './pages/rate-item/rate-item';
import { SwUpdate } from '@angular/service-worker';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UsersService } from './services/users/userservice';

let themes = [];
const defaultthemes = {
  chocolatey: {
      primary: '#be4646',
      secondary: '#e9c7b9',
      tertiary: '#832f2f',
      success: '#2dd36f',
      warning: '#ffc409',
      danger: '#eb445a',
      dark: '#222428',
      medium: '#989aa2',
      light: '#f4f5f8',
      black: '#000000',
      white: '#FFFFFF'
  },
  chocolateydark: {
    primary: '#e9c7b9',
    secondary: '#be4646',
    tertiary: '#832f2f',
    success: '#2dd36f',
    warning: '#ffc409',
    danger: '#eb445a',
    dark: '#f4f5f8',
    medium: '#989aa2',
    light: '#222428',
    black: '#FFFFFF',
    white: '#000000'
},
  autumn: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876'
  },
  night: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867'
  },
  neon: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A'
  },
  oriental: {
    primary: '#3C150A',
    secondary: '#F7DC99',
    tertiary: '#CFAE61',
    light: '#C9BFBC',
    medium: '#925F52',
    dark: '#3C150A'
  },
  default: {
    primary: '#3880ff',
    secondary: '#3dc2ff',
    tertiary: '#5260ff',
    light: '#f4f5f8',
    medium: '#92949c',
    dark: '#222428',
    black: '#000000',
    white: '#FFFFFF',
  },
  defaultdark: {
    primary: '#3dc2ff',
    secondary: '#3880ff',
    tertiary: '#5260ff',
    light: '#487294',
    medium: '#92949c',
    dark: '#f4f5f8',
    black: '#FFFFFF',
    white: '#000000',
  }
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss']
})
export class AppComponent {
  isDesktop = false;
  imagebaseurl = '';
  Cart: any = [];
  noOfItemsInCart: any;
  noOfItemsInFevrt: any;
  noOfItemsInNews: any;
  noOfItemsInOffer: any;
  userID: any;
  name: any = 'Not Signed In.';
  currentenv = 'env:??';
  currentversion = 'x.x.x';
  profilepicture = 'assets/img/profile.jpg';
  rootPage = 'HomePage';
  message;
  hasCheckedIfUserCanRate = false;

  constructor(
    public af: AngularFireAuth,
    public db: AngularFireDatabase,
    public analytics: AngularFireAnalytics,
    private platform: Platform,
    private router: Router,
    private ngZone: NgZone,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    public toastCtrl: ToastController,
    private authService: AuthService,
    private googlePlus: GooglePlus,
    private fcmweb: FcmWebService,
    private fcmnative: FcmNativeService,
    private userservice: UsersService,
    private theme: ThemeService,
    private dbserver: DbService,
    public modalController: ModalController,
    private swUpdate: SwUpdate//,
    //private firebaseDynamicLinks: FirebaseDynamicLinks
  ) {    
    translate.setDefaultLang('en');   
    this.platform.ready().then(() => {
      this.initializeApp();
    });       
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }

  changeSpeed(val) {
    this.theme.setVariable('--speed', `${val}ms`);
  }

  async createToaster(message, duration) {
    const toast = await this.toastCtrl.create({
      header: 'Vegecious Update',
      position: 'top',
      message: message,
      duration: duration
    });
    toast.present();
  }

  private initAnalytics() {
    this.analytics.setAnalyticsCollectionEnabled(true);
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      console.log('route changed: ', e.url);
      this.analytics.logEvent('menu_item_view', {
        screen_name: e.url
      });
    });
  }
  
  /*
  private initDynamicLinks() {
    console.log('Init Dynamic Links....');
    // Handle the logic here after opening the app with the Dynamic link
    this.firebaseDynamicLinks.onDynamicLink()
      .subscribe((res: any) => console.log('onDynamicLink: ' + JSON.stringify(res)), (error:any) => console.log('onDynamicLink Error: ' + JSON.stringify(error)));
  }*/

  private async notificationWebSetup(userid: string) {
    console.log('Setting web notifications.');
    await this.fcmweb.requestPermission(userid);
    
    this.fcmweb.onNotification().subscribe((message: any) => {
        console.log('notification received...' + message);
        this.createToaster(message.notification.body, 4000);
    });
  }

  private async notificationNativeSetup2(userid: string) {
    console.log('Setting notifications.');
    await this.fcmnative.requestPermission(userid);
    
    this.fcmnative.onNotification().subscribe((data) => {
      if(data.wasTapped){
        console.log('Notification was received on device tray and tapped by the user.');
      } else {
        console.log('Notification was received in foreground. Maybe the user needs to be notified.');
        this.createToaster(data.body, 4000);
      }      
    });
  }

  /*
  private async notificationNativeSetup(userid: string) {
    console.log('Setting native notifications.');
    await this.fcmnative.requestPermission(userid);
    
    this.fcmnative.onNotification().subscribe((data) => {
      if(data.wasTapped){
        console.log('Notification was received on device tray and tapped by the user.');
      } else {
        console.log('Notification was received in foreground. Maybe the user needs to be notified.');
        this.createToaster(data.body, 4000);
      }      
    });
  }*/
  
  private hasUserPropertiesSet(userproperties: any): boolean {
    if (userproperties != null) {
        if (
          (userproperties.mobileNo != null) && (userproperties.mobileNo.length > 0)
          && (userproperties.name != null) && (userproperties.name.length > 0)
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
  }



  toggleDarkTheme(shouldAdd, themename) {
    if (shouldAdd) {
      this.changeTheme(themename + 'dark');
    } else {
      this.changeTheme(themename);
    }
    document.body.classList.toggle('dark', shouldAdd);
  }

  initializeApp() {
    //this.dbserver.setThemes(defaultthemes);
    this.dbserver.getThemes().then((_themes) => {
      this.dbserver.getSettings().then((settings) => {
        console.log('Settings: ' + JSON.stringify(settings));
        themes = _themes.default != null ? _themes : defaultthemes;
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        prefersDark.addEventListener('change', (mediaQuery) => this.toggleDarkTheme(mediaQuery.matches, settings.theme));
        this.toggleDarkTheme(prefersDark.matches, settings.theme); 
      });

    });

    this.currentversion = Configs[environment.name].currentversion;
    this.currentenv = 'env: ' + environment.name;
    console.log(this.currentenv);    
    console.log('App Version: ' + this.currentversion);
    // console.log("Firebase Config APIKEY: " + Configs[environment.name].firebaseConfig.apiKey);
    // console.log("Firebase Config projectId: " + Configs[environment.name].firebaseConfig.projectId);
    this.platform.ready().then((res) => {
      console.log('Platform ready....');
      let uniqueid = this.userservice.getUniqueId();
      //this.userservice.removePresenceOnUserLeft(uniqueid);
      this.statusBar.styleDefault();      
      const thisapp = this;     
     
      this.lookforpwaupdate();

      this.Cart = JSON.parse(localStorage.getItem('Cart'));
      if (localStorage.getItem('Cart') != null) {
        this.noOfItemsInCart = this.Cart.length;
      }

      // this.initDynamicLinks();
      // this.initAnalytics(); 

      // Get current user
      this.af.onAuthStateChanged((user) => {
        if (user) {
          const _user = user;
          // User is signed in.
          /*
          var userid = user.uid
          var email = user.email;
          var emailVerified = user.emailVerified;
          var isAnonymous = user.isAnonymous;
          var providerData = user.providerData;
          */
          this.db.object('/users/' + user.uid).valueChanges().subscribe( (res: any) => {
            if (res != null) {
              thisapp.name = res.name;
              const isfacebook = (_user.providerData[0].providerId === "facebook.com");
              if (_user.emailVerified || isfacebook) {
                if (((res.profilepictureurl != null) && (res.profilepictureurl.length > 0)) || (_user.photoURL != null) && (_user.photoURL.length > 0)) {
                  console.log('Setting profile picture: ' + res.profilepictureurl || _user.photoURL);
                  thisapp.profilepicture = res.profilepictureurl || _user.photoURL;
                  thisapp.name = res.name;              
                } else {
                  console.log('Not Setting profile picture');
                  thisapp.profilepicture = 'assets/img/profile.jpg';
                }
    
                let isApp = (document.URL.indexOf('http://app') === -1 && document.URL.indexOf('https://app') === -1);
                console.log('Is App: ' + isApp);
                console.log('Document URL: ' + document.URL);
                
                if (isApp) {
                // if (this.platform.is('android') || this.platform.is('ios')) {
                  // this.notificationNativeSetup(user.uid);
                  this.notificationNativeSetup2(user.uid);
                } else {
                  this.notificationWebSetup(user.uid);
                }
                
                if (!this.hasUserPropertiesSet(res)) {
                  this.settings();
                }  
  
                if (!this.hasCheckedIfUserCanRate) {
                  this.hasCheckedIfUserCanRate = true;
                  this.CheckIfUserCanRate();
                }
                
              } else {
                this.verifyemail();
              }
            }            
          });
          // ...
        } else {
          // User is signed out.
          // ...
          // this.login();
        }
      });

      if (this.af.currentUser) {
        this.af.currentUser.then((cuser) => {
          if (cuser !== null) {
            this.userID = cuser.uid;
            this.db.object('/users/' + this.userID).valueChanges().subscribe((res: any) => {
              const _user = res;
              this.name = _user.name;
              if ((_user.profilepictureurl != null) && (_user.profilepictureurl.length > 0)) {
                console.log('Setting profile picture: ' + _user.profilepictureurl);
                this.profilepicture = _user.profilepictureurl;
              } else {
                console.log('Not Setting profile picture');
              }
              if (!this.hasUserPropertiesSet(res)) {
                this.settings();
              }
            });
            this.db.list('/users/' + this.userID + '/favourite').valueChanges().subscribe(res => {
              this.noOfItemsInFevrt = res.length;
            });
          }
        });
      } else {
        console.log('No current user');
      }
    
      // offer
      this.db.list('/menuItems', ref => ref.orderByChild('offer/hasOffer').equalTo(true)).snapshotChanges().subscribe(queriedItems => {
        this.noOfItemsInOffer = queriedItems.length;
      });
      // news
      this.db.list('/news').snapshotChanges().subscribe((res) => {
        this.noOfItemsInNews = res.length;
      });
      this.splashScreen.hide();
    });
  }

  async lookforpwaupdate() {
    //console.log('Looking for pwa');
    if (this.swUpdate.isEnabled) {
      console.log('pwa is enabled...');
      this.swUpdate.available.subscribe(() => {
        this.toastCtrl.create({
          header: 'Hey... Vegecious has a new update!!',
          message: 'Click reload to see the latest juicy changes.',
          position: 'bottom',
          animated: true,
          buttons: [
            {
              side: 'start',
              icon: 'refresh-circle-outline',
              text: 'Reload',
              handler: () => {
                console.log('reload clicked');
                window.location.reload();
              }
            }, {
              text: 'Dismiss',
              role: 'cancel',
              handler: () => {
                console.log('Dismiss clicked');
              }
            }
          ]
        }).then((toast) => {
          toast.present();
        });
      });
    } else {
      console.log('pwa is NOT enabled...');
    }
  }
 
  home() {
    this.router.navigate(['/product-list/all']);
  }

  yourOrders() {
    this.router.navigate(['/orders']);
  }

  goToCart() {
    this.router.navigate(['/cart']);
  }

  /*
  category() {
    this.router.navigate(['/category']);
  }*/

  favourite() {
    this.router.navigate(['/favourite']);
  }

  offer() {
    this.router.navigate(['/offer']);
  }

  news() {
    this.router.navigate(['/news']);
  }

  contact() {
    this.router.navigate(['/contact']);
  }

  aboutUs() {
    this.router.navigate(['/aboutus']);
  }

  settings() {
    this.router.navigate(['/settings']);
  }

  verifyemail() {
    this.router.navigate(['/verifyemail']);
  }

  invite() {
    // this.socialSharing.share('share Vegicious App with friends to get credits', null, null, 'https://ionicfirebaseapp.com/#/');
  }

  /*
  firebaseSignout() {
    this.af.signOut();
    localStorage.removeItem('uid');
    this.router.navigate(['/signintabs', { outlets: { 'login': 'login' } }]);
  }*/

  logout() {
    console.log('Log Out');
    this.af.currentUser.then((cuser) => {
      console.log('cuser: ' + cuser);
      this.af.signOut();
      localStorage.removeItem('uid');
      this.router.navigate(['/signintabs/login']);
    });
  }

  login() {
    this.ngZone.run(() => {
      this.router.navigate(['signintabs', 'login']);
    });    
  }

  isLoggedin() {
    return this.authService.authenticated;
    // return localStorage.getItem('uid') != null;
  }

  CheckIfUserCanRate() {
    this.dbserver.getUnRatedUserOrders().then((orderstorate) => {
      console.log('Found orders to rate');
      console.log(orderstorate);
      if ((orderstorate != null) && (orderstorate.length > 0) && (orderstorate[0].items.length > 0)) {
        setTimeout(() => {
          this.presentModal(orderstorate);
        }, 5000);
      }
    });
  }

  async presentModal(itemstoberated: any) {
    const modal = await this.modalController.create({
      component: RateItemPage,
      cssClass: 'rate-item-scss',
      animated: true,
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      swipeToClose: true,
      componentProps: {
        'itemstorate': itemstoberated
      }
    });
    return await modal.present();
  }

}
