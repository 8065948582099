import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "../../services/db-service/dbservice";
import { Configs } from "../../../app/config";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'page-seller-info',
    templateUrl: 'sellerinfo.html',
    styleUrls: ['sellerinfo.scss']
})
export class SellerInfoPage implements OnInit {
    @Input() public seller: any;
    currency: string = Configs[environment.name].Currency.symbol;
    
    constructor(public dbService: DbService, public router: Router, public modalCtrl: ModalController) {
        console.log('SellerPage');        
    }
    ngOnInit(): void {
        console.log('SellerInfo: ' + this.seller);
        this.seller.rating = {
            currentrating: 0,
            totalrating: 0
        };
        this.getSellerInfo(this.seller.sellerid);
    }

    getSellerInfo(sellerid: string) {
        this.dbService.getSellerMenuItems(sellerid).then((menuitems: any[]) => {
            let nitems = menuitems.length;
            let avgrating = 0;
            let totalratings = 0;
            menuitems.forEach((x) => {
                if (x.rating != null) {
                    avgrating += x.rating.currentrating || 0;
                    totalratings += x.rating.totalratings || 0;
                }
            });
            this.seller.rating.currentrating = avgrating/nitems;
            this.seller.rating.totalrating = totalratings;
            this.dbService.getSellerDetails(sellerid).then((sellerdetails: any) => {
                this.seller = {...this.seller, ...sellerdetails};
                this.seller.menuitems = menuitems;
                console.log(this.seller);
            });
        });
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            'dismissed': true
          });
    }

    navigate(item) {
        // console.log("id---"+item)
        let data = { 'itemid': item };
        this.modalCtrl.dismiss(data);
        this.router.navigate(['/product-details', item]);
    }

    ShareSellerLink(seller: any){
        console.log(seller);
        let appstosharewith: {
          copy: true,
          email: true,
          print: true,
          sms: true,
          messenger: false,
          facebook: true,
          whatsapp: true,
          twitter: true,
          linkedin: false,
          telegram: false,
          skype: false,
          language: 'en'
        };
        let link: string = '';
        if (environment.production) {
            link = 'https://app.vegecious.com/seller/' + seller.sellerid;
        } else {
            link = 'https://app-dev.vegecious.com/seller/' + seller.sellerid;
        }
        
        navigator.share({
            title: this.seller.storename,
            text: 'Hey!! Checkout my cloud kitchen on Vegecious - ' + this.seller.storename,
            url: link
          })
          .then( _ => console.log('Yay, you shared it :)'))
          .catch( error => console.log('Oh noh! You couldn\'t share it! :\'(\n', error));

        /*let navigatorsharepolyfill = navigator.share;
        navigatorsharepolyfill.call(this, {
            title: this.seller.storename,
            text: 'Hey!! Checkout my cloud kitchen on Vegecious - ' + this.seller.storename,
            url: link,
            hashtags: ['vegecious']
          }, {
            copy: true,
            email: true,
            print: false,
            sms: true,
            messenger: false,
            facebook: true,
            whatsapp: true,
            twitter: true,
            linkedin: false,
            telegram: false,
            skype: false,
            language: 'en'
          })
          .then( _ => console.log('Yay, you shared it :)'))
          .catch( error => console.log('Oh noh! You couldn\'t share it! :\'(\n', error));*/
      }
}