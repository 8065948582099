<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Verify Email</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="verifyemail">
  <img class="logo" src="assets/img/logo-icon.png">

  <ion-row>
    <ion-col size="12">
      <p class="text-center">{{"Email not yet verified. Please verify your email. Click resend verification to resend verification email." | translate}}</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6">
      <p center>Email: {{currentuser.email}}</p>
    </ion-col>
    <ion-col size="6">
      <p center>IsVerified: {{currentuser.emailVerified}}:</p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-text-center" size="12">
        <ion-button (click)="doResendEmail()">
            <ion-icon slot="start" name="star"></ion-icon>
            Resend Verification Email
        </ion-button>
    </ion-col>
  </ion-row>
</ion-content>