<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{"News" | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-item *ngFor="let news of newsData" (click)="newsDetail(news.$key)">
      <ion-avatar slot="start">
        <img [src]="news.thumb">
      </ion-avatar>
      <ion-label>
        <h3>{{news.title}}</h3>
        <p style="float: right; font-size: small;">- {{news.publishtime | date:'medium'}}</p>
      </ion-label>
    </ion-item>
  </ion-list> 
</ion-content>
