<ion-header translucent no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="light">
        <ion-icon slot="start" name="ios-arrow-back"></ion-icon>
        Favorites
      </ion-button>
    </ion-buttons>
    <ion-title>&nbsp;</ion-title>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon slot="icon-only" src="../../assets/checkmark-filled.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true" slot="fixed" >
  <div class="ion-padding">
    Redirecting to the chef page....
  </div>
</ion-content>




<!--<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
  <ion-toolbar color="primary"> 
      <ion-buttons slot="start">
        <ion-back-button text=""></ion-back-button>
        <ion-menu-button></ion-menu-button>
      </ion-buttons>     
        <ion-title>
          {{seller.storename}}         
        </ion-title>
        <ion-buttons slot="end">
      </ion-buttons>
   </ion-toolbar>
  </ion-header>
  
  
  <ion-content class="ion-no-padding">
    <ion-grid>
      <ion-card class="ion-no-margin">
        <ion-card-content>
          <ion-row>
            <ion-col size="4">
              <ion-img class="sellerthumbnail" [src]="seller.thumb"></ion-img>
            </ion-col>
            <ion-col size="8" class="center-vertical">
                <ion-row>
                  <ion-col>
                    <ion-label color="primary"><h3 style="font-weight: 700; text-align: justify;">{{seller.name}}</h3></ion-label>
                  </ion-col>
                </ion-row>                
                <ion-row *ngIf="(seller.locationsserved != null) && (seller.locationsserved.length > 0)">
                  <ion-col>
                    <ion-label color="dark"><h4>Serving Locations: {{seller.locationsserved}}</h4></ion-label>
                  </ion-col>
                </ion-row>
                <ion-row class="addtopmargin">
                  <ion-col class="item-limits" class="ion-text-center">
                    <rating [score]="seller.rating.currentrating  | number:'1.0-0'" [totalratings]="seller.rating.totalratings" readonly="true"></rating>
                  </ion-col>
                </ion-row>
            </ion-col>
          </ion-row>
          <ion-row style="border-top: 1px solid black; border-bottom: 1px solid black;">
            <ion-col>
              <ion-label color="medium"><h4 style="margin-left: 15px; font-style: italic;"> {{seller.description}}</h4></ion-label>
            </ion-col>
          </ion-row>
          <ion-list>
            <ion-list-header>
              <div class="ion-text-center">
                <h4>Items <span *ngIf="seller.menuitems != null">&nbsp; ({{seller.menuitems.length}})</span></h4>
              </div>
            </ion-list-header>
            <ion-item *ngFor="let item of seller.menuitems ; let i=index" (click)="navigate(item.key)">
              <ion-avatar slot="start">
                <img src="{{item.thumb}}">
              </ion-avatar>
              <ion-label>
                <h2>
                  <span>{{item.title}}</span>
                  <span style="float: right; font-weight: 500;">{{currency}} {{item.price.value}}</span>
                </h2>
                <h3>{{item.description}}</h3>
                <p></p>
              </ion-label>
            </ion-item>
          </ion-list>
      </ion-card-content>
      </ion-card>
    </ion-grid>
  </ion-content>
-->