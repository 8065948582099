import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';


@Component({
  selector: 'page-favourite',
  templateUrl: 'favourite.html',
  styleUrls: ['favourite.scss']
})
export class FavouritePage {
  favouriteItems: any[] = [];


  constructor(public af: AngularFireAuth,
    public db: AngularFireDatabase,
    public router: Router,
    public toastCtrl: ToastController) {

    if (this.af.currentUser) {
      this.af.currentUser.then((cuser) => {
        this.db.list('/users/' + cuser.uid + '/favourite/').valueChanges().subscribe(res => {
          this.favouriteItems = res;
        });
      });
    }
  }

  isFavourite(): boolean {
    if (this.favouriteItems.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  buyNow(key) {
    this.router.navigate(['/product-details', key]);
  }

  navcart() {
    this.router.navigate(['/cart']);
  }

  removeFromFavourites(key) {
    if (this.af.currentUser) {
      this.af.currentUser.then((cuser) => {
        this.db.object('/users/' + cuser.uid + '/favourite/' + key).remove();
        this.createToaster('removed from favourites', '3000');
      });
    }


  }

  async createToaster(message, duration) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration
    });
    toast.present();
  }
}
