<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
    <ion-toolbar>      
        <ion-title>
          Set your delivery area.
        </ion-title>
        <!--<ion-buttons slot="end">
          <ion-button (click)="dismissModal()">Close</ion-button>
      </ion-buttons>-->
   </ion-toolbar>
  </ion-header>
  
  
  <ion-content [fullscreen]="true" class="ion-padding">
    <ion-list>
      <ion-radio-group allow-empty-selection="true" name="radio-group" #radioGroup value="{{deliverydata.currentdeliveryarea}}" (ionChange)="radioGroupChange($event)">
        <ion-list-header>
          <ion-label>Delivery Area</ion-label>
        </ion-list-header>
  
        <ion-item *ngFor="let location of deliverydata.alllocations" (ionFocus)="radioFocus()" (ionSelect)="radioSelect($event)" (ionBlur)="radioBlur()">
          <ion-label>{{location.title}}</ion-label>
          <ion-radio slot="start" name="{{location.title}}" value="{{location.title}}"></ion-radio>
        </ion-item>
  
      </ion-radio-group>
    </ion-list>
  </ion-content>
 
  