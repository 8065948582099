import {NgModule} from '@angular/core';
import {AppPipe} from './app.pipe';
import {FireBaseImageUrlPipe} from './fbstorage.pipe';
import {NgPipesModule} from 'ngx-pipes';
import { DeliverablePipe } from './deliverable.pipe';



@NgModule({
  declarations: [
    AppPipe,
    DeliverablePipe,
    FireBaseImageUrlPipe
  ],
  imports: [
    NgPipesModule
  ],
  exports: [
    AppPipe,
    DeliverablePipe,
    FireBaseImageUrlPipe
  ]
})
export class PipesModule {  }
