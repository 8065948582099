<ion-header>
  <ion-toolbar class="apptitle">
  </ion-toolbar>
</ion-header>

<ion-content class="login">
  <img class="logo" src="assets/img/logo-icon.png">
  <form [formGroup]="registration" (ngSubmit)="onSubmit()">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label>{{"Name:" | translate}}</ion-label>
          <ion-input type="text" name="name" formControlName="name" required=""></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <!--email-->
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label>{{"Email:" | translate}}</ion-label>
          <ion-input type="email" name="email" formControlName="email" required=""></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <!--Mobile No-->
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label>{{"Mobile No:" | translate}}</ion-label>
          <ion-input type="tel" name="mobileNo" id="mobileNo" formControlName="mobileNo"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <!--password-->
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label>{{"Password:" | translate}}</ion-label>
          <ion-input [type]="showPasswordText ? 'text' : 'password'" name="password" id="password" formControlName="password"></ion-input>
          <button ion-button icon-only clear [style.color]="showPasswordText ? 'black' : 'gray'" style="background: transparent;" type="button" item-right (click)="showPasswordText = !showPasswordText">
            <ion-icon size="large" name="eye"> </ion-icon>
          </button>
        </ion-item>
      </ion-col>
    </ion-row>
    <!--button-->
    <ion-row>
      <ion-col size="12">
        <ion-button class="login-btn" type="submit" [disabled]="!registration.valid">{{"Register" | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </form>
  <!--login with-->
  <ion-row>
    <ion-col size="12">
      <p class="ion-text-center">{{"REGISTER WITH SOCIAL LOGIN" | translate}}</p>
    </ion-col>
  </ion-row>

  <!--Social login-->
  <ion-row style="font-size: 44px;">
    <ion-col class="ion-text-center" size-xs="12" size-md>
      <ion-button color="white" expand="block" (click)="doFbLogin()">
        <ion-icon slot="start" name="logo-facebook"></ion-icon>
        Sign in with Facebook
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-center" size-xs="12" size-md>
      <ion-button expand="block" color="white" (click)="googleLogin()">
          <ion-icon slot="start" name="logo-google"></ion-icon>
          Sign in with Google
      </ion-button>     
    </ion-col>   
    <ion-col class="ion-text-center" size-xs="12" size-md>
      <ion-button color="white" expand="block" (click)="appleLogin()" >
          <ion-icon slot="start" name="logo-apple"></ion-icon>
          Sign in with Apple
      </ion-button>     
    </ion-col>   
  </ion-row>  
</ion-content>
