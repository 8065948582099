import { Component } from '@angular/core';

@Component({
  selector: 'page-thankyou',
  templateUrl: 'thankyou.html',
  styleUrls: ['thankyou.scss']
})
export class ThankyouPage {
  constructor() {
    localStorage.removeItem('Cart');
    localStorage.removeItem('CouponApplied');
  }
}
