<ion-app>
  <ion-menu style="--background:var(--ion-color-secondary-tint);" side="start" persistent="true" class="menu" contentId="menu-output">
    <ion-content persistent="true" class="sidebar-menu">
      <ion-row class="img-name-row">
        <ion-col style="text-align: center;">
          <ion-item color="secondary">
            <ion-avatar slot="start">
              <img class="user-img" src="{{profilepicture}}">
            </ion-avatar>  
            <ion-label>{{name}}</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
     <br>
     <br>
     <br>
      <ion-list class="menu-list" lines="full">
        <ion-menu-toggle>
          <ion-item (click)="home()">
            <ion-label>{{'Home' | translate}}</ion-label>
            <ion-icon name="home" slot="start"></ion-icon>
          </ion-item>
          <!--
          <ion-item (click)="category()">
            <ion-label>{{'Category' | translate}}</ion-label>
            <ion-icon name="apps" slot="start"></ion-icon>
          </ion-item>
          -->
          <ion-item (click)="offer()">
            <ion-label>{{'Offers' | translate}}</ion-label>
            <ion-icon name="pricetag" slot="start"></ion-icon>
            <ion-badge color="secondary" class="menu-badge">{{noOfItemsInOffer}}</ion-badge>
          </ion-item>
          <ion-item (click)="goToCart()">
            <ion-label>{{'MyCart' | translate}}</ion-label>
            <ion-badge color="secondary" class="menu-badge">{{noOfItemsInCart}}</ion-badge>
            <ion-icon name="cart" slot="start"></ion-icon>
          </ion-item>
          <ion-item *ngIf="isLoggedin()" (click)="yourOrders()">
            <ion-label>{{'Your Orders' | translate}}</ion-label>
            <ion-icon name="receipt" slot="start"></ion-icon>
          </ion-item>
          <ion-item *ngIf="isLoggedin()" (click)="settings()">
            <ion-label>{{'Settings' | translate}}</ion-label>
            <ion-icon name="settings" slot="start"></ion-icon>
          </ion-item>
          <ion-item (click)="aboutUs()">
            <ion-label>{{'About Us' | translate}}</ion-label>
            <ion-icon name="people" slot="start"></ion-icon>
          </ion-item>
          <ion-item (click)="news()">
            <ion-label>{{'Vegecious News' | translate}}</ion-label>
            <ion-icon name="newspaper" slot="start"></ion-icon>
            <ion-badge color="secondary" class="menu-badge">{{noOfItemsInNews}}</ion-badge>
          </ion-item>
          <ion-item *ngIf="!isLoggedin()" (click)="login()">
            <ion-label>{{'Login' | translate}}</ion-label>
            <ion-icon name="log-in" slot="start"></ion-icon>
          </ion-item>
          <ion-item *ngIf="isLoggedin()" (click)="logout()">
            <ion-label>{{'Logout' | translate}}</ion-label>
            <ion-icon name="log-out" slot="start"></ion-icon>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-toolbar color="primary">
        <ion-title>{{currentenv}} <ion-badge  color="secondary" style="float: right; top: 5px; position: relative;">v{{currentversion}}</ion-badge></ion-title>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="menu-output" style="margin: 0 auto;"></ion-router-outlet>
</ion-app>
